@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

body {
    background-color: var(--color-background);
}

.slider-button {
    -webkit-appearance: none;
    appearance: none;
    background: var(--color-scholl-bus-yellow);
    border: none;
    border-radius: 30px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.8));
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-regular);
    padding: 15px 40px;
    text-transform: uppercase;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.over-slider {
    align-items: center;
    background-color: var(--bg-color-white);
    display: flex;
    flex-direction: row;
    height: 125px;
    margin-left: 0;
    position: absolute;
    width: 100%;
}

.slide.next.wrp-sld-img .over-slider.row {
    height: 0;
    visibility: hidden;
}

.slide.previous.wrp-sld-img .over-slider.row {
    height: 0;
    visibility: hidden;
}

.slider-button:hover {
    background: var(--bg-color-black);
    color: var(--color-white);
    -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
    box-sizing: border-box;
    margin: auto;
    max-width: 100%;
    padding: 0 20px;
}

.wrapper a {
    display: inline-block;
    margin: 5px;
}

.wrapper a:first-child {
    margin-left: 0;
}

.slider-top {
    height: 500px;
    margin: -62.5px auto 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: var(--z-index-8);
}

.coverImg {
    filter: saturate(180%) blur(5px);
    height: 600px;
    margin-top: 62.5px;
    position: absolute;
    width: 100%;
}

.slider-bot {
    height: 625px;
    margin: 100px auto 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: var(--z-index-8);
}

.slider-bot-full {
    height: 500px;
    left: calc((1305px - 100vw) / 2);
    overflow: hidden;
    position: relative;
    width: calc(100vw - 1%);
    z-index: var(--z-index-8);
}
.slide {
    height: auto;
}

.slide::before {
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
}

.previousButton {
    background: rgba(0, 0, 0, 0.3) url('../../assets/svg/arrow.svg') no-repeat center
        233px / 32px;
    cursor: pointer;
    height: 500px;
    padding-bottom: 50px;
    position: absolute;
    text-indent: -9999px;
    top: calc(40% + 125px);
    transform: translateY(-50%);
    width: 75px;
    z-index: var(--z-index-4);
}

.nextButton {
    background: rgba(0, 0, 0, 0.3) url('../../assets/svg/arrow.svg') no-repeat center
        center / 32px;
    cursor: pointer;
    height: 625px;
    position: absolute;
    text-indent: -9999px;
    top: calc(43% + 44px);
    transform: translateY(-50%);
    width: 75px;
    z-index: var(--z-index-4);
}

.nextButton:hover {
    background: rgba(225, 228, 232, 0.3) url('../../assets/svg/arrow.svg') no-repeat
        center center / 32px;
}

.previousButton:hover {
    background: rgba(225, 228, 232, 0.3) url('../../assets/svg/arrow.svg') no-repeat
        center 233px / 32px;
}

.previousButton {
    left: 0;
    -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
    transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
    left: -10px;
}

.nextButton {
    right: 0;
}

.nextButton:hover {
    right: -10px;
}

.slider-content {
    text-align: center;
}

.slider-content img {
    height: calc(100% / 25.9);
    margin-top: 125px;
    width: 100%;
}

.slider-content .inner {
    box-sizing: border-box;
    left: 50%;
    padding: 0 70px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
}

.slider-content .inner button {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.slider-content .inner h1 {
    color: var(--color-white);
    font-size: var(--font-size-64);
    font-weight: var(--font-weight-black);
    line-height: 1;
    margin: 0 auto;
    max-width: 840px;
}

.slider-content .inner p {
    color: var(--color-white);
    font-size: var(--font-size-14);
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
}

.slider-content section {
    bottom: 20px;
    left: 20px;
    position: absolute;
}

.slider-content section span {
    color: var(--color-white);
}

.slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    display: inline-block;
    font-size: var(--font-size-12);
    line-height: 1.4;
    margin-left: 10px;
    text-align: left;
    vertical-align: middle;
}

.slider-content section img {
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    height: 40px;
    vertical-align: middle;
    width: 40px;
}

.slider-content section span strong {
    color: var(--color-white);
    display: block;
    font-size: var(--font-size-14);
}

.title-slider {
    align-items: center;
    color: var(--color-main);
    display: flex;
    flex-direction: row;
    font-family: var(--font-main);
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-regular);
    justify-content: flex-start;
    padding-left: 100px;
    vertical-align: center;
}

.button-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

.button-container button {
    align-items: center;
    background-color: var(--color-accent-400);
    border: none;
    display: flex;
    flex-direction: row;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-semi-bold);
    height: 48px;
    justify-content: center;
    visibility: visible;
    width: 139px;
}

.button-container button > a {
    color: var(--color-accesibility-gray) !important;
}

.slide.next.wrp-sld-img .over-slider.row {
    height: 0;
    visibility: hidden;
}

.slide.previous.wrp-sld-img .over-slider.row {
    height: 0;
    visibility: hidden;
}

.button-container-responsive {
    display: none;
}

@media (max-height: 600px) {
    .slider-content .inner h1 {
        font-size: var(--font-size-32);
    }
}

@media (max-width: 640px) {
    .slider-content .inner h1 {
        font-size: var(--font-size-32);
    }
}

@media screen and (max-width: 1200px) {
    .previousButton {
        height: 500px;
        padding-bottom: 0;
        top: calc(41% + 45px);
    }

    .previousButton:hover,
    .nextButton:hover {
        background: rgba(0, 0, 0, 0.3) url('../../assets/svg/arrow.svg') no-repeat
            center center / 32px;
    }

    .previousButton:hover {
        left: 0;
    }

    .nextButton:hover {
        right: 0;
    }

    .slide {
        margin-left: 75px;
        top: 0;
        width: calc(100% - 150px);
    }

    .slider-bot {
        height: auto;
        padding-top: calc((100% / 2.59) + 70px);
    }
}

@media screen and (max-width: 768px) {
    .previousButton {
        display: none;
    }

    .nextButton {
        display: none;
    }

    .slide {
        margin-left: 0;
        width: 100%;
    }
}

@media screen and (max-width: 512px) {
    .button-container {
        justify-content: flex-start;
    }

    .title-slider {
        font-size: var(--font-size-12);
        justify-content: center;
        padding-left: 15px;
        padding-top: 5px;
    }

    .button-container button {
        font-size: var(--font-size-9);
        height: 30px;
        width: 79px;
    }

    .over-slider {
        height: 70px;
    }

    .slider-content img {
        margin-top: 70px;
    }
}
