.pageRow {
  box-sizing: border-box;
  margin: auto;
  max-width: 80%;
}


@media (min-width: 780px) {
  .pageRow {
    max-width: 768px;
  }
}

@media (min-width: 1280px) {
  .pageRow {
    max-width: 1440px;
  }
}