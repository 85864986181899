.actionDetailActionSeguimientoContent {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: space-around;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
}

.accionEstadoCumplimiento {
    margin-left: 20px;
}

.contenedorSeguimiento {
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;

}

.contenedorSeguimiento p {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-medium);
}

.seguimiento {
    display: none;
    padding: 40px 40px 40px 40px;
}

.seguimiento p {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-regular);
}

.seguimientoAdjuntos {
    margin-top: 100px;

    padding-top: 10px;
}

.seguimientoAdjuntos p {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-medium);
}

.adjuntos {
    margin-top: 20px;
}

.adjuntos img {
    margin-top: 20px;
}

.accionEstadoCumplimientoParrafo {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-regular);
    margin-bottom: 15px;

}

.accionEstadoParrafo {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-regular);
    text-align: center;
}

.infoboxBarraEstado {
    margin-top: 10px;
}

.accionEstadoCumplimiento p {
    text-align: center;
}

.actionDetail {
    background-color: var(--bg-color-white);
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    margin-top: 91px;
    width: 90%;
}

.actionDetail_plan-title {
    align-items: center;
    background-color: var(--bg-color-white);
    color: var(--color-main);
    display: flex;
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-semi-bold);
    height: 130px;
    margin-left: 40px;
}

.actionDetail_action-title {
    align-items: center;
    background-color: var(--bg-color-light-grey);
    color: var(--color-main);
    display: flex;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    height: 100px;
}

.actionDetail_plan-title h1 {
    font-size: var(--font-size-22)
}

.actionDetail_plan-title h1,
.actionDetail_action-title p,
.actionDetail-box_container-dates_svg p,
.actionDetail_action-descriptionContainer-downloads_title p,
.actionDetail_action-descriptionContainer-links_title p,
.presupuesto-number p,
.presupuesto-title p,
.actionDetail_action-desglose-detail p,
.actionDetail_action-descriptionContainer-clasificacion-title p,
.actionDetail_action-descriptionContainer-hitos-title p,
.clasificacion-content p,
.hitos-content-title p,
.hitos-dates p,
.hitos-estado-title p,
.objetivos-title p {
    margin-bottom: 0;
}

.actionDetail_action-descriptionContainer {
    background-color: var(--bg-color-lighter-grey);
    display: flex;
    justify-content: center;
}

.enlace-buscador {
    color: var(--color-nero);
}

.enlace-buscador:hover {
    color: var(--color-nero);
}

.container {
    height: auto !important;
    width: 90%;
}

.col-12 {
    padding: 0;
}

/*CHART-PRESUPUESTO*/

.container-Description-Chart {
    display: flex;
    flex-direction: row;
}

.actionDetail_action-descriptionContainer-description {
    font-size: var(--font-size-14);
    margin-right: 90px;
    margin-top: 50px;
    width: 50%;
}

.presupuesto-chart-texts-containter-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-left: 35px;
    padding-right: 35px; */
}

.actionDetail_action-descriptionContainer-chart {
    background-color: var(--bg-color-white);
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
    margin-top: 60px;
    min-height: 260px;
    width: 40%;
    z-index: var(--z-index-1);
}

.chart {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-left: 5%;
}

.presupuesto-chart-texts-containter {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 35px;
    padding-right: 35px;
}

.actionDetail_action-descriptionContainer-chart {
    background-color: var(--color-white);
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
    height: 260px;
    margin-top: -20px;
    width: 40%;
    z-index: var(--z-index-1);
}

.chart {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-left: 5%;
}

.presupuesto-chart-texts-containter {
    display: flex;
    flex-direction: column;
}

.chart-presupuesto {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 35px;
}

.presupuesto-container:not(:empty) {
    width: 55%;
}

.presupuesto-number {
    color: var(--color-text-news);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-regular);
    margin-bottom: 26px;
}

.separador {
    background-color: var(--bg-color-grey);
    height: 1px;
    margin-bottom: 12px;
    margin-top: 12px;
}

.downloads_files svg {
    height: 20px;
    margin-right: 5px;
    width: 20px
}

/*DESGLOSE*/

.arrow {
    display: flex;
    flex-direction: row;
}

.arrowShown {
    cursor: pointer;
    display: flex;
}

.arrowHidden {
    display: none;
}

.actionDetail_action-desglose-title {
    align-items: center;
    background-color: var(--bg-color-light-grey);
    cursor: pointer;
    display: flex;
    height: 57px;
    justify-content: space-between;
    padding-right: 35px;
}

.actionDetail_action-desglose-title p {
    color: var(--color-main);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
    margin-bottom: 0;
    padding-left: 35px;
}

.desglose-detail-hidden {
    display: none;
}

.actionDetail_action-desglose-detail {
    background-color: var(--bg-color-white);
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 35px;
}

.detail-container {
    color: var(--color-text-news);
    font-size: var(--font-size-14);
    margin-bottom: 30px;
}

.detail-numbers {
    display: flex;
    flex-direction: row;
}

.detail-numbers p {
    width: 200px;
}

.detail-numbers span {
    font-weight: var(--font-weight-medium);
}

.detail-numbers_title {
    font-weight: var(--font-weight-medium);
}

/*DATES*/
.actionDetail_action-descriptionContainer-dates {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    width: 75%;
}

.actionDetail_action-descriptionContainer-dates-responsive {
    display: none;
    margin-bottom: 60px;
    width: 50%;
}

.actionDetail-box_container-dates_svg {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    width: 290px;
}

.actionDetail-box_container-dates_svg p {
    font-size: var(--font-size-14);
}

.bold {
    color: var(--color-text-news);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
    margin-right: 5px;
}

.planned-dates,
.real-dates {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

/*DOWNLOADS-LINKS*/

.actionDetail_action-descriptionContainer-downloads_title,
.actionDetail_action-descriptionContainer-links_title {
    align-items: center;
    color: var(--color-main);
    display: flex;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
    margin-bottom: 2em;
}

.downloads_files {
    align-items: center;
    display: flex;
    margin-bottom: 10px
}

.downloads_files a {
    color: var(--color-text-news);
    font-size: var(--font-size-14);
    margin-bottom: 0;
    text-decoration: none;
}

/*DATOS DE CLASIFICACIÓN*/

.actionDetail_action-descriptionContainer-clasificacion,
.actionDetail_action-descriptionContainer-hitos,
.actionDetail_action-descriptionContainer-objetivos {
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
    margin-bottom: 60px;
    margin-top: 2em;
}

.actionDetail_action-descriptionContainer-clasificacion-content {
    background-color: var(--bg-color-white);
    color: var(--color-text-news);
    font-size: var(--font-size-14);
}

.clasification-content_tematica-area {
    margin-right: 260px;
}

.clasification-content_zonas span {
    color: var(--color-main);
    font-weight: var(--font-weight-medium);
    margin-right: 20px;
}

.clasification-content_zonas_container {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.clasification-content_zonas_container div {
    background-color: var(--bg-color-light-grey);
    border-radius: 15px;
    margin-right: 20px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
}

.clasificacion-content {
    margin-bottom: 20px;
    margin-left: 90px;
}

.actionDetail_action-descriptionContainer-clasificacion-title,
.actionDetail_action-descriptionContainer-hitos-title,
.actionDetail_action-descriptionContainer-objetivos-title {
    align-items: center;
    background-color: var(--bg-color-light-grey);
    color: var(--color-main);
    display: flex;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    height: 100px;
}

.clasificacion-title,
.hitos-title,
.objetivos-title {
    align-items: center;
    display: flex;
    margin-left: 90px;
}

.actionDetail_action-descriptionContainer-clasificacion-content {
    display: flex;
    flex-direction: row;
    padding-bottom: 60px;
    padding-top: 60px;
}

.clasificacion-content span {
    font-weight: var(--font-weight-medium);
}

.actionDetail_action-descriptionContainer-clasificacion-title svg {
    margin-right: 22px;
    width: 43px;
}

.actionDetail_action-descriptionContainer-hitos-title svg,
.objetivos-title svg {
    margin-right: 22px;
    width: 40px;
}

/*HITOS*/

.hitos-description-estado {
    display: flex;
    flex-direction: row;
}

.hitos-estado {
    margin-left: 50px;
    margin-top: 17px;
    width: 50%;
}

/* .bola {
    background-color: var(--color-main);
    border-radius: 40px;
    height: 12px;
    margin-right: 10px;
    width: 12px;
} */

.hitos-estado-title {
    color: var(--color-main);
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
}

.actionDetail_action-descriptionContainer-hitos-content {
    background-color: var(--bg-color-white);
    color: var(--color-text-news);
    font-size: var(--font-size-14);
}

.hitos-content-title {
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    align-items: center;
    color: var(--color-main);
    cursor: pointer;
    display: flex;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-medium);
    height: 100px;
    justify-content: space-between;
    padding-left: 90px;
    padding-right: 90px;
    text-align: start;
}

.arrows,
.arrows-budget {
    cursor: pointer;
}

.separador-hitos {
    background-color: var(--bg-color-grey);
    background-color: var(--color-iron);
    height: 1px;
}

.hitos-description-hidden {
    display: none;
}

.hitos-description-shown {
    background-color: #f9f9fb;
    display: flex;
}

.hitos-description-containter {
    margin: 60px 90px 30px 90px;
}

.hitos-description {
    display: flex;
    margin-bottom: 30px;
    width: 50%;
}

.hitos-description-hidden,
.hitos-description-responsive {
    display: none;
}

.hitos-description-visible {
    background-color: var(--bg-color-lighter-grey);
    display: flex;
}

.hitos-dates-container {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}

.hitos-dates {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
}

.date-inicio {
    display: flex;
    margin-right: 180px;
    width: 250px;
}

.date-fin {
    display: flex;
    width: 250px;
}

/*SVG*/

/* #Rendicion_accion_desktop_up, */
#Group-11-Copy-2,
#Rendicion_plan_v1_desktop_link,
#Oval-clasificaciones {
    fill: var(--color-main);
}

/* #Rendicion_accion_desktop_down, */
#Shape-Copy,
#Rendicion_plan_v1_desktop_paper,
#Path-30-clasificaciones,
#Path-30-Copy-3-clasificaciones,
#Rendicion_accion_desktop-hitos,
#lineas-mundo {
    stroke: var(--color-main);
}

.actionDetail_action-descriptionContainer-downloads_title svg {
    margin-right: 24px;
    width: 16px;
}

.actionDetail_action-descriptionContainer-links_title svg {
    margin-right: 24px;
    width: 20px;
}

.actionDetail_action-title svg {
    margin-left: 68px;
    margin-right: 30px;
    width: 38px;
}

.actionDetail_action-desglose-title svg,
.arrows svg {
    width: 13px;
}

.actionDetail-box_container-dates_svg svg {
    margin-right: 12px;
    width: 20px;
}

.hitos-dates svg {
    margin-right: 12px;
    width: 22px;
}

/*FIN SVG*/

/*Mapa*/
.actionDetail-box_container-map {
    margin-bottom: 70px;
    margin-top: 47px;
}

.map_container {
    height: 499px;
    margin-left: 0px;
    position: relative;
    width: 100%;

}

@media screen and (max-width: 990px) {
    .actionDetail {
        margin-top: 40px;
    }

    .container-Description-Chart,
    .actionDetail_action-descriptionContainer-dates,
    .actionDetail_action-descriptionContainer-downloads,
    .actionDetail_action-descriptionContainer-links,
    .actionDetail_action-descriptionContainer-clasificacion,
    .actionDetail_action-descriptionContainer-hitos {
        margin-left: 0;
    }

    .container-Description-Chart {
        display: flex;
        flex-direction: column;
    }

    .actionDetail_action-descriptionContainer-description,
    .actionDetail_action-descriptionContainer-chart {
        width: 100%;
    }

    .chart-presupuesto {
        min-height: 195px;
    }

    .actionDetail_action-descriptionContainer-dates {
        display: none;
    }

    .actionDetail_action-descriptionContainer-dates-responsive {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
        width: 100%;
    }

    .planned-dates,
    .real-dates {
        flex-direction: column;
    }

    .actionDetail_action-descriptionContainer-chart {
        background-color: var(--bg-color-white);
        box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
        height: auto;
        margin-top: 20px;
        min-height: 220px;
        width: 100%;
        z-index: var(--z-index-0);
    }

    .actionDetail_action-descriptionContainer-downloads {
        margin-top: 30px;
    }

    .actionDetail_action-descriptionContainer-clasificacion-content {
        flex-direction: column;
    }

    .clasificacion-title,
    .hitos-title,
    .clasificacion-content {
        margin-left: 40px;
    }

    .clasification-content_zonas_container {
        margin-left: 40px;
        margin-right: 40px;
    }

    .hitos-content-title {
        color: var(--color-text-news);
        height: 100px;
        margin-left: 40px;
        margin-right: 40px;
    }

    .hitos-description-containter {
        margin: 40px 40px 30px 40px;
    }

    .hitos-description,
    .actionDetail_action-descriptionContainer-dates {
        display: none;
    }

    .hitos-estado {
        margin-left: 0;
        margin-top: 0;
    }

    .hitos-description-responsive {
        display: flex;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 100%;
    }

    .hitos-description-estado {
        flex-direction: column;
    }

    .hitos-dates {
        align-items: flex-start;
        flex-direction: column;
    }

    .date-inicio {
        margin-bottom: 8px;
    }
}

@media screen and (max-width: 581px) {
    .hitos-description-responsive {
        width: 95%;
    }
}

@media screen and (max-width: 568px) {

    .actionDetailActionSeguimientoContent {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .contenedorValorObjetivo {
        margin-top: 50px;
    }

    .accionEstadoCumplimiento {
        margin-left: 0px;
    }

    .actionDetail {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        width: 100%;
    }

    .actionDetail_plan-title {
        font-size: var(--font-size-14);
        height: 80px;
        margin-left: 20px;
    }

    .actionDetail_action-title {
        font-size: var(--font-size-14);
        height: 60px;
    }

    .actionDetail_action-title svg {
        margin-left: 20px;
        margin-right: 22px;
        width: 26px;
    }

    .actionDetail_action-descriptionContainer-description {
        margin-top: 0;
    }

    .chart-presupuesto {
        min-height: 235px;
        padding-top: 0px;
    }

    .planned-dates,
    .real-dates {
        flex-direction: column;
    }

    .planned-dates,
    .real-dates {
        margin-top: 0;
    }

    .actionDetail-box_container-dates_svg {
        margin-bottom: 10px;
    }

    .actionDetail-box_container-map {
        margin-bottom: 30px;
    }

    .actionDetail_action-descriptionContainer-clasificacion-title,
    .actionDetail_action-descriptionContainer-hitos-title,
    .actionDetail_action-descriptionContainer-objetivos-title {
        font-size: var(--font-size-14);
        height: 60px;
    }

    .clasificacion-title,
    .hitos-title {
        margin-left: 20px;
    }

    .actionDetail_action-descriptionContainer-clasificacion-title svg {
        margin-right: 30px;
        width: 30px;
    }

    .actionDetail_action-descriptionContainer-hitos-title svg {
        width: 27px;
    }

    .actionDetail_action-descriptionContainer-clasificacion-content {
        padding-bottom: 30px;
        padding-top: 30px;
    }

    .clasification-content_tematica-area {
        margin-right: 0;
    }

    .actionDetail_action-descriptionContainer-clasificacion,
    .actionDetail_action-descriptionContainer-hitos {
        margin-bottom: 30px;
    }

    .hitos-content-title {
        font-size: var(--font-size-14);
        height: 60px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .hitos-description-containter {
        margin: 30px 20px 30px 20px;
    }

    .hitos-description-responsive {
        width: 85%;
    }

    .objetivos-title {
        margin-left: 20px;
    }
}

@media screen and (max-width: 442px) {
    .hitos-description-responsive {
        width: 75%;
    }
}

@media screen and (max-width: 400px) {
    .hitos-description-responsive {
        width: 65%;
    }
}