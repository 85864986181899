.hartuzCancellationform,
.hartuzCancellationformHourFilter {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hartuzCancellationform button[type="submit"],
.hartuzCancellationformHourFilter button[type="submit"] {
  background-color: var(--color-main);
  border: none;
  border-radius: 2px;
  color: var(--color-white);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  line-height: 14px;
  min-height: 58px;
  width: 100%;
}

.hartuzCancellationform__submit {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.hartuzCancellationform__submit button {
  max-height: 45px;
  max-width: 270px;
}

.hartuzCancellationform .hartuzCancellationform__checkbox .form-checkbox__content a,
.hartuzCancellationformHourFilter .hartuzCancellationform__checkbox .form-checkbox__content a {
  color: var(--color-main);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
}

.form-group.hartuzCancellationform__checkbox {
  margin-bottom: 0;
}

.hartuzCancellationform__errors {
  display: flex;
  justify-content: center;
}

.form-group__facilitySelect {
  color: var(--color-main);
  display: block;
  font-family: var(--typography-content);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-medium);
  line-height: 17px;
  margin-bottom: .5rem;
}

.hartuzCancellationform__facilitySelect {
  border: 1px solid #F0F0F0;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  font-size: 0.875rem;
  padding: 1rem;
  width: 100%;
  background-color: var(--color-white);
  min-height: 50px;
}

.hartuzCancellationform input,
.hartuzCancellationformHourFilter input,
.hartuzCancellationformHourFilter select {
  color: var(--color-text-news)
}

@media (min-width: 780px) {

  .hartuzCancellationform,
  .hartuzCancellationformHourFilter {
    gap: 30px;
  }

}

@media (min-width: 1024px) {
  .hartuzCancellationform {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  }

  .hartuzCancellationformHourFilter {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  .hartuzCancellationform__input--wrapper .form-group .form-input {
    min-height: 50px;
  }

  .hartuzCancellationform__submit {
    justify-content: initial;
  }

  .hartuzCancellationform__checkbox {
    order: 1;
  }

}