.fullscreenerror_cont {
    width: 100%;
}

.fullscreenerror_overlay {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 550px;
}

.fullscreenerror_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.fullscreenerror_text {
    width: 100%;
    color: var(--color-main);
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.fullscreenerror_text > div + div {
    margin-left: 20px;
}

.fullscreenerror_title {
    font-size: var(--font-size-32);
    font-weight: var(--font-weight-semi-bold);
}

.fullscreenerror_subtitle {
    font-size: var(--font-size-22);
}
