.aurretikCard {
  background-color: var(--bg-color-white);
  min-height: 160px;
}

.aurretikCard_icon-human {
  fill: var(--color-opened);
  height: auto;
  width: 40px;
}

.aurretikCard_icon-human g {
  fill: var(--color-opened);
}

.aurretikCard-top-content h2,
.aurretikCard-top-content p,
.aurretikCard-top-content svg {
  z-index: var(--z-index-1);
}

.aurretikCard-top-content {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  min-height: 100px;
  position: relative;
}

.aurretikCard-top-content p {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
}

.aurretikCard-top-content img {
  height: 100%;
  inset: 0;
  margin: 0;
  min-width: 100%;
  position: absolute;
}

.aurretikCard-middle-content {
  align-items: center;
  display: flex;
  min-height: 60px;
}

.aurretikCard-middle-content p {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
  line-height: 18px;
}

.aurretikCard-middle-content p span {
   font-size: var(--font-size-12);
  font-weight: var(--font-weight-semi-bold);
}

.aurretikCard-content {
  overflow: hidden;
  padding: 25px;
}

.aurretikCard-content p {
  margin-bottom: 0px;
}

.button--grey {
  background-color: var(--bg-color-grey);
  color: var(--color-mine-shaft);
  cursor: pointer;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  min-height: 34px;
  padding: 8px 10px 8px 10px;
  width: 200px;
}

.button--grey span {
  text-align: center;
  width: 100%;
}

.aurretikCard-bottom-content {
  border-top: 0.5px solid var(--color-light-grey);
  display: flex;
  justify-content: center;
}

.aurretikCard-content--uppercase {
  text-transform: uppercase;
}

.aurretikCard-bottom-content {
  display: none;
}

.aurretikCard-content p {
  display: flex;
  gap: 5px;
}

@media (min-width: 768px) {
  .aurretikCard-bottom-content {
    display: flex;
  }

  .aurretikCard-bottom-content a {
    display: flex;
    justify-content: center;
  }
}