.news-slider-button {
    -webkit-appearance: none;
    appearance: none;
    background: var(--color-scholl-bus-yellow);
    border: none;
    border-radius: 30px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.8));
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-regular);
    padding: 15px 40px;
    text-transform: uppercase;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.news-slider-button:hover {
    background: var(--color-nero);
    color: var(--color-white);
    -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
    box-sizing: border-box;
    margin: auto;
    max-width: 100%;
    padding: 0 20px;
}

.wrapper a {
    display: inline-block;
    margin: 5px;
}

.wrapper a:first-child {
    margin-left: 0;
}

.news-over-slider {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    height: 125px;
    margin-left: 0;
    position: absolute;
    width: 100%;
}

.news-slider-top {
    height: 625px;
    margin: -82px auto 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: var(--z-index-8);
}

.news-slider-bot {
    height: 625px;
    margin: 100px auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: var(--z-index-8);
}

.slide {
    height: auto;
}

.slide::before {
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
}

.leftButton,
.rightButton {
    color: var(--color-slider-arrow);
    cursor: pointer;
    height: 56px;
    position: absolute;
    text-indent: -9999px;
    top: 15%;
    transform: translateY(-50%);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    visibility: visible;
    width: 56px;
    z-index: var(--z-index-4);
}

.arrow-slider__left {
    height: 15px;
    stroke: var(--color-slider-arrow);
    transform: rotate(180deg) translateY(-18px) translateX(-24px);
    width: 15px;
}

.arrow-slider__right {
    height: 15px;
    stroke: var(--color-slider-arrow);
    transform: translateY(18px) translateX(18px);
    width: 15px;
}

.arrow-slider__left > g > polyline,
.arrow-slider__right > g > polyline {
    stroke: var(--color-slider-arrow);
}

.leftButton {
    left: 92%;
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
    transform: rotate(180deg) translateY(calc(50% + 0px));
}

.leftButton.disabled,
.rightButton.disabled {
    height: 0;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    visibility: hidden;
}

.rightButton {
    right: 0;
}

.news-slider-content {
    text-align: center;
}

.news-slider-date {
    align-items: flex-start;
    background-color: var(--color-accent-400);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-left: 25px;
    vertical-align: center;
}

.news-slider-left-triangle-shape {
    border-bottom: 15px solid transparent;
    border-left: 10px solid var(--color-accent-400);
    border-top: 15px solid transparent;
    bottom: calc(50% - 15px);
    height: 0;
    left: 8.33%;
    position: absolute;
    width: 0;
    z-index: var(--z-index-9);
}

.date-text {
    color: var(--color-accesibility-gray);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-bold);
}

.year-text {
    color: var(--color-accesibility-gray);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-regular);
}

.news-slider-title {
    align-items: center;
    background-color: var(--bg-color-white);
    color: var(--color-main);
    display: flex;
    flex-direction: row;
    font-family: var(--font-main);
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-regular);
    height: 100%;
    justify-content: flex-start;
    padding-left: 100px;
    vertical-align: center;
}

.arrows-container {
    align-items: flex-end;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

.arrow-left {
    background-color: var(--color-accent-400);
    border-left: 1px var(--color-white) solid;
    height: 50%;
    padding: 0;
}

.arrow-right {
    background-color: var(--color-accent-400);
    border-left: 1px var(--color-white) solid;
    height: 50%;
    padding: 0;
}

.arrow-left > a {
    color: var(--color-slider-arrow);
}

.news-slider-content .news-inner {
    box-sizing: border-box;
    left: 50%;
    padding: 0 70px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
}

.news-slider-content .news-inner button {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.news-slider-content .news-inner h1 {
    color: var(--color-white);
    font-size: var(--font-size-64);
    font-weight: var(--font-weight-black);
    line-height: 1;
    margin: 0 auto;
    max-width: 840px;
}

.news-slider-content .news-inner p {
    color: var(--color-white);
    font-size: var(--font-size-14);
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
}

.news-slider-content section {
    bottom: 20px;
    left: 20px;
    position: absolute;
}

.news-slider-content section span {
    color: var(--color-white);
}

.link-slider {
    display: block;
    height: 625px;
    position: absolute;
    width: 100%;
    z-index: var(--z-index-3);
}

.news-slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    display: inline-block;
    font-size: var(--font-size-12);
    line-height: 1.4;
    margin-left: 10px;
    text-align: left;
    vertical-align: middle;
}

.news-slider-content section img {
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    height: 40px;
    vertical-align: middle;
    width: 40px;
}

.news-slider-content section span strong {
    color: var(--color-white);
    display: block;
    font-size: var(--font-size-14);
}

.news-slider-content {
    text-align: center;
}

.news-slider-content img {
    height: calc(100% / 25.9);
    margin-top: 125px;
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .news-slider-left-triangle-shape {
        left: 16.66%;
    }

    .arrows-container,
    .leftButton,
    .rightButton {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .news-slider-title {
        align-items: center;
        background-color: var(--bg-color-white);
        color: var(--color-main);
        display: flex;
        flex-direction: row;
        font-family: var(--font-main);
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-regular);
        height: 100%;
        justify-content: flex-start;
        padding-left: 50px;
        vertical-align: center;
    }
}

@media screen and (max-width: 512px) {
    .date-text,
    .year-text,
    .news-slider-title {
        font-size: var(--font-size-12);
    }
    .news-over-slider {
        height: 70px;
    }

    .news-slider-content img {
        height: calc(100% / 25.9);
        margin-top: 70px;
        width: 100%;
    }

    .news-slider-top {
        height: auto;
        padding-top: calc((100% / 2.59) + 70px);
    }

    .news-slider-date {
        padding-left: 20px;
    }
}
