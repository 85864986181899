.aurretikFacilityState {
  align-items: center ;
  color: var(--content-color-lighter-blue);
  display: flex;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
  z-index: var(--z-index-2)
}

.aurretikFacilityState--icon {
  margin-right: 10px;
}

.aurretikFacilityState--uppercase {
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-semi-bold);
  text-transform: uppercase;
}

.aurretikFacilityState p {
  margin: 0px;
}