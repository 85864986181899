.aurretikFacility__image-container {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  max-height: 220px;
  min-height: 213px;
  overflow: hidden;
  width: 100%;
}

.aurretikFacility__image-container img {
  align-self: center;
  margin: 0px;
  object-fit: cover;
}

.aurretikFacility__left {
  background-color: var(--bg-color-white);
  flex: 1;
  position: relative;
}

.aurretikFacility__right {
  flex: 1;
}

.aurretikFacility__left-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  z-index: var(--z-index-1);
}

.aurretikFacility__left-title {
  font-weight: var(--font-weight-semi-bold);
  padding: 20px;
  z-index: var(--z-index-1);
}

.aurretikFacility__bg {
  inset: 0;
  opacity: 0.3;
  position: absolute;
  z-index: var(--z-index-0);
}

@media (min-width: 700px) {
   .aurretikFacility__image-container {
    max-height: 515px;
    min-height: 350px;
  }
}

@media (min-width: 1100px) {
  .aurretikFacility {
    display: flex;
    justify-content: center;
    min-height: 610px;
    padding-top: 90px;
  }

  .aurretikFacility__data {
    align-self: flex-start;
    display: flex;
    flex: 1;
    height: fit-content;
    transform: translateX(-20%) translateY(5%);
  }

  .aurretikFacility--no-image .aurretikFacility__data {
    flex: 2;
  }

  .aurretikFacility__image-container {
    flex: 1;
    overflow: initial;
    width: auto;

  }

  .aurretikFacility__image-container img {
    max-height: 360px;
    width: auto;
  }
}