.navbar {
    padding-right: 0;
}

ul.navbar-nav.mr-auto {
    margin: 0;
}

.logo-header {
    width: 160px;
}

.navbar-expand-xl .navbar-nav .nav-link {
    padding-top: 17px;
}

.overWrapper {
    background: var(--color-gradient-start);
    background: linear-gradient(to right,
            var(--color-gradient-start) 0%,
            var(--color-gradient-end) 100%);
    float: right;
    height: 43px;
    padding-left: 195px;
    width: 100%;
}

.overMenu {
    align-items: center;
    color: var(--color-font-secondary);
    display: flex;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
    height: 43px;
    justify-content: flex-end;
    margin: 0 auto;
    padding-right: 150px;
    vertical-align: middle;
    width: 100%;
}

.language-switcher {
    margin-left: 25px;
}

.menuItems {
    display: none;
}

.search-icon {
    align-self: center;
    color: var(--color-font-secondary);
}

.fixMenu {
    height: 57px;
    position: absolute;
    top: 57px;
}

.logo-container {
    align-items: center;
    background-color: var(--bg-color-white);
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    display: flex;
    float: right;
    height: var(--header-desktop-height);
    justify-content: center;
    position: absolute;
    top: 0;
    width: 195px;
    z-index: var(--z-index-12);
}

.navbar-wrapper {
    -webkit-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.5);
    float: right;
    height: var(--header-desktop-height);
}

.navbar-wrapper *:focus-visible {
    outline: 2px solid black;
}

.indigo {
    background-color: rgba(138, 148, 166, 0) !important;
}

.hero {
    height: 240px;
    left: 0;
    margin: auto;
    padding-bottom: 4px;
    position: relative;
    top: 0;
    width: 100%;
}

.background-img {
    height: 100%;
    max-height: 340px;
    object-fit: cover;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    z-index: var(--z-index-minus-2);
}

.header__globe {
    fill: var(--color-font-secondary);
    width: 20px;
}

span.credits {
    background-size: contain;
    cursor: pointer;
    display: inline-block;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-regular);
    height: 20px;
    width: 20px;
}

span.credits svg {
    fill: var(--color-font-secondary);
}

.toolBox {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

span.button_name {
    color: var(--color-font-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-regular);
    margin-left: 10px;
    margin-top: 2px;
    text-transform: none;
}

.toolMenu__button,
.toolMenu__button:active,
.toolMenu__button:focus,
.toolMenu__button:hover {
    background: Transparent no-repeat !important;
    border: none;
    box-shadow: none;
    cursor: pointer;
    overflow: hidden;
}

.md-form .form-control {
    padding: 6px 8px 6px 12px;
}

.md-form input[type='text'],
.md-form input[type='password'],
.md-form input[type='email'],
.md-form input[type='url'],
.md-form input[type='time'],
.md-form input[type='date'],
.md-form input[type='datetime-local'],
.md-form input[type='tel'],
.md-form input[type='number'],
.md-form input[type='search-md'],
.md-form input[type='search'],
.md-form textarea.md-textarea {
    background: linear-gradient(270deg,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 255, 255, 0.2) 100%);
    border: none;
    border-radius: 2px;
    padding-right: 8px;
}

.form-control {
    border: none;
    color: var(--color-font-secondary);
}

.search-form {
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 2px;
    display: flex;
    height: auto;
    margin-bottom: 3px;
    margin-right: 10px;
    padding: 8px 0 8px 10px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: background-color 1000ms;
    transition: background-color 1000ms;
    width: 260px;
}

.search-form-hidden {
    background-color: transparent;
    border: none;
    border-radius: 2px;
    display: flex;
    height: auto;
    margin-bottom: 3px;
    margin-right: 10px;
    padding: 8px 0 8px 10px;
    transition: background-color 1s ease;
}

.overMenu #userLoginButton {
    color: var(--color-font-secondary);
}

.search-input::-webkit-input-placeholder {
    color: var(--color-font-secondary);
    opacity: 1;
}

.search-input:-moz-placeholder {
    color: var(--color-font-secondary);
    opacity: 1;
}

.search-input::-moz-placeholder {
    color: var(--color-font-secondary);
    opacity: 1;
}

.search-input:-ms-input-placeholder {
    color: var(--color-font-secondary);
    opacity: 1;
}

.search-form>a {
    color: var(--color-font-secondary);
    text-decoration: none;
}

.search-input {
    background-color: transparent;
    border-width: 0;
    color: var(--color-font-secondary);
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: opacity 1000ms;
    transition: opacity 1000ms;
    visibility: visible;
    width: 230px;
}

.search-hidden {
    background-color: transparent;
    border-width: 0;
    color: var(--color-font-secondary);
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
    visibility: hidden;
    width: 230px;
    display: none;
}

.popover {
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.5);
    z-index: var(--z-index-14);
}

.popover-body {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 0;
}

.language-button {
    height: 30px;
    max-width: 120px;
    width: 60px;
}

.language-button {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2px;
}

.language-button a {
    color: var(--language-buttons);
}

.language-button:hover a {
    color: var(--color-font-language-hover);
}

.language-button:hover {
    background-color: var(--color-main);
    color: var(--color-font-secondary);
}

.horizontalSeparator {
    margin: 0 auto;
    padding: 0;
    width: 80%;
}

.not-active {
    border-bottom: none !important;
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link>li {
    float: left;
    position: relative;
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link a::after {
    border-bottom: 5px solid var(--color-white);
    bottom: 0px;
    content: ' ';
    left: 0;
    margin-top: 1px;
    position: relative;
    right: 0;
}

.navbar-dark .navbar-nav:hover>.nav-link,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link a::after {
    border-bottom: 5px solid var(--color-white);
    bottom: 0px;
    content: ' ';
    left: 0;
    margin-top: 1px;
    position: relative;
    right: 0;
    transform: scale(1, 1);
    transform-origin: left top;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover .nav-link.Ripple-parent:focus {
    box-shadow: none !important;
    outline: 0;
}

/* FIX BOILEPLATE 1PX MOVEMENT WHEN HOVERED AND ACTIVE */
.navbar.navbar-dark .breadcrumb .nav-item>.nav-link,
.navbar.navbar-dark .navbar-nav .nav-item>.nav-link.menu-inverted {
    margin-top: 1px;
}

.navbar.navbar-dark .breadcrumb .nav-item>.nav-link,
.navbar.navbar-dark .navbar-nav .nav-item>.nav-link.menu-normal {
    margin-top: 1px;
}

/* END FIX */

.Ripple {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    display: none;
    opacity: 1;
    position: absolute;
    transform: scale(0);
}

.navbar.navbar-dark .navbar-toggler:hover {
    background-color: rgba(0, 0, 0, 0);
}

.navbar.navbar-dark .breadcrumb .nav-item>.nav-link,
.navbar.navbar-dark .navbar-nav .nav-item>.nav-link.menu-normal {
    background: rgba(0, 0, 0, 0.1);
    background: linear-gradient(to right,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(255, 255, 255, 0) 100%);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
    height: 57px;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    width: 165px;
}

.navbar.navbar-dark .breadcrumb .nav-item>.nav-link,
.navbar.navbar-dark .navbar-nav .nav-item>.nav-link.menu-inverted {
    background: rgba(0, 0, 0, 0.1);
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 255, 255, 0) 100%);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
    height: 57px;
    text-align: center;
    vertical-align: middle;
    width: 165px;
}

.navbar.navbar-dark .breadcrumb .nav-item>.nav-link,
.navbar.navbar-dark .navbar-nav .nav-item>.nav-link.first-link {
    background: rgba(0, 0, 0, 0);
}

.navbar.navbar-dark .breadcrumb .nav-item .nav-link>span,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link>span {
    margin: auto;
}

.navbar.navbar-dark .breadcrumb .nav-item>.nav-link.active,
.navbar.navbar-dark .navbar-nav .nav-item>.nav-link.active {
    height: 57px;
    width: 165px;
}

.navbar.navbar-dark .breadcrumb .nav-item .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link {
    color: var(--color-menu-text);
}

.navbar.navbar-dark .breadcrumb .nav-item .nav-link:hover,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
    color: var(--color-menu-text);
}

.last {
    height: 57px;
}

.imgLayer {
    background-color: var(--color-accent-900);
    height: 100%;
    left: 0;
    mix-blend-mode: multiply;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: var(--z-index-minus-1);
}

.navbar.scrolling-navbar {
    clear: both;
    height: 57px;
    margin-left: 194px;
}

.navbar-expand-xl .navbar-collapse.macromenu--opened {
    background: var(--bg-color-white) !important;
}

.background-img.macromenu--opened {
    display: none;
}

.overWrapper.macromenu--opened {
    background: var(--bg-color-white);
}

.navbar-wrapper.macromenu--opened {
    box-shadow: none;
}

.imgLayer.macromenu--opened {
    background-color: var(--bg-color-white);
}

.button_container {
    cursor: pointer;
    height: 26px;
    margin-left: 10px;
    margin-top: 10px;
    position: relative;
    transition: opacity 0.25s ease;
    width: 25px;
    z-index: var(--z-index-12);
}

.button_container.macromenu--opened {
    transform: translateX(-16px);
}

.button_container:hover {
    opacity: 0.7;
}

.button_container.macromenu--opened .top {
    background: var(--color-main);
    transform: translateY(7px) translateX(0) rotate(45deg);
}

.button_container.macromenu--opened .middle {
    background: var(--color-main);
    opacity: 0;
}

.button_container.macromenu--opened .bottom {
    background: var(--color-main);
    transform: translateY(-7px) translateX(0) rotate(-45deg);
}

.navbar.navbar-dark .breadcrumb .nav-item>.nav-link,
.navbar.navbar-dark .navbar-nav .nav-item>.nav-link.menu-inverted.macromenu--opened {
    display: none;
}

.navbar.navbar-dark .breadcrumb .nav-item>.nav-link,
.navbar.navbar-dark .navbar-nav .nav-item>.nav-link.menu-normal.macromenu--opened {
    display: none;
}

.button_container span {
    background: var(--color-font-secondary);
    border: none;
    cursor: pointer;
    height: 1.5px;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.35s ease;
    width: 100%;
}

.button_container span:nth-of-type(2) {
    top: 7px;
}

.button_container span:nth-of-type(3) {
    top: 14px;
}

@keyframes fadeInRight {
    0% {
        left: 20%;
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

.hiddenParagraph {
    display: none;
}

@media (max-width: 1200px) {
    .navbar-wrapper {
        height: 85px;
        max-width: 1140px;
        width: 100%;
    }

    .navbar-collapse {
        height: 100vh;
        z-index: var(--z-index-13);
    }

    .navbar.navbar-dark .breadcrumb .nav-item>.nav-link.Ripple-parent,
    .navbar.navbar-dark .navbar-nav .nav-item>.nav-link.Ripple-parent {
        color: var(--color-font-secondary);
        display: flex;
        flex-direction: column;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-medium);
        height: 77px;
        justify-content: center;
        text-align: center;
        vertical-align: middle;
        width: 100%;
    }

    .navbar.navbar-dark .breadcrumb .nav-item .nav-link>span:hover,
    .navbar.navbar-dark .navbar-nav .nav-item .nav-link>span:hover {
        margin-top: 10px;
    }

    .navbar-dark .navbar-nav .active>.nav-link,
    .navbar-dark .navbar-nav .nav-link.active,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .show>.nav-link a::after {
        border-bottom: none;
    }

    .navbar-dark .navbar-nav:hover>.nav-link,
    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .show>.nav-link a::after {
        border-bottom: none;
    }

    .logo-container {
        height: 85px;
        width: 179px;
    }

    .logo-header {
        width: 145px;
    }

    .overMenu,
    .overWrapper {
        display: none;
    }

    .navbar.scrolling-navbar {
        display: flex;
        flex-direction: row;
        height: 85px;
        justify-content: flex-end;
    }

    .toolMenu {
        color: var(--color-font-secondary);
        display: flex;
        flex-direction: row;
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-medium);
        justify-content: flex-end;
        margin-top: 10px;
        width: 80%;
    }

    .toolMenu>span {
        margin-left: 20px;
    }

    .togglerMenu {
        margin-right: 20px;
        width: 10%;
    }

    .menuItems {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 85px;
        justify-content: flex-end;
    }

    .gradient {
        background: var(--color-gradient-start);
        background: linear-gradient(to right,
                var(--color-gradient-start) 0%,
                var(--color-gradient-end) 100%);
        height: 85px;
        width: calc(100% - 179px);
    }

    .navbar.scrolling-navbar {
        margin: 0;
        padding: 0;
    }

    .navbar-expand-xl .navbar-collapse {
        background-color: var(--navbar-menu-collapse);
        opacity: 0.95;
    }

    .navbar.navbar-dark .breadcrumb .nav-item>.nav-link,
    .navbar.navbar-dark .navbar-nav .nav-item>.nav-link {
        background: rgba(0, 0, 0, 0);
    }

    .overWrapper {
        background: rgba(0, 0, 0, 0);
    }

    .button_container {
        display: none;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-collapse {
        background: var(--color-gradient-start);
        background: linear-gradient(to right,
                var(--color-gradient-start) 0%,
                var(--color-gradient-end) 100%);
        width: 100%;
    }
}

@media (max-width: 576px) {
    .logo-container {
        height: 65px;
        width: 84px;
    }

    .logo-header {
        width: 75px;
    }

    .menuItems {
        height: 65px;
        margin-right: 10px;
    }

    .navbar.scrolling-navbar {
        display: flex;
        flex-direction: row;
        height: 65px;
        justify-content: flex-end;
    }

    .gradient {
        background: var(--color-gradient-start);
        background: linear-gradient(to right,
                var(--color-gradient-start) 0%,
                var(--color-gradient-end) 100%);
        height: 65px;
        width: calc(100% - 84px);
    }

    div.collapse.show.navbar-collapse {
        width: 100vw;
    }

    .hero {
        height: 200px;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .navbar-wrapper {
        height: 65px;
    }

    .search-form {
        padding: 1px 0 0 5px;
        width: 100px;
    }

    .search-form-hidden {
        padding: 1px 0 0 5px;
        width: 100px;
    }

    .search-input {
        width: 80%;
    }

    .search-hidden {
        width: 80%;
    }

    .search-form>a {
        float: right;
        margin-right: 4px;
    }

    .search-form-hidden>a {
        float: right;
        margin-right: 4px;
    }
}

.navbar {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0),
        0 2px 10px 0 rgba(0, 0, 0, 0);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0), 0 2px 10px 0 rgba(0, 0, 0, 0);
}