.postal {
  margin-top: 20px;
  padding: 0 1em;
}

.postal__img-container {
  display: flex;
  justify-content: center;
  max-width: 100vw;
  overflow: hidden;
}

.postal__img-container img {
  margin: 0;
  max-width: 100%;
}