@media screen and (max-width: 1200px) {
    .column-rendered {
        padding: 0;
        min-height: 0;
    }
}

@media screen and (min-width:512px) and (max-width: 768px){
    .no-tablet{
        display: none;
    }
}

@media screen and (max-width:512px){
    .no-mobile{
        display: none;
    }
}

@media screen and (max-width: 768px){
    .no-render{
        display: none;
    }
}

