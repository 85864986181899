.planDetail-box_container-chart_title,
.planDetail-box_container-downloads_title,
.planDetail-box_container-links_title {
  color: var(--color-main);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-medium);
  margin-bottom: 1rem;
}

.planDetail-box_container-links_title {
  margin-top: 50px;
}

@media screen and (max-width: 568px) {
  .planDetail-box_container-chart_title {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    width: 50%;
  }
}