
.form-input {
  appearance: none;
  border: 1px solid #F0F0F0;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  font-size: 0.875rem;
  min-height: 45px;
  padding: 0.5rem;
  width: 100%;
}

.form-input:focus,
.form-input:active {
  outline-color: var(--color-main);
}

.form-input:focus {
  border-color: #F0F0F0;
}

.form-input-error {
  background-color: var(--color-main-faded-bg);
  border: 1px solid var(--color-main);
}
