.userDataContainer__form {
  align-items: center;
  background-color: var(--bg-color-white);
  box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 70px 0 100px;
}

.userDataContainer__form .alertMessage {
  border: 1px solid transparent;
  width: 50% !important;
  border-radius: 0.25rem;
  font-weight: var(--font-weight-regular);
  padding: 0.75rem 1.25rem;
  display: grid !important;
  grid-template-columns: 8fr 3fr;
  height: 3.5rem !important;
  margin-bottom: 10px;
}

.userDataContainer__form .alertMessage.errorMessage {
  background-color: var(--color-pale-pink);
  border-color: var(--color-azalea);
  color: var(--color-red-berry);
}

.userDataContainer__form .alertMessage.successMessage {
  background-color: #D1E7DD;
  border-color: #A3CFBB;
  color: #355644;
}

.userDataContainer__closeBtn {
  cursor: pointer;
  font-size: var(--font-size-18);
  transition: 0.3s;
  justify-self: end;
}

.userDataContainer__closeBtn:hover {
  color: #D9BDBA;
}

.hartuzBookingGrid__concurrence-error {
  background-color: var(--color-pale-pink);
  border: 1px solid transparent;
  border-color: var(--color-azalea);
  border-radius: 0.25rem;
  color: var(--color-red-berry);
  font-weight: var(--font-weight-regular);
  padding: 0.75rem 1.25rem;
}

.userDataContainer__form .alertMessage p {
  margin: 0;
  justify-self: start;
}

.userDataContainer__form-header {
  font-size: 30px;
  margin-bottom: 30px;
  text-decoration: underline;
}

.userDataContainer__form div:not(.submitUserData) {
  align-items: center;
  display: flex;
  height: 90px;
  justify-content: center;
  width: 100%;
}

.userDataContainer__form label {
  align-items: center;
  display: flex;
  width: 20%;
}

.userDataContainer__form label,
.userDataContainer__form input,
.userDataContainer__form select {
  color: var(--color-text-news);
  height: 48px;
}

.userDataContainer__form input:disabled {
  color: #898989;
  background-color: #e8e8e8;
}

.userDataContainer__form div.calendarDiv [aria-label] {
  color: transparent;
}

.userDataContainer__form div.calendarDiv [aria-label] svg {
  fill: #757575;
}

.userDataContainer__form input,
.userDataContainer__form select,
.userDataContainer__form div.calendarDiv {
  border: 2px solid var(--color-white-smoke);
  border-radius: 6px;
  padding: 0.375rem 0.75rem;
  width: 30%;
  height: 48px;
}

.userDataContainer__form div.calendarDiv input,
.userDataContainer__form div.calendarDiv input:focus,
.userDataContainer__form div.calendarDiv fieldset {
  border: none;
  width: auto;
  height: 48px;
  outline: none !important;
}

.userDataContainer__form div.calendarDiv button,
.userDataContainer__form div.calendarDiv button:hover,
.userDataContainer__form div.calendarDiv button:focus,
.userDataContainer__form div.calendarDiv button:focus-visible {
  width: 100%;
  text-align: right;
  justify-content: right;
  background-color: rgba(0, 0, 0, 0);
}

.userDataCensus__select {
  background-color: white;
}

.submitUserData {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  width: 50%;
}

.submitUserData button {
  background-color: var(--bg-color-white);
  border: 1px solid var(--color-monza);
  border-radius: 25px;
  color: var(--color-monza);
  font-weight: var(--font-weight-medium);
  padding: 10px 30px;
}

.submitUserData button:hover {
  background-color: var(--color-main);
  color: var(--bg-color-white);
}

@media (min-width: 1280px) {
  .userDataContainer__content {
    padding: 75px 0 62px;
  }
}