.cardGrid {
  display: grid;
  grid-gap: 28px;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  width: 100%;
}

@media (min-width: 1100px) {
  .cardGrid {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 23rem));
  }
}
