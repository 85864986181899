.aurretikFacilitySeason {
  background-color: var(--bg-color-white);
  color: var(--content-color-medium-blue);
  display: flex;
  font-size: var(--font-size-11);
  font-weight: var(--font-weight-regular);
  line-height: 14px;
  padding: 15px;
  z-index: var(--z-index-2);
}

.aurretikFacilitySeason__bold-text {
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-bold);
  line-height: 15px;
}

.aurretikFacilitySeason svg {
  margin-right: 10px;
  width: 34px;
}

.aurretikFacilitySeason svg .calendar-day {
  fill: var(--content-color-light-blue);
}

.aurretikFacilitySeason svg .calendar-outline {
  fill: var(--content-color-medium-blue);
}

.aurretikFacilitySeason div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}