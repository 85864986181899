.planProgress {
    background-color: var(--bg-color-white);
    box-shadow: 0px 0px 14px 1px rgba(0,0,0,0.08);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    margin-top: 50px;
    width: 90%;
}
.planProgress-box {
    background-color: var(--bg-color-lighter-grey);
    display: flex;
    justify-content: center;
}
.planProgress-box-title {
    align-items: center;
    color: var(--color-main);
    display: flex;
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-semi-bold);
    height: 130px;
    justify-content: space-between;
    margin-left: 40px;
    margin-right: 40px;
}
.planProgress-box-title a h1 {
    color: var(--color-main);
    font-size: var(--font-size-22);
    margin-bottom: 0px;
}

.chart-title{
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 90px;
}
.planProgress-box-title_chart-progress {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 60px;
}
.planProgress-box_container {
    margin-bottom: 50px;
    margin-top: 50px;
    width: 87%;
}
.table td {
    border-top: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.table tr {
    line-height: 0px !important;
}
.table th {
    border-bottom: 1px solid var(--color-pattens-blue) !important;
    padding-left: 0 !important;
}
.table th p{
    color: var(--color-main);
    font-weight: var(--font-weight-semi-bold) !important;
}
.table tr p{
    font-weight: var(--font-weight-semi-bold) !important;
}
.task {
    border-right: 1px solid var(--color-pattens-blue) !important;
    color: var(--color-dim-gray);
    line-height: 1.3em ;
    width: 25%;
}
.partial-percentage {
    color: var(--color-dim-gray);
    text-align: right !important;
    width: 10%;
}
.progress {
    height: 4px;
    margin-left: 40px !important;
}

.finalizadas {
    background-color: var(--bg-color-green);
}

.en_ejecucion_avanzada {
    background-color: var(--bg-color-yellow-green);
}

.iniciada {
    background-color: var(--bg-color-yellow);
}

.en_diseno {
    background-color: var(--bg-color-orange);
}

.no_iniciadas {
    background-color: var(--bg-color-red);
}

@media screen and (max-width:960px) {
    .planProgress {
        display: flex;
        justify-content: center;
        margin-left: 30px;
        margin-right: 30px;
        width: 95%;
    }
    .planProgress-box_container {
        margin-bottom: 50px;
        margin-top: 35px;
        width: 90%;
    }
    .planProgress-box-title {
        margin-right: 50px;
    }
    .planProgress-box-title h1 {
        margin-left: 0;
    }
    .chart-title {
        margin-right: 0px;
    }
    .table tr {
        line-height: 12px !important;
    }
}
@media screen and (max-width:568px) {
    .planProgress {
        margin-bottom: -48px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: -20px;
        width: 100%;
    }
    .planProgress-box {
        background-color: var(--color-background);
        display: flex;
        justify-content: center;
    }
    .planProgress-box-title {
        align-items: center;
        color: var(--color-main);
        display: flex;
         font-size: var(--font-size-14);
        font-weight: var(--font-weight-semi-bold);
        height: 80px;
        justify-content: space-between;
        margin-bottom: 10px;
        margin-left: 13%;
        margin-top: 10px;
        width: 75%;
    }  
    .planProgress-box-title_chart-search {
        width: 60%;
    }
    .planProgress-box_container {
        margin-bottom: 50px;
        margin-top: 20px;
        width: 90%;
    }
    .planProgress-box-title h1 {
        margin-left: 0px;
    }
    .chart-title {
        margin-right: 0;
        width: 67px;
    }
    .Acciones{
        width: 161px;
    }
    .table td {
        border-right: none !important;

    }
    .table tr {
        line-height: 12px !important;
    }

    .partial-percentage {
        width: 13%;
    }

    .progress {
        margin-right: 30px !important;
    }
}