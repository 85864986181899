.indicenciaDetail {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20em;
    min-height: 1240px;
    width: 100%;
}

.indicenciaDetail_mapContainer {
    height: 700px;
    position: absolute;
    width: 100%;
}

.fatherIncidenceButton {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    width: 100%;
}

.incidencia_padre {
    align-items: center;
    background-color: var(--bg-color-grey);
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    height: 48px;
    justify-content: center;
    margin-top: 15px;
    width: 291px;
}

.incidencia_padre a {
    color: var(--color-mine-shaft);
    font-size: var(--font-size-14);
}

.indicenciaDetail-infobox {
    background-color: var(--bg-color-lighter-grey);
    height: 100%;
    margin-bottom: 23em;
    position: relative;
    top: 570px;
    width: 80%;
}

.incidenciaDetail-infobox_header {
    background-color: var(--bg-color-white);
    display: flex;
    flex-direction: row;
    height: 130px;
    justify-content: space-between;
    padding: 40px;
}

/*.imagenIncidenciaGaleria {
    border: solid 1px #E1E4E8;
}*/

.imagenIncidencia {
    /*background-color: black;*/
    /*margin-left: 40px !important;*/
    /* margin-right: 40px!important;*/
    display: flex;
    justify-content: center;
    /*min-width: 500px;*/
    max-width: 160px;
    float: left;
    border: solid 1px #E1E4E8;
    min-height: 160px;
    border-radius: 0.25rem;
    max-height: 160px;
    margin-right: 10px;
}

.imagenIncidencia a {
    align-items: center;
    justify-content: center;
    display: flex;
}

.incidenciaDetail-infobox_state {
    background-color: var(--bg-color-white);
    display: flex;
    flex-direction: column;
    height: 130px;
    height: auto;
    justify-content: space-between;
    padding: 40px;
}

.infobox_header-title {
    align-items: center;
    color: var(--color-main);
    display: flex;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    width: 50%;
}

.calendar {
    height: 15px;
    width: 20px;
}

.date-bell {
    display: flex;
    justify-content: space-evenly;
    padding-right: 10px;
    width: 50%;
}

.infobox_header-status {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: var(--font-size-14);
    justify-content: flex-end;
    width: 50%;
}

.infobox_header-status_status {
    align-items: center;
    border-radius: 21.84px;
    display: flex;
    flex-direction: row;
    font-weight: var(--font-weight-medium);
    height: 30px;
    justify-content: center;
    margin-bottom: unset;
    margin-top: unset;
    width: 132px;
}

.infobox_header-status_status p {
    margin-bottom: 1rem;
    margin-left: 6px;
    margin-top: revert;
}

.infobox_header-status_status_circle {
    border-radius: 1000px;
    height: 16px;
    width: 16px;
}


/*ESTADO DEL PROCESO*/

.infobox_status-proces {
    background-color: var(--bg-color-darker-white);
    display: flex;
    flex-direction: column;
    height: 214px;
    justify-content: center;
}

.infobox_status-bar {
    display: flex;
    justify-content: center;
}

.infobox_status-bar svg {
    width: 80%;
}

.infobox_status-bar-textsContainer {
    display: flex;
    justify-content: center;
}

.infobox_status-bar-textsContainer p {
    margin-bottom: 0;
}

.infobox_status-bar-textsContainer-date {
    width: 100px;
}

.infobox_status-bar-texts {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.infobox_status-bar-texts-proceso {
    display: flex;
    justify-content: space-between;
    width: 80%;
}


/*DESCRIPCION*/

.infobox_status-description {
    background-color: var(--bg-color-white);
    display: flex;
    flex-direction: row-reverse;
    height: 340px;
    padding-right: 40px;
}

.infobox_status-description-photo {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;
}

.infobox_status-description-photo img {
    align-self: center;
    border-style: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    width: 75%;
}

.image-slider {
    height: 100%;
    width: 100%;
}

.image-slider div {
    height: 340px;
    width: 49%;
}

.infobox_status-description-text {
    color: var(--color-text-news);
    font-size: var(--font-size-14);
    height: 340px;
    overflow-y: auto;
    padding-top: 1%;
    width: 48%;
}

::-webkit-scrollbar-thumb {
    background: var(--color-iron);
    border-radius: 4px;
}

.infobox_status-description ::-webkit-scrollbar {
    background-color: var(--bg-color-white);
    width: 6px;
}

.actualizaciones {
    align-items: center;
    background-color: var(--bg-color-white);
    border-radius: 15px;
    display: flex;
    display: -ms-flexbox;
    height: 70px;
    width: 176px;
}

.actualizaciones p {
    color: var(--color-biscay);
    font-family: var(--typography-content);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-medium);
    height: 23px;
    letter-spacing: 0;
    line-height: 19px;
    margin: 0;
    text-align: center;
    width: 140px;
}

.actualizaciones-title {
    align-items: center;
    background-color: var(--bg-color-lighter-grey);
    border-radius: 15px;
    display: flex;
    font-size: var(--font-size-16);
    height: 30px;
    justify-content: center;
    width: 176px;
}

.categoria {
    align-items: center;
    background-color: var(--bg-color-lighter-grey);
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    height: 30px;
    margin: 5px 50px;
    width: 75%;
}

.categoria svg {
    height: 20px;
    margin: 7%;
    margin-top: 10%;
    width: 20px;
}

.categoria p {
    margin-bottom: 1rem;
    margin-top: revert;
}

.direccion {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 13%;
    margin-top: 5%;
}

.direccion_location {
    display: flex;
}

.direccion_location p {
    margin-left: 2px;
}

.direccion_masinfo {
    margin-left: 5%;
}

.descripcion_incidencia {
    height: 200px;
    margin-left: 20%;
    width: auto;
}

.descripcion_incidencia p {
    margin-left: -10%;
    margin-top: 5%;
    padding-right: 15px;
    text-align: left;
}

.texto_actualizacion {
    align-items: center;
    background-color: var(--bg-color-lighter-grey);
    color: var(--color-biscay);
    display: flex;
    flex-direction: row;
    font-family: var(--typography-content);
    font-size: var(--font-size-14);
    height: 43px;
    height: auto;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 6px;
    padding: 20px 20px 20px 0;
}

.texto_actualizacion p {
    color: var(--color-biscay);
    margin-left: 8em;
    width: 80%;
}

.texto_actualizacion div {
    color: var(--color-biscay);
    margin-left: 1em;
    text-align: center;
    width: 25%;
}


/* } */

@media screen and (max-width: 1200px) {
    .indicenciaDetail_mapContainer {
        width: 100%;
    }

    .indicenciaDetail {
        margin-top: -40px;
    }
}

@media screen and (max-width: 1024px) {
    .indicenciaDetail {
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: 0%;
        min-height: 1240px;
        width: 100vw;
    }

    .infobox_status-description {
        background-color: var(--bg-color-white);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: column;
        height: 340px;
        height: auto;
        /* margin-bottom: 131px; */
        justify-content: center;
        padding-right: 0;
    }

    .infobox_status-description-text {
        height: 300px;
        margin-bottom: 10px;
        padding-top: 25px;
        width: 100%;
    }

    .direccion_location p {
        margin-left: 1%;
    }

    .categoria {
        margin: 1% 13%;
        width: 50%;
    }

    .descripcion_incidencia {
        height: 108px;
        margin-left: 13%;
        width: 75%;
    }

    .descripcion_incidencia p {
        margin-left: 0%;
        padding-right: 0;
    }

    .infobox_status-description-photo {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .infobox_status-description-photo img {
        margin: 0;
        width: 75%;
    }

    .actualizaciones {
        width: 100%;
    }

    .actualizaciones p {
        width: 100%;
    }

    .incidenciaDetail-infobox_state {
        padding: 10%;
    }

    .infobox_status-description-photo {
        display: flex;
        height: 340px;
        width: 100%;
    }

    .image-slider div {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {

    .infobox_header-title {
        width: 55%;
    }

    .infobox_header-status {
        width: 35%;
    }

    .infobox_header-status_status {
        border: 1px solid var(--color-biscay);
        border-radius: 21.84px;
        font-weight: var(--font-weight-medium);
        height: 32px;
        width: 290px;
    }

    .categoria svg {
        height: 20px;
        margin: 10px 7px 0px 7px;
        width: 20px;
    }

    .infobox_status-description {
        align-items: center;
        background-color: var(--bg-color-white);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: column;
        height: 340px;
        /* margin-bottom: 131px; */
        height: auto;
        padding-right: 0;
    }

    .infobox_status-description-text {
        color: var(--color-text-news);
        font-size: var(--font-size-14);
        height: 300px;
        min-height: 190px;
        overflow-y: auto;
        padding-top: 5%;
        width: 100%;
    }

    .descripcion_incidencia p {
        margin-left: 0%;
        margin-top: 5%;
        text-align: center;
        width: 75%;
    }

    .infobox_status-description-photo img {
        height: 100%;
        margin: 0;
        width: 100%;
    }

    .direccion {
        align-items: center;
        margin-left: 0;
    }

    .actualizaciones {
        align-content: center;
        -ms-flex-align: center;
        justify-content: center;
    }

    .texto_actualizacion {
        margin-bottom: 10px;
        padding: 20px 0 20px 0;
    }

    .incidenciaDetail-infobox_header {
        flex-direction: column;
        height: 45%;
    }

    .title-button {
        display: flex;
        justify-content: space-between;
    }

    .date-bell-cat {
        display: flex;
        justify-content: space-between;
    }

    .categoria {
        height: auto;
        margin: 0;
        margin-top: 16px;
        width: 500px;
    }

    .categoria p {
        margin: 0;
    }

    .direccion_location {
        justify-content: center;
        width: 60%;
    }

    .direccion_masinfo {
        margin-left: 5%;
    }

    .descripcion_incidencia {
        display: flex;
        height: auto;
        height: 180px;
        justify-content: center;
        margin-left: 0%;
        width: 100%;
    }

    .texto_actualizacion {
        display: flex;
        flex-direction: column;
        height: auto;
        margin-bottom: 10px;
    }

    .texto_actualizacion div {
        margin-left: 0;
        width: 100%;
    }

    .texto_actualizacion p {
        color: var(--color-biscay);
        margin-left: unset;
        margin-top: 21px;
        text-align: center;
        width: 80%;
    }

    .incidencia_padre {
        width: 50%;
    }
}

@media screen and (max-width: 576px) {
    .incidenciaDetail-infobox_header {
        flex-direction: column;
        height: 60%;
        padding: 20px;
    }

    .title-button {
        flex-direction: column;
    }

    .infobox_header-status {
        justify-content: center;
        margin-bottom: 10%;
        width: 100%;
    }

    .infobox_header-title {
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .date-bell-cat {
        flex-direction: column;
    }

    .date-bell {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3%;
        width: 100%;
    }

    .categoria {
        font-size: 95%;
        width: 100%;
    }

    .infobox_header-status_status {
        width: 60%;
    }

    .state-rotate {
        font-size: 70%;
    }

    .infobox_status-proces {
        background-color: var(--bg-color-darker-white);
        display: flex;
        flex-direction: row;
        height: 300px;
        justify-content: center;
        padding-bottom: 12px;
    }

    .infobox_status-bar-textsContainer {
        height: 100%;
        margin-left: 15px;
        position: relative;
        right: 76px;
        width: 100%;
    }

    .infobox_status-bar-texts-proceso {
        width: 100%;
    }

    .infobox_status-bar-texts {
        display: -ms-flexbox;
        display: flex;
        flex-direction: row-reverse;
        -ms-flex-pack: justify;
        width: 100%;
    }

    .state-rotate {
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        font-size: 90%;
        height: 33%;
        margin-bottom: 30px;
        margin-top: 8px;
        width: 144px;
    }

    .infobox_status-bar-texts {
        display: -ms-flexbox;
        display: block;
        -ms-flex-pack: justify;
        height: 100%;
    }

    .infobox_status-bar-textsContainer p {
        margin-bottom: 0;
        /* transform: rotate(270deg); */
    }

    .infobox_status-bar {
        height: 20%;
        position: relative;
        right: -40px;
        top: 123px;
        transform: rotate(90deg);
        width: 300px;
    }

    .infobox_status-bar svg {
        height: 46px;
        margin: 1%;
        width: 270px;
    }
}

@media screen and (max-width: 440px) {
    .infobox_status-bar svg {
        bottom: 36%;
    }

    .categoria {
        font-size: 80%;
    }
}