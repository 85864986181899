.button {
  align-items: center;
  background-color: var(--color-main);
  border: 1px solid var(--color-main);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  gap: 16px;
  justify-content: center;
  padding: 12px 61px 12px 55px;
  transition: 0.3s;
  width: 100%;
}

.button:hover,
.button:focus {
  opacity: .6;
}

.button--inverted {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-main);
  fill: var(--color-main);
}

.button--inverted:focus,
.button--inverted:hover {
  background-color: var(--color-main-faded);
  border-color: var(--color-main-faded);
  color: var(--color-white);
}

.button--disabled {
  border-color: var(--color-mine-shaft);
  color: var(--color-mine-shaft);
  color: var(--color-white);
  fill: var(--color-mine-shaft);
  opacity: 0.7;
}

.button--disabled:focus,
.button--disabled:hover {
  background-color: var(--color-white);
  border-color: var(--color-mine-shaft);
  color: var(--color-mine-shaft);
  fill: var(--color-mine-shaft);
}


@media (min-width: 1280px) {
  .button {
    padding: 12px 39px;
  }
}
