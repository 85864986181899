.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden!important;
}

.banner img {
    flex-shrink: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    display: block !important;
}
