.hartuzContainer__top {
  background-color: var(--bg-color-white);
  padding-bottom: 40px;
}

.hartuzContainer__top--tall {
  padding-bottom: 80px;
}

.hartuzCancelationButton__container {
  margin-top: 30px;
}

.hartuzContainer__bottom {
  padding-top: 40px;
}

.flex-items {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

@media (min-width: 1024px) {
  .hartuzCancelationButton__container {
    margin-top: 40px;
  }
}

@media (min-width: 1280px) {
  .hartuzContainer__top {
    background-color: var(--bg-color-white);
    padding: 75px 0 62px;
  }

  .hartuzContainer__bottom {
    padding-top: 80px;
  }
}

@media (max-width: 600px) {
  .flex-items {
    flex-direction: column;
    justify-content: center;
  }
}
