.hartuzBookingDateForm__input--wrapper {
  width: 100%;
}

.hartuzBookingDateForm__input--wrapper .form-group .form-input {
  background-color: var(--color-white);
}

.hartuzBookingDateForm__submit {
  align-self: center;
}

.hartuzBookingDateForm__submit button {
  max-width: 270px;
}

.hartuzBookingGrid__content--form {
  background-color: var(--bg-color-hawks-blue);
  padding: 75px 20px;
}


.hartuzBookingGrid__content--form select, .SingleDatePickerInput {
  min-height: 46px;
  width: 100%;
}

.hartuzBookingGrid__content--form,
.hartuzBookingGrid__content--info {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  min-height: 326px;
}

.hartuzTimeSelect__wrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;
}

.hartuzTimeSelect__wrapper span {
  grid-column: 1/2;
  grid-row: 1/2;
  margin-left: 20px;
  width: min-content;
  z-index: var(--z-index-1);
}

.hartuzTimeSelect__wrapper span svg path,
.hartuzTimeSelect__wrapper span svg circle {
  stroke: var(--color-main);
}

.hartuzTimeSelect__select {
  align-content: center;
  background-color: var(--color-white);
  border: none;
  display: flex;
  grid-column: 1/3;
  grid-row: 1/2;
  height: 100%;
  min-height: 46px;
  padding-left: 61px;
  width: 100%;
}

.hartuzTimeSelect__select,
.hartuzTimeSelect__select option {
  color: var(--color-main);
  font-family: var(--typography-content);
  font-size: var(--font-size-14);
  font-style: italic;
  font-weight: var(--font-weight-regular);
}

.hartuzBookingDateForm__form-group-disabled.form-group {
  margin-bottom: 0.5rem;
}

.hartuzBookingDateForm__input-delete {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-14);
  gap: 5px;
  justify-content: flex-end;
}

.hartuzBookingDateForm__input-delete p {
  color: var(--color-main);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  margin: 0px;
}

.hartuzBookingDateForm__input-delete svg {
  height: 20px;
  width: 20px
}

.hartuzBookingDateForm__input-delete svg path {
  stroke: var(--color-main);
}

@media (min-width: 768px) {
  .hartuzBookingGrid__content--form {
    flex: 1;
    padding: 10px 30px;
  }

  .hartuzTimeSelect__select,
  .hartuzTimeSelect__select option {
    font-size: var(--font-size-16);
  }
}

@media (min-width: 1024px) {
  .hartuzBookingGrid__content--form {
    padding: 10px 40px;
  }

  .hartuzTimeSelect__select,
  .hartuzTimeSelect__select option {
    font-size: var(--font-size-14);
  }
}