.detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -60px 0 0 auto;
    position: relative;
    width: 100%;
}

.detail__content {
    background-color: var(--bg-color-white);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
    padding-bottom: 50px;
    padding-right: 150px;
    position: relative;
    width: 90%;
}

.detail__wrapper {
    align-content: space-between;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.detail__attachments {
    background-color: var(--bg-color-white);
    bottom: 0;
    min-height: 50px;
    position: absolute;
    right: 69.31%;
    width: 300px;
}

.detail__content-box {
    margin-bottom: 100px;
    margin-left: 355px;
    margin-top: 100px;
    max-width: 785px;
    width: 100%;
}

.detail__sidebar {
    background: url('../../assets/img/menu_transparencia.jpg') repeat;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 114px;
    padding-top: 97px;
    position: absolute;
    width: 30.69%;
    z-index: var(--z-index-1);
}

.detail__sidebar * {
    position: relative;
    /* hack */
}

.detail__title {
    color: var(--color-main);
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-semi-bold);
}

.detail__subtitle {
    color: var(--color-main);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-regular);
    margin-bottom: 50px;
    margin-top: 50px;
}

.detail__category-box {
    margin: 0 auto;
    max-width: 543px;
    width: 100%;
}

.overlay:before {
    content: ' ';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: var(--z-index-0);
}

.blue:before {
    background-color: var(--color-main);
    opacity: 0.9;
}

@media screen and (max-width: 1200px) {
    .detail__sidebar {
        display: none;
    }

    .detail__content {
        padding-right: 0;
        width: 100%;
    }

    .detail__content-box {
        margin: 60px auto 100px auto;
        max-width: 785px;
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .detail__content {
        background-color: rgba(0, 0, 0, 0);
    }

    .detail__content-box {
        margin: 0px auto 100px auto;
    }
}
