.removeFiltersButton {
  padding: 0 10px;
  background-color: var(--color-white);
  color: var(--color-main);
  font-weight: var(--font-weight-semi-bold);
  border: 1px solid var(--color-main);
  border-radius: 5px;
  height: 40px;
}

.removeFiltersButton:hover {
 background-color: var(--color-main);
 color: var(--color-white);
}