.mainDiv {
    width: 100%;
}
.finderDetail {
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 213px;
    margin-left: 5%;
    margin-top: 91px;
    width: 90%;
}
.finder-form {
    background-color: var(--bg-color-white);
    padding: 40px 40px 60px 40px;
}
.finderDetail_title {
    align-items: center;
    color: var(--color-main);
    display: flex;
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-semi-bold);
    height: 130px;
    justify-content: space-between;
    margin-left: 40px;
    margin-right: 40px;
}

.finder-infobox_search {
    padding-bottom: 40px;
}

.finder-infobox_searchInput {
    display: flex;
}
.search_bar_finder {
    border: 2px solid var(--color-white-smoke);
    border-radius: 6px;
    height: 60px;
    margin: 20px 0;
    margin-right: -2%;
    padding-left: 2%;
    width: 100%;
  }
.search_bar {
    border: 2px solid var(--color-white-smoke);
    border-radius: 6px;
    height: 60px;
    margin: 20px 0;
    margin-right: -2%;
    padding-left: 3%;
    width: 100%;
}
.search_select_finder {
    background-color: var(--bg-color-white);
    border: 2px solid var(--color-white-smoke);
    border-radius: 6px;
    color: var(--color-text-news);
    height: 48px;
    padding-left: 2%;
    position: relative;
    width: 100%;
}
.select {
    width: 38%;
}

.desplegables_boton {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.submit-button {
    display: flex;
    justify-content: flex-end;
}
.finder-reportBoton {
    align-items: center;
    background-color: var(--color-main);
    border-radius: 2px;
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    height: 48px;
    justify-content: center;
    text-align: center;
    width: 18%;
}
.finder-container {
    background-color: var(--bg-color-light-grey);
    height: auto;
    padding: 0 40px 40px 40px;
    padding-bottom: 40px;
    width: 100%;
}

.finder-container-title {
    align-items: center;
    background-color: var(--bg-color-light-grey);
    color: var(--color-main);
    display: flex;
    font-weight: var(--font-weight-semi-bold);
    height: 130px;
    letter-spacing: 0.55px;
    line-height: 28px;
    width: 100%;
}

.finder-container-content {
    background-color: var(--bg-color-white);
    width: 100%;
}

.box-plans {
    margin: 0 40px;
}

.fila-impar {
    background-color: var(--bg-color-white);
    flex-direction: row;
    line-height: 30px;
    padding: 0 2%;
}

.fila-par {
    background-color: var(--bg-color-light-grey);
    flex-direction: row;
    line-height: 30px;
    padding: 0 2%;

}
.box-plans div div {
padding-top: 1rem;
}

.plan-content {
    color: var(--color-dim-gray);
    font-weight: var(--font-weight-bold);
    padding: 15px 0 15px 20px;
}

.axis-content {
    color: var(--color-dim-gray);
    padding: 15px 0 15px 50px;
}

.action-content {
    color: var(--color-dim-gray);
    padding: 15px 0 15px 70px;
} 

.just-action-content {
    color: var(--color-dim-gray);
    padding: 15px 0 15px 20px;
} 
.porcentaje-content {
    color: var(--color-dim-gray);
    text-align: center;
}

.rendicion-tabla tr th {
    width: 33%;
}

.rendicion-tabla {
    width: 100%;
}


.enlace-content {
    font-weight: var(--font-weight-bold);
    padding-right: 20px;
    text-align: end;
}

.link-color {
    color: var(--color-main);
}

.link-color:hover {
    color: var(--color-main); 
}

table:nth-child(even) {
    background-color: var(--bg-color-light-grey);
  }

.finder-no-results {
    display: flex;
    font-weight: var(--font-weight-semi-bold);
    justify-content: center;
}
  @media screen and (max-width: 768px) {
    .finder-infobox_search {
        padding-bottom: 20px;    
    }
    .finder-form {
        padding: 30px 30px 50px 30px;    
    }
    .select {
        width: 47%;
    }
    .desplegables_boton {
        padding-bottom: 40px;
    }
    .finder-reportBoton {
        width: 47%;
    }
    .finder-container-title {
        height: 100px;
    }
    .porcentaje-content {
        text-align: end;
    }
    .finder-container {
        padding: 0 30px 40px 30px;
    }
  }
  @media screen and (max-width: 568px) {

    .finderDetail {
        margin-bottom: 0;
    }
    .finder-form {
        padding: 0 16px 30px 16px;
    }
    .finder-infobox_search {
        padding-bottom: 0;
    }
    .search_bar_finder {
        height: 48px;
    }
    .desplegables_boton {
        flex-direction: column;
        padding-bottom: 20px;
    }
    .select {
        padding-bottom: 20px;
        width: 100%;
    }
    .finder-reportBoton {
        width: 100%;
    }
    .finder-container {
        padding: 0;
    }
    .finder-container-title {
        padding-left: 20px;
    }
    .porcentaje-content, 
    .enlace-content{
        display: none;
    }
    .rendicion-tabla tr th {
        width: 100%;
    }
  }