.campo {
    margin-bottom: 40px;
}

.titulo-field {
    color: var(--color-main);
    font-family: var(--typography-content);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-medium);
    height: 23px;
    letter-spacing: 0;
    line-height: 19px;
    width: auto;
}

.field {
    background-color: var(--bg-color-white);
    border: 1px solid var(--color-white-smoke);
    border-radius: 2px;
    box-sizing: border-box;
    height: 48px;
    width: 100%;
}

.error-message {
    color: #E0241B;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-semi-bold);
}

.descripcion {
    background-color: var(--bg-color-white);
    border: 1px solid var(--color-white-smoke);
    border-radius: 2px;
    box-sizing: border-box;
    height: 202px;
    width: 100%;
}

.image {
    align-items: center;
    background: transparent;
    border: 1px dashed var(--color-iron);
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    height: 270px;
    width: 22em;
}

.imageBackground {
    background-image: url("/src/assets/img/pre_imagen.jpg");
    background-repeat: no-repeat;
    background-size: 270px 467px;
    height: 270px;
    width: 467px;
}

.categorias {
    color: var(--color-main);
    font-family: var(--typography-content);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-light);
    height: 46px;
    letter-spacing: 0;
    line-height: 23.52px;
    width: 31em;
}

.categoriasNewIncidence {
    background-color: var(--bg-color-white);
    border: 1px solid var(--color-white-smoke);
    border-radius: 2px;
    box-sizing: border-box;
    height: 48px;
    width: 31em;
}

@media screen and (max-width: 568px) {
    .field {
        border-radius: 15px;
        height: 30px;
        width: 100%;
    }

    .image {
        height: 185.35px;
        width: 219px;
    }

    .imageBackground {
        background-image: url("/src/assets/img/pre_imagen.jpg");
        background-repeat: no-repeat;
        background-size: 270px 467px;
        height: 185.35px;
        width: 219px;
    }

    .categorias {
        color: var(--color-main);
        font-family: var(--typography-content);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-light);
        height: 44px;
        letter-spacing: 0;
        line-height: 23.52px;
        width: 15em;
    }

    .descripcion {
        background-color: var(--bg-color-white);
        border: 1px solid var(--color-white-smoke);
        border-radius: 2px;
        box-sizing: border-box;
        height: 195px;
        width: 100%;
    }
}