.category-icon {
    width: 38px;
    height: 38px;
    fill: var(--color-main);
}

.category-icon--little {
    width: 26px;
    fill: var(--color-main);
}
