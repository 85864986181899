.aurretikStatusIcon {
  height: auto;
  margin-right: 10px;
  width: 25px;
}

.aurretikStatusIcon--red #Oval-Copy-56 {
  stroke: var(--color-closed);
}

.aurretikStatusIcon--red #Oval-Copy-38 {
  fill: var(--color-closed);
}