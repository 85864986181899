.incidencia-card,
.incidencia-card-2 {
  display: flex;
  flex-direction: column;
  height: 441px;
  justify-content: center;
  margin-bottom: 30px;
  width: 32%;
}

.incidencia-card-2 {
  margin-right: 2%;
}

.incidencia-card_photo-container {
  background-color: black;
  height: 211px;
  opacity: .4;
  transition: opacity .5s ease-in-out;
}

.incidencia-card_photo-container:hover {
  opacity: 1;
}

.incidencia-card_photo a {
  display: contents;
}

.incidencia-card_photo-title {
  color: var(--color-white);
  display: flex;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-semi-bold);
  justify-content: center;
  margin-top: 105px;
  position: relative;
  text-align: center;
  z-index: var(--z-index-2);
}

.incidencia-card_photo {
  display: flex;
  justify-content: center;
  position: relative;
}

.incidencia-card_content {
  background-color: var(--bg-color-white);
  display: flex;
  flex-direction: column;
  height: 229px;
  justify-content: center;
  padding: 14px 24px 14px 24px;
  text-align: center;
}


.incidencia-card_location {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
}

.incidencia-card_location a {
  align-items: center;
  background-color: var(--bg-color-grey);
  color: var(--color-mine-shaft);
  display: flex;
   font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  height: 34px;
  justify-content: center;
  width: 60%;
}

.incidencia-card_location__icon{
  border: 0.7px solid var(--color-iron);
  height: 34px;
  width: 20%;
}

.incidencia-card_location__icon svg {
  height: 22px;
  margin-top: 6px;
}

.incidencia-text-overflow {
  align-self: center;
  display: flex;
  height: 26%;
  max-width: 300px;
  overflow: hidden;
  position: relative;
}

.incidenceCardImg {
  height: 211px;
  opacity: 0.8;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.incidenceCardImg.placeholder {
  opacity: 0.5;
}

@media screen and (max-width: 1000px) {
  .incidencia-card {
      width: 48%;
  }
  .incidencia-card-2 {
      margin-right: 0;
      width: 48%;
  }
  .incidencia-card_location a {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) {
  .incidencia-card_location a {
    font-size: 12px;
  }
}

@media screen and (max-width: 576px) {
  .incidencia-card {
    width: 100%;
  }
  .incidencia-card-2 {
    width: 100%;
  }
}