.news-attachments-container {
    background-color: var(--bg-color-white);
    height: 100%;
    margin-top: 1px;
    width: 75%;
}

.news-attachments-content {
    padding: 20px 0 20px 20px;
}
