.hartuzSearchInput>.pageRow {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.hartuzSearchInput__button {
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-main);
  border-radius: 5px;
  color: var(--color-main);
  display: flex;
  font-weight: var(--font-weight-semi-bold);
  gap: 16px;
  justify-content: center;
  padding: 12px 61px 12px 55px;
  width: 100%;
}

.hartuzSearchInput__button.active,
.hartuzSearchInput__button:hover {
  background-color: var(--color-main);
  border: 1px solid var(--color-white);
  color: var(--color-white);
}


.hartuzSearchInput__button svg path,
.hartuzSearchInput__button svg rect {
  stroke: var(--color-main);
}

.hartuzSearchInput__button.active svg path,
.hartuzSearchInput__button.active svg rect,
.hartuzSearchInput__button:hover svg path,
.hartuzSearchInput__button:hover svg rect {
  stroke: var(--color-white);
}

@media (min-width: 1280px) {
  .hartuzSearchInput__button {
    padding: 12px 39px;
    width: 250px;
    height: 40px;
  }

  .hartuzSearchInput__button svg {
    height: 22px;
  }
}