.news-date-container {
    width: 110px;
    height: 164px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: var(--color-news-date-box);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-accesibility-gray);
    padding-left: 20px;
}

.news-date_year {
    font-weight: var(--font-weight-medium);
}

@media screen and (max-width: 568px) {
    .news-date-container {
        width: 100%;
    }
}
