.last-news__card {
    background-color: var(--bg-color-white);
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    color: var(--color-main);
    font-size: var(--font-size-14);
    font-weight: var(var(--font-weight-regular));
    height: 100%;
    min-height: 300px;
    padding: 25px;
}

.last-news__card_footer {
    background-color: var(--color-main);
    border: none;
    box-shadow: none;
    font-size: var(--font-size-12);
    font-weight: var(var(--font-weight-regular));
    padding: 25px;
    width: 200px;
}

.last-news__title {
    font-family: var(--font-main);
    font-size: var(--font-size-15);
    font-weight: var(--font-weight-semi-bold);
}

.last-news__card > .card-text > a {
    color: var(--color-main);
}

.last-news__card > .card-text > a:hover {
    color: var(--color-accent-600);
}
