.actionMap__category-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: -20px;
}

.actionMap_map_container {
    height: 499px;
    margin-left: 0px;
    position: absolute;
    width: 100%;
}

.container {
    height: 750px;
    position: 'relative';
    width: 1440px;
}

.combined-shape-container {
    background-color: var(--bg-color-white);
    border-radius: 10.2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 320px;

}

.container-popUp {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
}

.opciones {
    align-items: center;
    background-color: var(--bg-color-grey);
    border-radius: 0 0 7.5px 6.8px;
    display: flex;
    flex-direction: row;
    height: 45px;
    justify-content: center;
}

.gm-style-iw-d {
    overflow: auto;
}

.plan {
    color: var(--color-main);
    font-family: var(--font-main);
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-medium);
    letter-spacing: 0;
    line-height: 20px;
    width: auto;
}

.accion {
    color: var(--color-text-news);
    font-family: var(--font-main);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-light);
    height: auto;
    letter-spacing: 0;
    line-height: 16px;
    width: auto;
}

.ver-accion,
.ver-plan {
    cursor: pointer;
    font-family: var(--typography-content);
     font-size: var(--font-size-14);
    height: 20px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    width: 124px;
}

.ver-plan a:hover {
    color: var(--color-main);
}

.ver-accion a,
.ver-plan a {
    color: var(--color-regent-gray);
    text-decoration: none;
}

@media screen and (max-width: 1200px) {
    .actionMap__category-box {
        margin-top: -60px;
    }
}

@media screen and (max-width: 960px) {
    /* tablet styles */

    .map_container {
        height: 747px;
        width: 939px;
    }

    .combined-shape {
        height: auto;
        width: 320px;
    }

    .plan,
    .accion {
        height: auto;
        width: auto;
    }

    .opciones {
        height: 52px;
        width: 313px;
    }
    .ver-plan {
        height: 20px;
        width: 153px;
    }

    .ver-accion {
        height: 20px;
        width: 151px;
    }
}

@media screen and (max-width: 568px) {
    /* phone styles */

    .map_container {
        height: 289px;
        width: 500px;
    }

    .combined-shape-container {
        width: 248px;
    }

    .combined-shape {
        height: auto;
        width: 254px;
    }

    .plan {
        font-size: var(--font-size-14);
        height: auto;
        width: auto;
    }

    .accion {
        font-size: var(--font-size-12);
        height: auto;
        width: auto;
    }

    .opciones {
        background-color: var(--bg-color-grey);
        border-radius: 0 0 7.5px 6.8px;
        height: 45px;
        width: 248.43px;
    }

    .ver-plan {
        height: 20px;
        width: 123px;
    }

    .ver-accion {
        height: 20px;
        width: 124px;
    }

    .mapa-de-acciones-title{
        display: flex;
        justify-content: center;
    }

}

@media screen and (max-width: 404px) {
    .combined-shape-container,
    .opciones {
        width: auto;
    }
}