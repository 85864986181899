.first-column {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding-right: 0;
    z-index: var(--z-index-8);
}

.second-column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    z-index: var(--z-index-7);
}

.background-img-1 {
    height: 896px;
    margin-top: -60px;
    padding-right: 0;
    z-index: var(--z-index-5);
}

.background-img-2 {
    margin-top: -60px;
    padding-left: 0;
    z-index: var(--z-index-6);
}

.background-img-2 img {
    height: 545px;
    object-fit: cover;
    width: 100%;
    margin-left:0.1px;
}

.background-img-1 img {
    height: 896px;
    object-fit: cover;
    width: 100%;
}

.first-row {
    margin-top: -224px;
}

.layer-news {
    background-color: var(--color-main);
    height: 896px;
    margin-left: 15px;
    margin-top: -60px;
    opacity: 0.9;
    position: absolute;
    width: calc(25% - 7px);
    z-index: var(--z-index-7);
}

.triangle-news {
    border-bottom: 15px solid transparent;
    border-left: 10px solid var(--color-news-date-box);
    border-top: 15px solid transparent;
    height: 0;
    position: absolute;
    right: -10px;
    top: calc(50% - 15px);
    width: 0;
    z-index: var(--z-index-9);
}

.single-news {
    position: relative;
}

.background-container {
    height: 545px;

}

.single-news__social-responsive {
    background-color: var(--bg-color-white);
}

@media screen and (max-width: 1200px) {
    .layer-news {
        display: none;
    }

    .background-img-2 {
        margin-left: 0;
        width: 768px;
    }

    .background-img-2 img {
        height: 445px;
        object-fit: cover;
        width: 768px;
    }
    .single-news__content {
        margin-top: 30px;
    }

    .second-column {
        padding-right: 0;
    }
    .container {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .news-date-container {
        width: 100%;
    }

    .first-row {
        margin: -400px auto 0 auto;
        max-width: 720px;
    }

    .second-row {
        margin: 0 auto;
        max-width: 720px;
    }

    .first-column {
        padding-left: 0;
    }
}

@media screen and (max-width: 960px) {
    .background-img-2 img {
        margin-top: 0;
    }

    .background-img-1 {
        display: none;
    }
}

@media screen and (max-width: 512px) {
    .background-img-2 {
        margin-left: 0;
        max-width: 568px;
        min-height: 300px;
        padding-right: 0;
        width: 100%;
    }

    .background-img-2 img {
        height: 445px;
        max-width: 568px;
        min-width: 300px;
        object-fit: cover;
        width: 100%;
    }

    .first-column {
        padding-left: 0;
    }

    .container {
        margin-left: 0;
        margin-right: 0;
    }
}
