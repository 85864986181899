.hartuzFacilityCard {
  background-color: var(--bg-color-white);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.hartuzFacilityCard__logo {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 10px;
  position: relative;
  width: 100%;
}

.hartuzFacilityCard__logo i {
  padding: 5px 7px 0;
}

.lock-icon {
  font-size: 25px;
}

.wheelchair-icon {
  font-size: 22px;
  font-weight: bold;
}

.hartuzFacilityCard a {
  align-self: center;
  background-color: var(--bg-color-grey);
  border-radius: 4px;
  color: var(--color-mine-shaft);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  line-height: 17px;
  padding: 10px 16px 10px 17px;
  text-align: center;
  width: 60%;
}

.hartuzFacilityCard__top,
.hartuzFacilityCard__middle,
.hartuzFacilityCard__bottom,
.hartuzFacilityCard__button {
  display: flex;
  justify-content: center;
}

.hartuzFacilityCard__middle {
  align-content: space-between;
  flex-wrap: wrap;
  height: 250px;
}

.hartuzFacilityCard__button {
  align-self: flex-end;
  padding: 20px;
  width: 100%;
}

.hartuzFacilityCard__top {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px 47px;
}

.hartuzFacilityCard__top h3 {
  color: var(--color-main);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  line-height: 17px;
  margin: 0;
  text-transform: capitalize;
}

.hartuzFacilityCard__bottom {
  font-size: 13px;
  line-height: 2em;
  text-align: center;
}

.hartuzFacilityCard__logo-div {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  margin-left: 10px;
  width: 35px;
  height: 35px;
  text-align: center;
}

@media (min-width: 768px) {
  .hartuzFacilityCard__bottom {
    padding: 30px 28px;
  }

  .hartuzFacilityCard__top {
    padding: 20px 28px;
  }
}

@media (min-width: 1024px) {
  .hartuzFacilityCard {
    background-color: var(--bg-color-white);
    border-radius: 5px;
  }
}