.social-bar__container {
    font-family: var(--font-main);
    max-width: 110px;
    min-height: 350px;
    z-index: var(--z-index-13);
}

.social-bar__content {
    width: 110px;
    height: 350px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
}

.social-icon {
    width: 29px;
    height: 29px;
    fill: var(--color-accent-400);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.social-icon:hover {
    width: 31px;
    height: 31px;
    fill: var(--color-white);
}

@media screen and (max-width: 1200px) {
    .social-box {
        flex-direction: row;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .social-bar__container {
        font-family: var(--font-main);
        max-width: 100%;
        width: 100%;
        height: 100%;
        z-index: var(--z-index-13);
        margin-bottom: 50px;
    }

    .social-bar__content {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
        height: 100%;
    }

    .social-icon {
        width: 29px;
        fill: var(--color-accent-400);
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        margin: 0 10px;
    }

    .social-icon:hover {
        width: 29px;
        fill: var(--color-main);
    }
}
