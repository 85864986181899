.central-content {
    min-height: 40vh;
    width: 100%;
}

.central-content *:focus-visible {
    outline: 2px solid black !important;
}

a {
    color: var(--color-font-secondary);
    text-decoration: none;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.fix-footer {
    margin-top: 20px;
    min-height: calc(100% - (267px + 369px + 1.5rem));
}

.macromenu--opened {
    overflow: hidden;
}

@media (max-width: 1200px) {
    .row {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }
}