.modal {
  align-items: center;
  backdrop-filter: brightness(0.4);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.modal__content_wrapper {
  background-color: var(--color-white);
  border-radius: 16px;
  max-width: 95vw;
  position: relative;
}

.modal__content {
  padding: 15px 35px 23px;
  width: 100%;
}

.modal__content_top {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 40px;
  padding: 20px 14px 0 23px;
  position: relative;
  width: 100%;
}

.modal__content_top h2 {
  font-family: var(--font-headers);
  font-size: 12px;
  font-weight: var(--font-weight-extra-bold);
  line-height: 16px;
  margin: 0;
  max-width: 80%;
  text-transform: uppercase;
}

.modal__content > div {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
}

.modal__content-close {
  background: none;
  border: none;
  cursor: pointer;
  justify-self: flex-end;
  position: absolute;
  right: 0;
}

.modal__content-close svg {
  fill: #383B41;
  height: 35px;
  width: 35px;
}

@media (min-width: 768px) {
  .modal__content_wrapper {
    min-width: 558px;
  }
}

@media (min-width: 1024px) {
  .modal__content_wrapper {
    min-width: 432px;
  }
}