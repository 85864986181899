.thankYouPage {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 40vh;
  width: 100%;
}

.thankYouPage a {
  color: var(--color-main);
  font-size: var(--font-size-12);
}

.thankYouPage__card {
  align-items: center;
  background-color: var(--color-white);
  box-shadow: 0px 0px 36px 0px #00000013;
  color: var(--color-main);
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-bold);
  gap: 16px;
  justify-content: center;
  min-height: 225px;
  padding: 20px;
}

.thankYouPage__card svg {
  height: 40px;
  width: 40px;
}

.thankYouPage__card h1 {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: 0.45px;
  line-height: 17px;
}

.thankYouPage__container {
  width: 90%;
  padding: 10px;
}

.thankYouPage__container > ul {
  display: table-cell;
  vertical-align: middle;
}

@media (min-width: 768px) {

  .thankYouPage__card h1 {
    font-size: var(--font-size-18);
    line-height: 23px;
  }  
}

@media (min-width: 1024px) {
  .thankYouPage__card {
    min-height: 230px;
    min-width: 528px;
  }

  .thankYouPage__card svg {
    height: 48px;
    width: 48px;
  }
}