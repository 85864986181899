.aurretikContainer_row {
  padding: 20px 20px 23px 27px;
}

.aurretikContainer_description {
  padding: 0px 20px 0px 27px;
}

.aurretikContainer__title {
  align-items: center;
  background-color: var(--bg-color-white);
  color: var(--content-color-dark-blue);
  display: flex;
  font-weight: var(--font-weight-semi-bold);
  gap: 20px;
  min-height: 80px;
  width: 100%;
}

.aurretikContainer__title svg {
  height: auto;
  height: 36px;
  width: 24px;
}

.aurretikContainer__title svg g {
  fill: var(--content-color-dark-blue);
}

.aurretikContainer__subtitle {
  color: var(--content-color-dark-blue);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
  line-height: 22px;
}

@media (min-width: 768px) {
  .aurretikContainer_row  {
    padding: 20px 60px 20px 69px;
  }

  .aurretikContainer_description {
    padding: 0px 60px 0px 69px;
  }

  .aurretikContainer__title {
    font-size: var(--font-size-18);
    line-height: 22px;
  }

  .aurretikContainer__subtitle {
    font-size: var(--font-size-18);
    line-height: 24px;
  }
}

@media (min-width: 1200px) {
  .aurretikContainer__title {
    background-color: transparent;
    font-size: var(--font-size-22);
    line-height: 28px;
    min-height: 80px;
    width: 100%;
  }

  .aurretikContainer__subtitle {
    font-size: var(--font-size-15);
    line-height: 21px;
  }

  .aurretikContainer_description {
    padding: 0px;
  }
}