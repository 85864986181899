.over-text {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    height: 500px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

.news-slider-img {
    display: inline-block;
    height: 500px;
    margin: 0;
    overflow: hidden;
    padding-left: 0;
    position: relative;
}

.news-slider-img img {
    display: block;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.news-slider-img.corrected {
    background-color: var(--bg-color-white);
    display: inline-block;
    height: 500px;
    margin: 0;
    overflow: hidden;
    padding-left: 0;
    position: relative;
}

.news-slider-img.corrected img {
    display: block;
    left: 50%;
    min-height: 100%;
    min-width: auto;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.news-content {
    height: auto;
    margin-left: 0;
    margin-right: 0;
}

.news-list-title {
    align-self: center;
    color: var(--color-main);
    font-size: var(--font-size-28);
    font-weight: var(--font-weight-bold);
}

.news-list-title-container {
    margin-bottom: 40px;
    margin-top: 68px;
    text-align: center;
}

.news-inner {
    background: var(--bg-color-white);
    padding-bottom: 68px;
    width: 100%;
}

.date {
    align-items: flex-start;
    background-color: var(--color-accent-400);
    color: var(--color-accesibility-gray);
    display: flex;
    flex-direction: column;
    height: 125px;
    justify-content: center;
    padding-left: 25px;
    vertical-align: center;
}

.date-responsive {
    display: none;
}

.news-list-body-content {
    padding: 0 68px;
}

.news-list-title-container-responsive {
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.left-triangle-shape-list {
    border-bottom: 15px solid transparent;
    border-left: 10px solid var(--color-accent-400);
    border-top: 15px solid transparent;
    height: 0;
    position: absolute;
    right: -10px;
    top: calc(125px / 2 - 15px);
    width: 0;
    z-index: var(--z-index-9);
}

.date-text {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-bold);
}

.year-text {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-regular);
}

.title {
    align-items: center;
    background-color: var(--bg-color-white);
    color: var(--color-main);
    display: flex;
    flex-direction: row;
    font-family: var(--font-main);
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-regular);
    height: 100%;
    justify-content: flex-start;
    padding-left: 100px;
    vertical-align: center;
}

.arrows-container {
    align-items: flex-end;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

.arrow-left {
    background-color: var(--color-accent-600);
    border-left: 1px var(--color-white) solid;
    height: 50%;
    padding: 0;
}

.arrow-right {
    background-color: var(--color-accent-600);
    border-left: 1px var(--color-white) solid;
    height: 50%;
    padding: 0;
}

.news-slider-content .news-inner {
    box-sizing: border-box;
    left: 50%;
    padding: 0 70px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
}

.news-slider-content .news-inner button {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.news-slider-content .news-inner h1 {
    color: var(--color-white);
    font-size: var(--font-size-64);
    font-weight: var(--font-weight-black);
    line-height: 1;
    margin: 0 auto;
    max-width: 840px;
}

.news-slider-content .news-inner p {
    color: var(--color-white);
    font-size: var(--font-size-14);
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
}

.news-slider-content section {
    bottom: 20px;
    left: 20px;
    position: absolute;
}

.news-slider-content section span {
    color: var(--color-white);
}

.news-slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    display: inline-block;
    font-size: var(--font-size-12);
    line-height: 1.4;
    margin-left: 10px;
    text-align: left;
    vertical-align: middle;
}

.news-slider-content section img {
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    height: 40px;
    vertical-align: middle;
    width: 40px;
}

.news-slider-content section span strong {
    color: var(--color-white);
    display: block;
    font-size: var(--font-size-14);
}

a span {
    display: block !important;
}

.news-body_text {
    color: var(--color-main);
    display: block;
    font-size: var(--font-size-14);
    margin-bottom: 68px;
}

.list-news+.list-news {
    margin-top: 50px;
}

.list-news {
    margin: 100px 0;
}

.below-text {
    align-items: flex-start;
    display: none;
    flex-direction: row;
    height: 375px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

.news-list-title-container-responsive-small {
    display: none;
}

@media screen and (max-width: 1200px) {
    .news-content {
        margin: -146px auto 0;
        position: relative;
        width: 70%;
        z-index: var(--z-index-7);
    }

    .news-inner {
        margin: 0 auto;
        padding: 0;
        width: 70%;
    }

    .date {
        display: none;
    }

    .date-responsive {
        align-items: flex-start;
        background-color: var(--color-accent-600);
        color: var(--color-white);
        display: flex;
        flex-direction: column;
        height: 146px;
        justify-content: center;
        padding-left: 20px;
        vertical-align: center;
    }

    .two-columns-list {
        -webkit-column-count: 1;
        /* Chrome, Safari, Opera */
        -moz-column-count: 1;
        /* Firefox */
        column-count: 1;

        -webkit-column-gap: 98px;
        /* Chrome, Safari, Opera */
        -moz-column-gap: 98px;
        /* Firefox */
        column-gap: 98px;
    }

    .news-list-title-container {
        margin-top: 0;
    }

    .left-triangle-shape-list {
        right: -9px;
    }

    .news-body_text {
        padding-bottom: 40px;
        padding-left: calc(16.66% + 15px);
        padding-right: 40px;
    }

    .news-slider-img {
        padding: 0;
    }

    .news-list-body-content {
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .news-content {
        margin: -146px auto 0;
        position: relative;
        width: 100%;
        z-index: var(--z-index-7);
    }

    .news-body_text {
        padding-left: 40px;
        padding-top: 30px;
    }

    .below-text {
        display: none;
    }

    .news-slider-img-below {
        display: none;
    }

    .list-news+.list-news {
        margin-bottom: 50px;
        margin-top: 0;
    }

    .news-list-title-container-responsive+.news-list-title-container-responsive {
        padding-top: 0;
    }

    .list-news {
        margin: 50px 0;
    }

    .two-columns-list {
        -webkit-column-count: 1;
        /* Chrome, Safari, Opera */
        -moz-column-count: 1;
        /* Firefox */
        column-count: 1;

        -webkit-column-gap: 98px;
        /* Chrome, Safari, Opera */
        -moz-column-gap: 98px;
        /* Firefox */
        column-gap: 98px;
    }
}

@media screen and (max-width: 576px) {
    .below-text {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 375px;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    .over-text {
        display: none;
    }

    .news-content {
        display: none;
    }

    .news-slider-img-below {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }

    .news-slider-img-below img {
        height: 100%;
        max-width: 414px;
        min-height: 300px;
        object-fit: cover;
        width: 100%;
    }

    .news-list-title-container-responsive-small {
        background-color: var(--bg-color-white);
        display: block;
        height: 60px;
        padding-top: 20px;
        text-align: center;
    }

    .news-list-title-container-responsive {
        background-color: var(--bg-color-white);
        font-size: var(--font-size-20);
        height: 90px;
        padding-top: 10px;
        text-align: center;
    }

    .news-list-title {
        font-size: var(--font-size-20);
    }

    .news-list-title-container {
        margin-top: 0;
        padding-left: 15px;
    }
}