.aurretikFacilityOccupancy {
   background-color: var(--bg-color-white);
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 15px;
  z-index: var(--z-index-2);
}

.aurretikFacilityOccupancy_capacity {
  border-left: 0.5px solid var(--color-zumthor);
  margin-left: 10px;
  padding-left: 20px;
}

.aurretikFacilityOccupancy_occupancy,
.aurretikFacilityOccupancy_capacity {
  display: flex;
  gap: 10px;
}

.aurretikFacilityOccupancy_data-wrapper {
  color: var(--content-color-medium-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aurretikFacilityOccupancy_description {
  font-size: var(--font-size-11);
  line-height: 14px;
}

.aurretikFacilityOccupancy_capacity svg {
  height: auto;
  width: 13px;
}

.aurretikFacilityOccupancy_occupancy--data {
   font-size: var(--font-size-12);
  font-weight: var(--font-weight-bold);
  line-height: 15px;
  text-transform: uppercase;
}

.aurretikFacilityOccupancy_capacity--data {
   font-size: var(--font-size-18);
  font-weight: var(--font-weight-extra-light);
  line-height: 22px;
}

.aurretikFacilityOccupancy_capacity .aurretikFacilityOccupancy_capacity--data .aurretikFacilityOccupancy_capacity--data-num {
   font-size: var(--font-size-18);
  font-weight: var(--font-weight-medium);
  line-height: 22px;
}

