.kalezainduMapContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 1000px;
}

.map-container {
    display: flex;
    height: 700px;
    margin-top: -20px;
    overflow: hidden;
    position: absolute;
    width: 100%;
}

.indicencias-infobox {
    background-color: var(--bg-color-lighter-grey);
    height: 363px;
    margin-bottom: 70px;
    margin-left: 0%;
    position: relative;
    top: 550px;
    width: 90%;
}

.incidencias-infobox_header {
    background-color: var(--bg-color-white);
    display: flex;
    flex-direction: row;
    height: 130px;
    justify-content: space-between;
    padding: 40px;
    top: -130px;
}

.incidencias-filters {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: -3%;
    width: strech;
    width: 65%;
}

.incidencias-filters>div {
    margin-left: 5%;
}

.filter-select {
    align-items: center;
    background-color: var(--bg-color-lightest-grey);
    border-radius: 40px;
    border-width: 0;
    color: var(--color-biscay);
    cursor: pointer;
    display: flex;
    font-family: var(--typography-content);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
    height: 30px;
    justify-content: center;
    width: 150px;
}

.filter-noselect {
    align-items: center;
    background-color: var(--bg-color-lightest-grey);
    border-radius: 40px;
    border-width: 0;
    color: var(--color-pale-sky);
    cursor: pointer;
    display: flex;
    font-family: var(--typography-content);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
    height: 30px;
    justify-content: center;
    width: 150px;
}

.oval-select-1 {
    background-color: var(--bg-color-orange);
    border-radius: 40px;
    height: 14px;
    margin-right: 6px;
    width: 13.91px;
}

.oval-select-2 {
    background-color: var(--bg-color-bright-green);
    border-radius: 40px;
    height: 14px;
    margin-right: 6px;
    width: 13.91px;
}

.oval-select-3 {
    background-color: var(--bg-color-dark-blue);
    border-radius: 40px;
    height: 14px;
    margin-right: 6px;
    width: 13.91px;
}

.oval-noselect-1 {
    background-color: var(--bg-color-darker-grey);
    border-radius: 40px;
    height: 14px;
    margin-right: 6px;
    width: 13.91px;
}

.oval-noselect-2 {
    background-color: var(--bg-color-darker-grey);
    border-radius: 40px;
    height: 14px;
    margin-right: 6px;
    width: 13.91px;
}

.oval-noselect-3 {
    background-color: var(--bg-color-darker-grey);
    border-radius: 40px;
    height: 14px;
    margin-right: 6px;
    width: 13.91px;
}

.incidencias_reportBoton {
    align-items: center;
    background-color: var(--color-main);
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    height: 48px;
    justify-content: center;
    width: 315px;
}

.incidencias-reportBoton a:hover {
    color: var(--color-black);
}

/*INPUT*/

.incidencias-infobox_search {
    padding: 40px;
}

.kalezaindu_search_bar {
    border: 2px solid var(--color-white-smoke);
    border-radius: 6px;
    height: 60px;
    margin: 20px 0;
    margin-right: -22px;
    padding-left: 15px;
    width: 100%;
}

.incidencias-infobox_searchInput {
    display: flex;
    width: 100%;
}

.incidencias-infobox_searchInput svg {
    cursor: pointer;
    position: relative;
    right: 25px;
    width: 21px;
}

.incidencias-infobox_searchDates {
    display: flex;
    justify-content: space-between;
}

.incidencias-infobox_searchDates .datesInput {
    position: relative;
}

.incidencias-infobox_searchDates .datesInput svg {
    position: absolute;
    top: 10px;
    right: 15px;
    pointer-events: none;
}

.search_date,
.search_select {
    background-color: var(--bg-color-white);
    border: 2px solid var(--color-white-smoke);
    border-radius: 6px;
    color: var(--color-text-news);
    height: 48px;
    position: relative;
    width: 100%;
}

.search_date::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: relative;
    right: 10%;
    z-index: var(--z-index-2);
}

.dates {
    display: flex;
    flex-direction: column;
    width: 23%;
}

input[type="date"] {
    padding-left: 10px;
}

.incidencias-infobox_searchDates .dates label {
    display: flex;
    z-index: var(--z-index-1);
}

.titles {
    align-items: center;
    color: var(--color-text-news);
    display: flex;
    flex-direction: row;
}

.titles p {
    margin-bottom: 0;
}

.dates svg {
    width: 27px;
}


/*INFOWINDOW*/

.container-popUp {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
}

.ver-incidencia {
    color: var(--color-regent-gray);
    font-family: var(--font-main);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-medium);
}

.description {
    color: var(--color-text-news);
    font-family: var(--font-main);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-light);
    line-height: 16px;
    text-align: center;
}

.details-container {
    color: var(--color-text-news);
    display: flex;
    flex-direction: row;
    font-family: var(--font-main);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-light);
    justify-content: space-between;
    margin-bottom: 17px;
    padding-left: 5px;
    padding-right: 5px;
}

.details-container_detail {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.details-container svg {
    margin-right: 6.2px;
    width: 21px;
}

.ver-incidencia-container {
    align-items: center;
    background-color: var(--bg-color-grey);
    border-radius: 0 0 7.5px 6.8px;
    display: flex;
    flex-direction: row;
    height: 45px;
    justify-content: center;
}


/*GALLERY CARDS*/

.incidencias-galleryContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 1041px;
    justify-content: space-between;
    left: 5%;
    margin-bottom: -100px;
    margin-left: 0%;
    overflow: scroll;
    padding: 0;
    position: relative;
    top: -25px;
    /* width: 1150px; */
    width: 90%;
}

.incidencias-gallery-container-2 {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-start;
    left: 5%;
    margin-left: 0%;
    overflow: scroll;
    position: relative;
    top: -25px;
    width: 90%;
}

.incidencias-galleryContainer-3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 5%;
    margin-left: 0%;
    overflow: scroll;
    position: relative;
    top: -25px;
    width: 90%;
}

.incidencias-galleryContainer::-webkit-scrollbar {
    display: none;
}

.incidencias-gallery-container-2::-webkit-scrollbar {
    display: none;
}

.incidencias-galleryContainer-3::-webkit-scrollbar {
    display: none;
}

.fake-card {
    display: flex;
    flex-direction: column;
    height: 441px;
    justify-content: center;
    margin-bottom: 30px;
    width: 32%;
}

#Rendicion_accion_desktop2 {
    fill: var(--color-iron);
    stroke: var(--color-iron);
}

#Oval-Copy-29 {
    fill: var(--color-white);
}

.container-no-result {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    right: 0%;
    top: -25px;
}

@media screen and (max-width: 1200px) {
    .kalezainduMapContainer {
        margin-top: -40px;
    }

    .filter-select {
        margin-right: 0;
        max-width: 90%;
    }

    .filter-noselect {
        margin-right: 0;
        max-width: 90%;
    }

    .incidencias-infobox_header {
        align-items: center;
        flex-direction: column;
        padding-bottom: 130px;
    }

    .map-container {
        height: 700px;
        left: 0%;
        position: absolute;
        width: 100%
    }

    .incidencias-infobox_searchDates {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: space-evenly;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .indicencias-infobox {
        background-color: var(--bg-color-lighter-grey);
        height: 490px;
        position: relative;
        top: 570px;
    }

    .incidencias-galleryContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 53px;
        position: relative;
        top: 125px;
        width: 90%;

    }

    .incidencias-gallery-container-2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 100px;
        position: relative;
        top: 125px;
        width: 90%;
    }

    .incidencias-galleryContainer-3 {
        margin-bottom: 100px;
        margin-left: 0%;
        top: 125px;
        width: 90%;

    }

    .incidencias-infobox_search {
        padding: 20px;
    }

    .fake-card {
        display: none;
    }

    .incidencias-filters {
        align-items: center;
        display: flex;
        justify-content: space-around;
        margin-bottom: 25px;
        margin-left: 0%;
        width: stretch;
    }

    .dates {
        display: flex;
        flex-direction: column;
        margin-bottom: 3%;
        width: 48%;
    }

    .dates svg {
        position: relative;
        width: 27px;
    }

    .container-no-result {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        height: 150px;
        justify-content: center;
        position: relative;
        right: 0%;
        top: 125px;
    }
}


@media screen and (max-width: 576px) {
    .details-container {
        align-items: center;
        color: var(--color-text-news);
        display: flex;
        flex-direction: column;
        font-family: var(--font-main);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-light);
        margin-bottom: 0;
        padding-left: 5px;
        padding-right: 5px;
    }

    .details-container_detail {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
    }

    .filter-noselect {
        align-items: center;
        background-color: var(--bg-color-lightest-grey);
        border-radius: 40px;
        border-width: 0;
        color: var(--color-pale-sky);
        cursor: pointer;
        display: flex;
        font-family: var(--typography-content);
        font-size: var(--font-size-0);
        font-weight: var(--font-weight-medium);
        height: 30px;
        justify-content: center;
        margin-right: 35px;
        width: 32px;
    }

    .oval-noselect-1 {
        background-color: var(--bg-color-orange);
        border-radius: 40px;
        height: 14px;
        margin-right: 0;
        width: 13.91px;
    }

    .oval-noselect-2 {
        background-color: var(--bg-color-bright-green);
        border-radius: 40px;
        height: 14px;
        margin-right: 0;
        width: 13.91px;
    }

    .oval-noselect-3 {
        background-color: var(--bg-color-dark-blue);
        border-radius: 40px;
        height: 14px;
        margin-right: 0;
        width: 13.91px;
    }

    .oval-select-1 {
        background-color: var(--bg-color-orange);
        border-radius: 40px;
        height: 14px;
        margin-right: 6px;
        width: 13.91px;
    }

    .oval-select-2 {
        background-color: var(--bg-color-bright-green);
        border-radius: 40px;
        height: 14px;
        margin-right: 6px;
        width: 13.91px;
    }

    .oval-select-3 {
        background-color: var(--bg-color-dark-blue);
        border-radius: 40px;
        height: 14px;
        margin-right: 6px;
        width: 13.91px;
    }

    .incidencias-filters {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 26px;
        width: 100%;
    }

    .filter-select {
        max-width: 100%;
    }

    .filter-noselect {
        margin-right: 0;
    }

    .ocultar {
        display: none;
        font-size: var(--font-size-0);
        max-height: 0;
    }

    .incidencias-infobox_header {
        align-items: center;
        flex-direction: column;
        padding: 20px 20px 132px 20px;
    }

    .incidencias-infobox_header a {
        width: 100%;
    }

    .incidencias_reportBoton {
        font-size: 100%;
        width: 100%;
    }

    .incidencias-filters>div {
        margin-left: 0;
    }

    .dates {
        display: flex;
        flex-direction: column;
        margin-bottom: -1px;
        margin-top: 20px;
        width: 100%;
    }

    .incidencias-infobox_search {
        padding: 10px;
    }

    .indicencias-infobox {
        height: auto;
        padding-bottom: 20px;
    }

    .incidencias-galleryContainer {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        left: 5%;
        margin-bottom: 77px;
        position: relative;
        width: 90%;
    }

    .incidencias-gallery-container-2 {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        left: 5%;
        position: relative;
        width: 90%;
    }

    .incidencias-infobox_searchDates {
        align-items: center;
        flex-direction: column;
        padding: 0 10px;
    }

    .incidencias-galleryContainer .container-no-result {
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        height: 300px;
        justify-content: center;
        overflow: scroll;
        position: relative;
        top: -158px;
    }

    .no-result p {
        margin-left: -5%;
    }

    .mobile_dates {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .dates_calendar {
        display: flex;
        flex-direction: column;
        width: 48%;
    }

    .incidencias-infobox_searchInput {
        margin-left: 10px;
    }

    .kalezaindu_search_bar {
        margin-right: -2px;
    }

    .incidencias-infobox_searchInput svg {
        right: 35px;
    }

    .dates_calendar svg {
        left: 5px;
        position: relative;
        width: 27px;
    }
}