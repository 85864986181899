.ag-root-wrapper {
  font-family: var(--font-main);
}

.paginatedTable__deleteContainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.paginatedTable__deleteContainer button {
  height: 60%;
    display: flex;
    align-items: center;
    border: none;
    background-color: unset;
    width: auto;
    padding: 0;
}

.paginatedTable__deleteContainer svg {
  height: 20px;
  width: 20px;
}