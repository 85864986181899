.mapsSelectors {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0;
}

.mapsSelectors__select {
  background-color: var(--bg-color-white);
  border: 2px solid var(--color-white-smoke);
  border-radius: 6px;
  color: var(--color-text-news);
  height: 48px;
  padding: 5px 5px;
  position: relative;
  width: 100%;
}

.mapsSelectors__select-container label {
  color: var(--color-text-news);
  display: block;
  margin-right: 5px;
  margin-top: 0;
}