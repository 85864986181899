.actionDetail_action-descriptionContainer-objetivos-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  padding-left: 90px;
  padding-top: 60px;
}

@media screen and (max-width: 568px) {
  .actionDetail_action-descriptionContainer-objetivos-content {
      padding-bottom: 30px;
      padding-left: 40px;
      padding-top: 30px;
  }
}