.categoryButton {
  align-items: center;
  background: var(--color-white);
  border: 1px solid var(--border-white);
  border-radius: 4px;
  color: var(--color-main);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  justify-content: center;
  text-transform: capitalize;
  padding: 10px 0;
  width: 100%;
}

.categoryButton.active,
.categoryButton:hover {
  background-color: var(--color-main);
  color: var(--color-white);
}