.userBookingsContainer__top {
  background-color: var(--bg-color-white);
  padding-bottom: 20px;
}

.userBookingsContainer__bottom {
  padding-top: 40px;
}

.userBookingsContainer__top h1 {
  padding: 21px 0 20px;
}

.userBookingsContainer__bookingSearch,
.userBookingsContainer__bookingList {
  box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
}

.userBookingsContainer__bookingSearchTitle {
  background-color: var(--bg-color-light-grey);
  padding: 15px 50px;
}

.userBookingsContainer__bookingSearchTitle h2 {
  color: var(--color-mine-shaft);
  font-size: 18px;
  font-weight: var(--font-weight-regular);
  margin: 0;
}

.userBookingsContainer__bookingSearchForm {
  background-color: var(--bg-color-white);
  padding: 30px 50px 50px;
}

.userBookingsContainer__bookingSearchForm input,
.userBookingsContainer__bookingSearchForm select {
  background-color: var(--bg-color-white);
  border: 2px solid var(--color-white-smoke);
  border-radius: 6px;
  height: 48px;
  padding: 7px 5px 7px 15px;
}

.userBookingsContainer__firstRow,
.userBookingsContainer__secondRow {
  padding-bottom: 30px;
}

.userBookingsContainer__firstRow input {
  width: 100%;
}

.userBookingsContainer__secondRow {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.userBookingsContainer__firstRow label,
.userBookingsContainer__secondRow label,
.userBookingsContainer__secondRow select,
.userBookingsContainer__dateFilter {
  color: var(--color-text-news);
}

.userBookingsContainer__secondRow div,
.userBookingsContainer__secondRow select,
.userBookingsContainer__dateFilter {
  width: 100%;
}

.userBookingsContainer__buttonPanel {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  padding-right: 50px;
  padding-top: 15px;
}

.userBookingsContainer__buttonPanel button {
  background-color: var(--bg-color-white);
  border-radius: 25px;
  font-weight: var(--font-weight-medium);
  padding: 10px 30px;
}

.userBookingsContainer__filtersButton {
  border: 1px solid var(--color-dove-gray);
  color: var(--color-dove-gray);
}

.userBookingsContainer__removeFiltersButton {
  border: 1px solid var(--color-monza);
  color: var(--color-monza);
}

.userBookingsContainer__buttonPanel button:hover {
  color: var(--bg-color-white);
}

.userBookingsContainer__removeFiltersButton:hover {
  background-color: var(--color-main);
}

.userBookingsContainer__filtersButton:hover {
  background-color: var(--color-dove-gray);
}

.colorButton {
  color: #000 !important;
}

#dialog-content .MuiPaper-root{
  max-width: calc(100% - 80px);
}

#alert-dialog-description {
  margin-bottom: 16px;
}

#form-content .hartuzCancellationform__submit {
  margin-top: 12px;
}

@media (min-width: 1280px) {
  .userBookingsContainer__top {
    padding: 75px 0 62px;
  }

  .userBookingsContainer__bottom {
    padding-top: 80px;
  }
}