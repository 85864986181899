.wysiwyg-functionality__title {
  color: var(--color-main);
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: 1rem;
}

.wysiwyg-functionality__subtitle h2 {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-semi-bold);
}

.wysiwyg-functionality__subtitle h3 {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-semi-bold);
}

.wysiwyg-functionality__subtitle h4 {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-semi-bold);
}

.wysiwyg-functionality__subtitle {
  color: var(--color-text-news);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
  line-height: 18px;
}

