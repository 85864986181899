.form-checkbox {
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 16px;
  position: relative;
}

.form-checkbox:focus,
.form-checkbox:active,
.form-checkbox__input:focus-visible {
  outline-color: var(--color-main);
}

.form-checkbox:focus .form-checkbox__content::before {
  border-color: var(--color-dim-gray);
}

.form-checkbox__input {
  left: -9999px;
  position: absolute;
}

.form-checkbox__content {
  display: block;
  padding-left: 10px;
  position: relative;
}

.form-checkbox__content::before,
.form-checkbox__content::after {
  box-sizing: border-box;
  position: absolute;
  transition: 0.3s;
}

.form-checkbox__box {
  background-color: var(--color-white);
  border: 1px solid var(--color-dim-gray);
  border-radius: 3px;
  height: 14px;
  padding: 8px;
  position: relative;
  width: 14px;
}

.form-checkbox__check {
  fill: var(--color-main);
  height: 20px;
  left: -2px;
  opacity: 0;
  position: absolute;
  top: -2px;
  width: 20px;
}

.form-checkbox__input:checked + .form-checkbox__box > .form-checkbox__check {
  opacity: 1;
}

.form-checkbox__input:focus + .form-checkbox__content::before {
  border-color: var(--color-dim-gray);
}
