.hartuzBookingform {
  width: 100%;
}

.hartuzBookingform__form {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 28px;
  width: 100%;
}

.hartuzBookingform__inputs--wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hartuzBookingform .modal__content_wrapper {
  min-width: 350px;
}

.hartuzBookingform .modal__content-close {
  margin-right: 15px;
}

.hartuzBookingform .modal__content-close svg {
  height: 20px;
  width: 20px;
}

.hartuzBookingform .modal__content_top h2 {
  font-size: 16px;
  margin-top: 8px;
}

.hartuzBookingform .modal__content {
  padding-top: 6px;
}

.hartuzBookingform__confirmation h3 {
  font-size: 14px;
}

.hartuzBookingform__form input {
  color: var(--color-text-news);
}

.hartuzBookingForm__day-time-card {
  background-color: var(--color-hawkes-blue);
  color: var(--color-main);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  line-height: 17px;
  padding: 36px 28px 36px 28px;
}


.hartuzBookingform .form-checkbox__content a {
  color: var(--color-main);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
}

.hartuzBookingform .hartuzBookingform__checkbox {
  grid-column: 1/3;
}

.hartuzBookingForm__day-time-card p {
  margin: 0px;
}

.hartuzBookingForm__day-time-card--content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px
}

.hartuzBookingForm__day-time-card--content div {
  align-items: center;
  display: flex;
  gap: 16px;
}

.hartuzBookingform__submit {
  display: flex;
  justify-content: flex-start;
}

.hartuzBookingform__confirmation_content {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
}

.hartuzBookingform__confirmation_content div {
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%
}

.hartuzBookingform__confirmation_content p {
  font-size: 14px;
  margin: 0;
}

.hartuzBookingform__confirmation_content i {
  align-self: center;
  display: flex;
  justify-content: center;
  width: 20px;
}

.hartuzBookingform__confirmationButton {
  max-width: 80%;
  margin-bottom: 25px;
}

.bookingDataContainer input {
  color: #898989;
  background-color: #e8e8e8;
}

@media (min-width: 768px) {
  .hartuzBookingform .modal__content_wrapper {
    min-width: 450px;
  }

  .hartuzBookingform .modal__content_top h2 {
    font-size: 20px;
    margin-top: 15px;
  }

  .hartuzBookingform__confirmation h3 {
    font-size: 18px;
  }

  .hartuzBookingForm__day-time-card--content {
    flex-direction: row;
    gap: 30px;
  }

  .hartuzBookingform__inputs--wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .form-group__errors {
    grid-column: 1/3;
  }

  .hartuzBookingform__submit button {
    max-width: 335px;
  }
}

@media (min-width: 1024px) {
  .hartuzBookingform__form {
    gap: 20px;
  }

  .hartuzBookingform__inputs--wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .form-group__errors {
    grid-column: 1/4;
    order: 1;
  }

  .hartuzBookingform__submit button {
    max-width: 230px;
  }

  .hartuzBookingForm__day-time-card {
    padding: 15px 28px 15px 28px;
  }
}