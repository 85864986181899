.category-card {
    background-color: var(--bg-color-white);
    color: var(--color-main);
    display: flex;
    flex-direction: row;
    font-size: var(--font-size-14);
    max-width: 543px;
    width: 100%;
}

.category-card__subtitle {
    display: block;
}

.category-card__icon-container {
    width: 38px;
}

.category-card__text {
    margin-left: 47px;
}

.category-card__title {
    font-weight: var(--font-weight-semi-bold);
}

.category-card__subtitle {
    font-weight: var(--font-weight-regular);
}

.category-card__link {
    display: block;
}

.category-card__link + .category-card__link {
    padding-top: 15px;
}
