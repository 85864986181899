.related-card {
    background-color: var(--bg-color-white);
    -webkit-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.75);
    color: var(--color-main);
    display: block !important;
    font-size: var(--font-size-14);
    font-weight: var(var(--font-weight-regular));
    height: 100%;
    min-height: 300px;
    width: 302px;
}

.related-card__date {
    font-size: var(--font-size-12);
}

.related-card__title {
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    max-height: 40px;
}

.related-card__text {
    font-size: var(--font-size-14);
    margin-top: 35px;
}

.related-news__container_horizontal
    > .related-card__container
    + .related-card__container {
    padding-left: 10px;
}

.related-news__container_vertical
    > .related-card__container
    + .related-card__container {
    padding-top: 10px;
}

.related-news__container_horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.related-news__container_vertical {
    align-content: flex-start;
    display: flex;
    flex-direction: column;
}

.clamp-lines__button {
    visibility: hidden;
}

small {
    color: var(--color-main);
}

.related-card__container {
    height: 100%;
}

@media screen and (max-width: 1200px) {
    .related-card {
        width: 100%;
    }
}
