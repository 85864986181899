.pageTitle {
  color: var(--color-main);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: 0.35px;
  line-height: 22px;
}

@media (min-width: 1024px) {
  .pageTitle {
    font-size: var(--font-size-18);
    letter-spacing: 0.45px;
  }
}

@media (min-width: 1280px) {
  .pageTitle {
    font-size: var(--font-size-22);
    letter-spacing: 0.55px;
    line-height: 28px;
  }
}