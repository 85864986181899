.breadcrumb-container {
    background: var(--color-main);
    display: flex;
    height: 40px;
    position: relative;
    width: 960px;
    z-index: var(--z-index-10);
    color: white;

}

.breadcrumb-content {
    display: flex;
    width: 100%;
}

.breadcrumb-bluemargin {
    background-color: var(--color-main);
    height: 40px;
    margin-left: -41%;
    width: 41%;
}

.breadcrumb {
    align-items: center;
    background: none;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: flex-start;
    margin-bottom: 0;
    margin-top: 0;
}

ol {
    padding: 0;
}

.breadcrumb a {
    color: var(--color-white);
    text-decoration: none;
}

.breadcrumb-item+.breadcrumb-item::before {
    background-image: url('../../assets/svg/arrow.svg');
    background-size: 8px 8px;
    content: ' ';
    height: 8px;
    margin-right: 10px;
    width: 8px;
}

.breadcrumb-item {
    align-items: center;
    display: flex;
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-semi-bold);
}

.breadcrumb-item.active {
    color: var(--color-white);
}


@media screen and (max-width: 1200px) {
    .breadcrumb-content {
        display: none;
    }
}