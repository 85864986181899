.macromenu-container.macromenu--opened {
    opacity: 1;
    transition: all 0.5s ease;
    visibility: visible;
}

.macromenu-container {
    background-color: var(--bg-color-white);
    color: var(--color-main);
    height: 120vh;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.5s ease;
    visibility: hidden;
    width: 100%;
    z-index: var(--z-index-11);
}

.macromenu-content {
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(var(--header-desktop-height) + 50px);
    /* width: 80%; */
}

.macromenu-item__parent {
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-semi-bold);
}

.macromenu-item__child {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-medium);
}
.macromenu-column {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.macromenu-link {
    text-align: center;
    width: 100%;
}

.macromenu-content a {
    color: var(--color-main);
    display: block;
    text-decoration: none;
    transition: opacity 500ms;
    width: 100%;
}

.macromenu-content {
    transition: opacity 500ms;
}

.macromenu-content:hover a {
    opacity: 0.3;
    transition: opacity 500ms;
}
.macromenu-content:hover a:hover {
    opacity: 1;
    transition: opacity 500ms;
}
