.cont {
    margin: 70px auto 50px auto;
    width: 100%;
}

.error_container {
}

.error_overlay {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 550px;
    justify-content: space-between;
    margin: 0 auto;
    width: 60%;
}

.error_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.error_text {
    color: var(--color-main);
    display: flex;
    flex-direction: row;
    width: 35%;
}

.error_list {
    color: var(--color-main);
    font-size: var(--font-size-16);
    width: 35%;
}
.error_list ul {
    list-style-image: url('../../assets/svg/link_icon.svg');
}

.error_text > div + div {
    margin-left: 20px;
}

.error_title {
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-semi-bold);
}

.error_subtitle {
    font-size: var(--font-size-16);
}

.face_icon {
    width: 53px;
}

.error_links {
    color: var(--color-main);
}

.error_links:hover {
    font-weight: var(--font-weight-medium);
    text-decoration: underline;
}
