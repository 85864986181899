.form-group {
  margin: 0;
}

.form-group__label {
  color: var(--color-main);
  display: block;
  font-family: var(--typography-content);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-medium);
  line-height: 17px;
  margin: 0;
}

.form-group__errors {
  color: var(--color-cinnabar);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
}

.server-group__errors {
  background-color: var(--color-pale-pink);
  border: 1px solid transparent;
  border-color: var(--color-azalea);
  border-radius: 0.25rem;
  color: var(--color-red-berry);
  font-weight: var(--font-weight-regular);
  margin: 0;
  padding: 0.75rem 1.25rem;
}

.server-group__info {
  align-items: center;
  background-color: var(--color-pale-blue);
  border: 1px solid var(--color-pale-cornflower-blue);
  border-radius: 0.25rem;
  color: var(--color-dark-cerulean);
  display: flex;
  font-weight: var(--font-weight-regular);
  justify-content: center;
  height: 46px;
  text-align: center;
  width: 100%;
}

.server-group__info p {
  margin: 0
}

.form-group__label-red {
  color: var(--color-cinnabar);
}