.box-estado {
  align-items: center;
  border: 1px solid var(--color-main);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 3px;
  width: 137px;
}

.box-estado p {
  color: var(--color-main);
  font-weight: var(--font-weight-medium);
  margin-bottom: 0;
  margin-right: 10px;
}