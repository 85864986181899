.hartuzBookingGrid-title {
  background-color: var(--color-main);
  color: var(--color-white);
  display: grid;
  min-height: 73px;
  padding: 8px 20px;
  place-content: center;
}

.hartuzBookingGrid-title h1 {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: 0.45px;
  line-height: 23px;
  margin: 0;
  text-transform: capitalize;
}

.hartuzBookingGrid {
  box-shadow: 0px 0px 36px 0px #00000013;
  display: grid;
  min-height: 700px;
}

.hartuzBookingGrid-img {
  background-color: var(--color-whisper);
  width: 100%;
}

.hartuzBookingGrid-img img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.hartuzBookingGrid__content {
  width: 100%;
}


.hartuzBookingGrid__content--info {
  align-items: center;
  background-color: var(--bg-color-white);
  color: var(--color-main);
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  min-height: 326px;
  text-align: center;
  padding: 30px 0;
}

.hartuzBookingGrid__content--info svg circle {
  stroke: var(--color-main);
}

.hartuzBookingGrid__content--info svg path {
  fill: var(--color-main);
}


.hartuzBookingGrid__content--info p {
  max-width: 195px;
  margin: 1em auto;
}

.hartuzBookingGrid__content--booking p, .hartuzBookingGrid__content--payment p {
  margin: 5px; 
  max-width: 100%;
}

.hartuzBooking {
  display: flex;
  flex-direction: column-reverse;
  margin: 0;
}

.hartuzBookingGrid__concurrence-error {
  background-color: var(--color-pale-pink);
  border: 1px solid transparent;
  border-color: var(--color-azalea);
  border-radius: 0.25rem;
  color: var(--color-red-berry);
  font-weight: var(--font-weight-regular);
  padding: 0.75rem 1.25rem;
}


@media (min-width: 768px) {
  .hartuzBookingGrid {
    grid-template-rows: 1fr auto 1fr
  }

  .hartuzBookingGrid__content {
    display: flex;
    width: 100%;
  }

  .hartuzBookingGrid__content--info {
    flex: 1;
  }

  .hartuzBookingGrid__concurrence-error {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    transition: opacity 0.6s;
  }

  .hartuzBookingGrid__closeBtn {
    cursor: pointer;
    font-size: var(--font-size-18);
    transition: 0.3s;
  }

  .hartuzBookingGrid__closeBtn:hover {
    color:#D9BDBA;
  }


  .hartuzBookingGrid__row {
    margin-left: auto;
    margin-right: auto;
    width: 695px;
  }

  .hartuzBookingGrid-img {
    grid-area: 1/1/3/1;
  }

  .hartuzBookingGrid__title-wrapper {
    grid-area: 2/1/3/1;

  }
}

@media (min-width: 1024px) {
  .hartuzBooking {
    margin-top: 100px;
  }

  .hartuzBookingGrid__concurrence-error {
    margin-top: 0;
  }

  .hartuzBookingGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto minmax(380px, 1fr);
    min-height: 450px;
  }

  .hartuzBookingGrid-img {
    grid-area: 2/1/3/2;
  }

  .hartuzBookingGrid__title-wrapper {
    grid-area: 1/1/2/4;
    width: 100%;
  }

  .hartuzBookingGrid-title {
    background-color: var(--color-main);
    color: var(--color-white);
    padding: 8px 20px;
  }


  .hartuzBookingGrid__content {
    grid-area: 2/2/3/4;
    order: 2;
    width: 100%;
  }


}