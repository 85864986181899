.backtotop {
    background-color: var(--color-main);
    border: none;
    -moz-border-radius: 42px;
    -webkit-border-radius: 42px;
    border-radius: 42px;
    bottom: 120px;
    color: var(--color-white);
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
     font-size: var(--font-size-14);
    opacity: 1;
    outline: none;
    padding: 16px 21px;
    position: fixed;
    right: 50px;
    text-decoration: none;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.backtotop:hover {
    background-color: var(--bg-color-white);
    color: var(--color-main);
    outline: none;
}

.backtotop:active,
.backtotop:visited,
.backtotop:focus {
    outline: none;
}

.not-show {
    opacity: 0;
}

@media screen and (max-width: 1200px) {
    .backtotop {
        display: none;
    }
}
