.indicator-card__container {
    align-items: center;
    background-color: var(--color-background);
    color: var(--color-main);
    display: flex;
    flex-direction: row;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-regular);
    height: 82px;
    justify-content: space-between;
    padding: 0 30px;
    width: 785px;
}

.indicator-card__container--level2 {
    width: 733px;
}

.indicator-card__container--level3 {
    width: 665px;
}

.indicator-card__container:hover {
    font-weight: var(--font-weight-medium);
}

.indicator-card__link+.indicator-card__link {
    display: block;
    margin-top: 4px;
}

.indicator-card__container+.indicator-card__container {
    margin-top: 4px;
}

@media screen and (max-width: 1440px) and (min-width: 1201px) {
    .indicator-card__container {
        width: 585px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 769px) {
    .indicator-card__container {
        max-width: 785px;
    }
}

@media screen and (max-width: 768px) {
    .indicator-card__container--level2 {
        margin: 0 auto;
        width: 557px;
    }

    .indicator-card__container--level3 {
        margin: 0 auto;
        width: 458px;
    }

    .indicator-card__container {
        background-color: var(--bg-color-white);
    }
}

@media screen and (max-width: 560px) {
    .indicator-card__container {
        height: 102px;
    }

    .indicator-card__container--level2 {
        margin: 0 auto;
        width: 315px;
    }

    .indicator-card__container--level3 {
        margin: 0 auto;
        width: 295px;
    }

    .indicator-card__container {
        background-color: var(--bg-color-white);
        max-width: 320px;
    }
}