.plansGrouped_plans {
  color: var(--color-text-news);
  display: flex;
   font-size: var(--font-size-18);
  height: 30px;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-left: 96px;
  margin-right: 20px;
  margin-right: 96px;
  margin-top: 30px;
  /* width: 817px; */
}

.plansGrouped_title {
  /* margin-right: 40px; */
  width: 340px;
}

.plansGrouped_start_date {
  margin-right: 40px;
}

.planDetail-box_container-dates_svg p{
   font-size: var(--font-size-14);
}

.separador {
  display: flex;
}

.plans-percentage{
  align-items: center;
  display: flex;
  flex-direction: column;
}

.plans-percentage--hidden{
  visibility: hidden;
}

.percentage {
   font-size: var(--font-size-20);
  font-weight: var(--font-weight-medium);
}
.cumplido {
  font-size: var(--font-size-8);
  font-weight: var(--font-weight-medium);
  margin-top: -20px;
}

.percentage p,
.cumplido p{
  margin-bottom: 0;
}

.plansGrouped_plans a {
  color: var(--color-text-news);
}

@media screen and (max-width:960px) {
  .plansGrouped_start_date {
    display: none;
  }
  .plansGrouped_end_date {
    display: none;
  }
  .plansGrouped_plans {
    align-items: center;
    color: var(--color-text-news);
    display: flex;
     font-size: var(--font-size-18);
    height: 60px;
    margin-bottom: 0px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 15px;
    /* width: 100%; */
  }
  /* .plansGrouped_plans p {
    margin-bottom: 0;
  } */

  .plansGrouped_title {
      font-size: var(--font-size-16);
  }
  .planList-box_container_title p {
    margin-left: 40px;
  }
}

@media screen and (max-width:568px) {
  .plansGrouped_start_date {
    display: none;
  }
  .plansGrouped_end_date {
    display: none;
  }
  .plansGrouped_plans {
    align-items: center;
    border-bottom: 1px solid var(--color-iron);
    display: flex;
     font-size: var(--font-size-14);
    height: 60px;
    justify-content: center;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-top: -1px;
    width: 100%;
  }

  .plansGrouped_title {
    margin-bottom: 0;
    margin-right: 0px;
    text-align: center;
    width: 80%;
  }

}