.date-picker-wrapper {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.date-picker {
  align-items: center;
  background-color: var(--color-white);
  display: flex;
}

.date-picker .DateInput {
  font-size: var(--font-size-14);
  padding-left: 15px;
  width: 100%;
}

.date-picker .SingleDatePicker {
  width: 100%; 
}


.date-picker__focused {
  background: none;
  justify-content: center;
}

.date-picker__focused .DateInput {
  display: none;
}

.date-picker__focused .SingleDatePicker {
  display: flex;
  justify-content: center;
}

.date-picker .SingleDatePicker_picker,
.date-picker .SingleDatePickerInput,
.date-picker-wrapper  {
  width: 100%;
}

.date-picker__focused .SingleDatePicker_picker,
.date-picker__focused .SingleDatePickerInput,
.date-picker-wrapper__focused {
  width: auto;
}

.date-picker__icon {
  margin-left: 20px;
}

.date-picker__icon svg rect,
.date-picker__icon svg path {
  stroke: var(--color-main)
}

.date-picker .SingleDatePicker_picker {
  box-shadow: 1.80px 1.80px 6.35px rgba(0, 0, 0, 0.13);
  position: initial;
}

.date-picker__with_modal .DateInput {
  display: none;
}

.date-picker .CalendarDay__default,
.date-picker .DayPickerNavigation_button__horizontalDefault,
.date-picker .SingleDatePickerInput__withBorder {
  border: none;
}

.date-picker .CalendarDay__selected, 
.date-picker .CalendarDay__selected:active,
.date-picker .CalendarDay__selected:hover {
  background-color: var(--color-main);
  border-radius: 20px;
}

.date-picker .DayPickerNavigation_svg__horizontal {
  fill: var(--color-main)
}

.date-picker .DayPicker_weekHeader {
  display: none;
}

.date-picker .CalendarMonth_caption {
  padding-bottom: 5px;
}

.date-picker .CalendarMonth_table {
  background-color: transparent;
}

.CalendarMonth_caption {
  color: var(--color-main);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
  text-transform: capitalize
}

.CalendarDay__default:hover {
  background: var(--color-main);
  border: none;
  border-radius: 25px;
  color: var(--color-white);
  opacity: 0.8;
}

.CalendarDay__default {
  font-size: 12.5px;
  font-weight: var(--font-weight-bold);
}

.CalendarDay__default:focus-visible {
  border-radius: 25px;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active, 
.CalendarDay__blocked_calendar:hover {
  background-color: transparent;
  color: var(--color-mine-shaft);
  font-weight: var(--font-weight-regular);
}

.date-picker .DateInput .DateInput_input {
  padding: 11px 0px 9px;
}

.date-picker .DateInput .DateInput_input::placeholder,
.date-picker .DateInput .DateInput_input  {
  color: var(--color-main);
  font-size: var(--font-size-14);
  font-style: italic;
  font-weight: var(--font-weight-regular);
}

.date-picker__button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.date-picker__disabled input {
  opacity: 0.7;
  pointer-events: none !important;
}

 @media (min-width: 768px) {
  .date-picker .DateInput .DateInput_input::placeholder {
    color: var(--color-main);
    font-size: var(--font-size-16)
  }
 }

 @media (min-width: 1024px) {
  .date-picker .DateInput .DateInput_input::placeholder {
    font-size: var(--font-size-14);
  }

  .date-picker__button button {
    max-width: 230px;
  }
 }