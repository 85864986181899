.indicator-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -60px 0 0 auto;
    width: 100%;
}

.indicator-detail__content {
    background-color: var(--bg-color-white);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
    padding-bottom: 25px;
    position: relative;
    width: 90%;
}

.indicator-detail__content-box {
    margin-bottom: 100px;
    margin-left: 355px;
    margin-top: 100px;
    padding-right: 150px;
    width: 100%;
}

.indicator-detail__sidebar {
    background: url('../../assets/img/menu_transparencia.jpg') repeat;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 442px;
    padding-bottom: 114px;
    padding-top: 97px;
    position: absolute;
    width: 33%;
    z-index: var(--z-index-1);
}

.indicator-detail__sidebar * {
    position: relative;
    /* hack */
}

.indicator-detail__title {
    color: var(--color-main);
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-semi-bold);
}

.indicator-detail__subtitle {
    color: var(--color-main);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-regular);
    margin-bottom: 50px;
    margin-top: 50px;
}

.indicator-detail__attachments {
    bottom: 0;
    left: 0;
    margin-bottom: 25px;
    margin-left: 78px;
    position: absolute;
    width: 300px;
}

.indicator-detail__attachments-title {
    color: var(--color-main);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-semi-bold);
}

.indicator-detail__attachments-subtitle {
    color: var(--color-main);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-regular);
    margin-left: 7px;
}

.status-label + .indicator-detail__title {
    margin-top: 15px;
}

.clip__icon {
    fill: red;
    width: 12px;
}

.indicator-detail__category-box {
    margin: 0 auto;
    max-width: 543px;
    width: 100%;
}

.overlay:before {
    content: ' ';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: var(--z-index-0);
}

.blue:before {
    background-color: var(--color-main);
    opacity: 0.9;
}

@media screen and (max-width: 1200px) {
    .indicator-detail__sidebar {
        display: none;
    }

    .indicator-detail__content {
        width: 100%;
    }

    .indicator-detail__content-box {
        margin: 100px auto;
        padding: 0 90px;
        width: 100%;
    }

    .indicator-detail__attachments {
        margin-left: 90px;
    }
}

@media screen and (max-width: 568px) {
    .indicator-detail__content-box {
        padding: 0 20px;
    }
}
