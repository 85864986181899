.mapa-de-acciones-container {
    height: auto;
    padding-bottom: 40px;
    width: 100%;
}

.action_map_list {
    background-color: var(--bg-color-lighter-grey);
    -webkit-box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.08);
    height: auto;
    margin-top: 399px;
    position: relative;
    width: 90%;
}
.action_map_list hr {
    background-color: var(--bg-color-grey);
    height: 1px;
    width: 1060px;
}

.mapa-de-acciones-container_title {
    align-items: center;
    background-color: var(--bg-color-white);
    color: var(--color-main);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    font-family: var(--font-main);
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    height: 100px;
    justify-content: space-between;
    letter-spacing: 0.35px;
    letter-spacing: 0.45px;
    line-height: 18px;
    padding: 0 40px;
    text-align: center;
    width: 100%;
}

.actionMap-box-tittle_search-plan {
    width: 35%;
    
}

.search-icon-plan-am {
    color: var(--color-main);
    margin-bottom: 5px;
    padding: 10px;
    position: relative;
    top: -6px;

  }

.search-hidden-icon-plan-am {
    background-color:var(--color-ghost-white);
    border-radius: 50%;
    color: var(--color-main);
    height: 38px;
    margin-bottom: 5px;
    padding: 10px;
    position: relative;
    top: -6px;
}

.am-search-input-plan::placeholder {
    color: var(--color-dim-gray);
    font-size: 80%;
  }
  
.am-search-hidden-plan::placeholder {
    color: var(--color-dim-gray);
    font-size: 80%;
  }

.mapa-de-acciones-container_title p {
    font-size: var(--font-size-22);
    margin-bottom: 0;
}

.mapa-de-acciones-container_content {
    background-color: var(--bg-color-lighter-grey);
    width: 100%;
}
.mapa-de-acciones-container_content hr {
    width: 92%;
}

.am-search-form-hidden-plan {
    background-color: transparent;
    border: none;
    border-radius: 2px;
    border-radius: 50px;
    display: -ms-flexbox;
    display: flex;
    height: 42px;
    justify-content: space-evenly;
    margin-bottom: 3px;
    margin-right: 10px;
    padding: 8px 0 8px 10px;
    -webkit-transition: background-color 1s ease;
    -o-transition: background-color 1s ease;
    transition: background-color 1s ease;
    width: 100%;
}

.am-search-form-plan {
    background-color:var(--color-ghost-white);
    border: none;
    border-radius: 50px;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    height: 42px;
    justify-content: space-evenly;
    margin-bottom: 3px;
    margin-right: 10px;
    padding: 8px 0 8px 10px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: background-color 1000ms;
    -o-transition: background-color 1000ms;
    transition: background-color 1000ms;
    width: 100%;
}

.am-search-hidden-plan {
    background-color: transparent;
    border-width: 0;
    color: var(--color-dim-gray);
    opacity: 0;
    -webkit-transition: visibility 0s 1s, opacity 1s linear;
    -o-transition: visibility 0s 1s, opacity 1s linear;
    transition: visibility 0s 1s, opacity 1s linear;
    visibility: hidden;
    width: 92%;
}

.am-search-input-plan {
    background-color: transparent;
    border-width: 0;
    color: var(--color-dim-gray);
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: opacity 1000ms;
    -o-transition: opacity 1000ms;
    transition: opacity 1000ms;
    visibility: visible;
    width: 92%;
}

.box-projects {
    margin-left: 40px;
}
.separador {
    background-color: var(--bg-color-grey);
    height: 1px;
    margin-bottom: 40px;
    margin-top: -10px;
}
.box_statement {
    display: flex;
    flex-direction: row;
    padding-left: 40px;
    padding-top: 40px;
}
.box_statement div {
    margin-right: 28%;
    width: 9%;
}

.box_statement div p {
    color: var(--color-main);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
    margin-bottom: 0;
    width: 71px;
}

.box_project {
    align-items: center;
    display: flex;
    height: 75px;
    margin-left: 40px;
    width: 100%;
}
.project {
    display: flex;
    flex-direction: row;
    line-height: 30px;
}
.project div {
    margin-right: 22%;
    width: 13%;
}

.project div p {
    color: var(--color-text-news);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-regular);
    margin-bottom: 0;
    margin-right: 245px;
    width: 100%;

}

@media screen and (max-width: 960px) {
    /* Tablet */

    .mapa-de-acciones-container {
        height: 747px;
        width: 1119px;
    }

    .action_map_list {
        height: auto;
        margin-top: 394px;
        width: 85%;
    }
    .mapa-de-acciones-title {
        background-color: var(--bg-color-white);
        margin-bottom: 60px;
    }
    .mapa-de-acciones {
        align-items: center;
        color: var(--color-main);
        display: flex;
        font-family: var(--font-main);
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-semi-bold);
        height: 100px;
        justify-content: space-between;
        letter-spacing: 0.45px;
        line-height: 18px;
        margin: 0 40px;
        text-align: center;
    }
    .mapa-de-acciones p {
        margin-bottom: 0;
    }
    .box_statement_tablet p {
        color: var(--color-main);
        font-weight: var(--font-weight-medium);
    }

    .box_action_tablet {
        margin-left: 40px;
        width: 90%;
    }

    .box_action_tablet hr {
        background-color: var(--bg-color-grey);
        height: 0.1px;
        margin-left: -1px;
        width: 98%;
    }
    .budget {
        display: flex;
        flex-direction: row;
        margin: 25px 0 52px 0px;
    }
    .budget p {
        color: var(--color-dim-gray);
        margin-right: 12%;
    }
}

@media screen and (max-width: 568px) {
    /* Mobile */

    .mapa-de-acciones-container {
        height: 289px;
        width: 605px;
    }

    .action_map_list {
        height: auto;
        margin-top: 418px;
        width: 80%;
    }

    .mapa-de-acciones {
        align-items: center;
        color: var(--color-main);
        display: flex;
        font-family: var(--font-nain);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-semi-bold);
        height: 80px;
        justify-content: space-between;
        letter-spacing: 0.35px;
        line-height: 18px;
        margin: 0 19px 0 22px;
        text-align: center;
        width: 100%;
    }
    .box_statement_tablet p {
        color: var(--color-main);
        font-family: var(--font-main);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-medium);
        height: 17px;
        letter-spacing: 0;
        line-height: 18px;
        width: 107px;
    }

    .box_action_tablet {
        margin-left: 22px;
        width: 90%;
    }

    .box_action_tablet hr {
        background-color: var(--bg-color-grey);
        height: 0.1px;
        margin-left: -1px;
    }
    .budget {
        display: flex;
        flex-direction: column;
        margin: 15px 0 20px 0px;
    }
    .budget p {
        color: var(--color-dim-gray);
        margin-right: 12%;
    }

    .search-hidden-icon-plan-am {
        padding: 12px;
    }
}
