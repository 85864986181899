.hartuzFacilityGrid {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
}

.hartuzFacilityGrid__message {
    font-weight: var(--font-weight-semi-bold);
    grid-column: 1/4;
}

@media (min-width: 1024px) {
    .hartuzFacilityGrid {
        grid-template-columns: repeat(auto-fill, minmax(23rem, 1fr));
        overflow: hidden;
        overflow-y: scroll;
        padding-bottom: 20px;
        position: relative;
    }
  
    .hartuzContainer__bottom .hartuzCancelationButton__container {
        position: relative;
    }
    

    /* Hide scrollbar for Chrome, Safari and Opera */
    .hartuzFacilityGrid::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .hartuzFacilityGrid {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}
