.status-label {
    border: 1px solid var(--color-main);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    color: var(--color-main);
    display: inline-block;
    font-size: var(--font-size-14);
    font-weight: var(var(--font-weight-regular));
    height: 26px;
    padding: 2px 10px 0 10px;
    text-align: center;
}
