
.hartuzContainer__intro {
  min-height: 80px;
}

.hartuzContainer__intro p {
  color: var(--color-main);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
  line-height: 22px;
  padding: 30px 0;
}

.hartuzContainer__intro__title {
  border-bottom: 1px solid var(--color-gainsboro);
  padding: 21px 0 20px;
}

.hartuzContainer__intro__subtitle p {
  color: var(--color-text-news)
}

@media (min-width: 768px) {
  .hartuzContainer__intro {
    min-height: 115px;
  }
}

@media (min-width: 1024px) {
  .hartuzContainer__intro p {
    font-size: var(--font-size-18);
    line-height: 24px;
  }

  .hartuzCancelationButton__container {
    margin-top: 40px;
  }
}

@media (min-width: 1280px) {
  .hartuzContainer__intro p {
    font-size: var(--font-size-15);
    line-height: 21px;
    padding-bottom: 0px;
    padding-top: 0;
  }

  .hartuzContainer__intro__title {
    border: none;
  }

}