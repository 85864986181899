.radio-container {
    display: flex;
}

.radio-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 30px 10px 0;
}

.radio-text {
    margin: 10px;
}

.info-container {
    color: var(--color-text-news);
    font-size: var(--font-size-15);
    font-weight: var(--font-weight-regular);
    margin-bottom: 20px;
}