.test {
    width: 300px;
    height: 300px;
    background-color: blue;
}

.row {
    margin-left: 0;
    margin-right: 0; 

}
