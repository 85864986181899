a .fa.fa-3x {
    color: var(--color-main);
    font-size: 1.7em;
    margin-top: 20px;
    padding-left: 20px;
}

a .fa.fa-3x:first-of-type {
    padding-left: 0;
}

a .fa.fa-2x {
    margin-top: 25px;
    opacity: 0.6;
}

a .fa.fa-2x:first-of-type {
    margin-top: 0;
}

.icons-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.social-box {
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: space-around;
}

.hidden-title {
    display: none !important;
}

a span {
    display: none;
}

@media screen and (max-width: 1200px) {
    .icons-container {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 576px) {
    .fa.fa-2x .fa.fa-3x {
        display: none;
    }
}
