.cookie-banner {
    align-items: center;
    background-color: var(--color-main);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    min-height: 60px;
    opacity: 0.8;
    padding: 15px;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: var(--z-index-8);
}

.cookie-banner .cookie-message {
    color: var(--color-white);
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-medium);
    line-height: 12px;
    width: 80%;
}

.cookie-banner .button-close {
    background: none;
    border: solid 1px var(--color-white);
    box-shadow: none;
    color: var(--color-white);
    cursor: pointer;
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-medium);
    line-height: 14px;
    margin-top: 10px;
    transition: color 0.3s;
}

.cookie-banner .button-close:hover {
    color: var(--color-white);
}

@media screen and (max-width: 769px) {
    .cookie-banner {
        min-height: 100px;
    }
}

@media screen and (max-width: 576px) {
    .cookie-banner {
        min-height: 160px;
    }
}

@media screen and (min-width: 768px) {
    .cookie-banner {
        flex-direction: row;
    }

    .cookie-banner .cookie-message {
        font-size: var(--font-size-14);
        line-height: 18px;
    }

    .cookie-banner .button-close {
        font-size: var(--font-size-14);
        line-height: 18px;
        margin-left: 20px;
        margin-top: 0;
    }
}


