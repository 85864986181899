
.searchInput__Container {
    flex-grow: 1;
}

.searchInput__Container input {
    border: 1px solid var(--color-main);
    border-radius: 5px;
    background-color: var(--color-white);
    width: 100%;
    height: 40px;
    caret-color: var(--color-main);
    font-size: var(--font-size-14);
    padding: 0 10px;
}

.searchInput__Container input::-webkit-input-placeholder {
    color: var(--color-text-news);
}

.searchInput__Container input:focus {
    outline: none;
    border: 2px solid var(--color-main);
}

@media (max-width: 1280px) {
    .searchInput__Container input {
        width: 250px;
    }
}
