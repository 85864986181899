.news-title-container {
    align-items: center;
    background-color: var(--news-title-container);
    display: flex;
    flex-direction: row;
    font-family: var(--font-main);
    height: 164px;
    justify-content: center;
    opacity: 0.95;
    position: absolute;
    width: 448px;
    z-index: var(--z-index-13);
}

.news-title-content {
    margin: 0 auto;
    width: 360px;
}

.news-title_text::first-letter {
    color: rgba(255, 255, 255, 0.6);
    font-family: 'MontserratBlack', Fallback, sans-serif;
    font-size: var(--font-size-60);
    font-weight: var(--font-weight-black);
}

.news-title_text {
    color: var(--color-white);
    display: block;
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0.55px;
    line-height: 28px;
}

@media screen and (max-width: 768px) {
    .news-title-container {
        width: 100%;
    }
    .news-title_text {
        color: var(--color-white);
        display: block;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-semi-bold);
        letter-spacing: 0.55px;
        line-height: 28px;
    }
}

@media screen and (max-width: 568px) {
    .news-title-content {
        width: 80%;
    }
}
