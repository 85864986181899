.functionality-home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -60px -15px 0 auto;
    width: 100%;
}

.functionality-home__content {
    background-color: var(--bg-color-white);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
    position: relative;
    width: 90%;
}

.functionality-home__content-box {
    margin-bottom: 100px;
    margin-left: 355px;
    margin-top: 100px;
    max-width: 785px;
    padding-right: 150px;
    width: 100%;
}

.functionality-home__sidebar {
    background: url('../../assets/img/menu_transparencia.jpg') repeat;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 442px;
    padding-bottom: 114px;
    padding-top: 97px;
    position: absolute;
    width: 33%;
    z-index: var(--z-index-1);
}

.functionality-home__sidebar * {
    position: relative;
    /* hack */
}

.functionality-home__subtitle {
    color: var(--color-main);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-regular);
    margin-bottom: 50px;
    margin-top: 50px;
}

.functionality-home__category-box {
    margin: 0 auto;
    max-width: 543px;
    width: 100%;
}

.overlay:before {
    content: ' ';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: var(--z-index-0);
}

.blue:before {
    background-color: var(--color-main);
    opacity: 0.9;
}

@media screen and (max-width: 768px) {
    .functionality-home__sidebar {
        display: none;
    }

    .functionality-home__content {
        width: 100%;
    }

    .functionality-home {
        margin: -60px auto 0 auto;
    }

    .functionality-home__content-box {
        margin: 100px 50px;
        max-width: 785px;
        padding-right: 0;
        width: 100%;
    }
}
