.icon-footer {
    text-align: center;
}

.icon-footer>svg {
    width: 15px;
}

.info-footer>span {
    font-size: var(--font-size-14) !important;
    font-weight: var(--font-weight-semi-bold) !important;
}

.policies-card__container {
    width: 100%;
}

.icon-to-top {
    margin-top: 30px;
}

.link-button {
    all: unset;
    background-color: transparent;
    border: none;
    color: var(--color-white);
}