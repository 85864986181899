.footer {
    display: grid;
    grid-template-columns: 2fr 5fr 5fr;
    margin-top: 3rem;
    min-height: 270px;
    padding-top: 1.5rem;
    text-align: left;
    width: 100%;
}

.footer a {
    color: white;
}

.footer-logo {
    align-items: center;
    background-color: var(--bg-color-white);
    display: flex;
    flex-direction: column;
    height: 270px;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.footer-logo a:focus-visible {
    outline: 2px solid black;
}

.footer-wrapper {
    height: 243px;
    width: 100%;

}

.footer-second {
    align-items: center;
    display: flex;
    justify-content: center;
}

.footer-second.social {
    align-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.footer-udala {
    width: 112px;
}

.content-info {
    background-color: #666666;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;
    opacity: 0.95;
    width: 100%;
}

.content-info *:focus-visible {
    outline: 2px solid white;
}

.footer-biscaytik {
    margin-top: 25px;
    width: 117px;
}

.politics-sponsors {
    background-color: var(--color-main);
    display: grid;
    grid-template-areas: "first third";
    grid-template-columns: 8fr 4fr;
    justify-content: space-between;
    width: 100%;
}

.politics-sponsors *:focus-visible {
    outline: 2px solid black;
}

.icon-footer {
    float: left;
    height: 15px;
    width: 40px;
    margin-right: 20px;
}


.footer-container {
    margin: 0 auto;
    width: 100%;
}

.info-footer {
    display: table;
    margin-bottom: 10px;
    padding-left: 10px;
}

.footerVariableContainer {
    align-items: center;
    display: flex;
    grid-area: first;
    padding: 30px 0 0 80px;
}

.info-footer>span {
    display: table-cell;
}

.logo-footer {
    width: 150px;
}

.footerLogos {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-area: third;
    padding-top: 50px;
}

.politics-link {
    display: none;
    grid-area: second;
}

@media (max-width: 1200px) {
    .footer {
        left: 0%;
        width: 100%;
        grid-template-columns: 4fr 8fr;
    }

    .last-news__card_footer {
        display: none;
    }

    .content-info {
        display: none;
    }

    .footer-logo {
        padding: 12% 15px 15px 15px;
    }

    .footerLogos,
    .footerVariableContainer {
        padding-top: 30px;
    }

    .politics-sponsors {
        grid-template-areas: "first third" "second second";
        grid-template-columns: 8fr 4fr;
    }

    .politics-link {
        display: block;
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-semi-bold);
        margin: 0 auto;
    }

    .mobile {
        display: none;
    }

    .tablet {
        display: block;
    }
}

@media (max-width: 992px) {
    .footer {
        text-align: center;
    }

    .logo-footer {
        width: 150px;
    }

    .footer-udala {
        width: 95px;
    }

    .footer-biscaytik {
        width: 99px;
    }
}

@media (max-width: 768px) {
    .footer {
        width: 100%;
    }

    .footer-logo {
        height: 18em;
        width: 100%;
    }

    .politics-sponsors {
        width: 100vw;
    }

    .pòlitics-link {
        display: block;
    }
}

@media (max-width: 576px) {
    .footer {
        grid-template-columns: 1fr;
        height: auto;
    }

    .footer-udala {
        width: 120px;
        margin-right: 70px;
    }

    .footer-biscaytik {
        width: 120px;
        margin-left: 70px;
    }

    .footerVariableContainer {
        margin-top: 50px;
    }

    .footerLogos {
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 50px;
    }

    .footer-container {
        height: 160px !important;
        margin: 0 !important;
        width: 100%;
    }

    .row {
        margin: 0;
    }

    .politics-sponsors {
        padding-left: 0;
        grid-template-areas: "first first" "second second" "third third";
    }

    .footerVariableContainer {
        padding-left: 78px;
    }

    .mobile {
        display: block;
        margin-top: 20px;
    }

    .tablet {
        display: none;
    }
}