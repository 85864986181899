.planDetail {
    background-color: var(--bg-color-white);
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    margin-top: 91px;
    width: 90%;
}

.planDetail_title__container,
.planDetail_title {
    align-items: center;
    color: var(--color-main);
    display: flex;
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-semi-bold);
    height: 130px;
    justify-content: space-between;
    margin-left: 40px;
    margin-right: 40px;
}

.planDetail_title__container svg {
    width: 50px;
}

.planDetail-title_pdf {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-regular);
}

.planDetail-title_pdf p {
    margin: 0;
}

.planDetail-box {
    background-color: var(--bg-color-lighter-grey);
    display: flex;
    justify-content: center;
}

.planDetail-box_container {
    margin-bottom: 50px;
    margin-top: 50px;
    width: 90%;
}

.planDetail-box_container-description-responsive {
    display: none;
}

/*CHART AND DESCRIPTION BOX*/

.planDetail-box_container-descrip-chart {
    display: flex;
    flex-direction: row;
}

.chart {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 135px;
}

.done-text p {
    color: var(--color-main);
    font-size: var(--font-size-12);
    margin-bottom: 0;
}

.done-text {
    margin-top: -55px;
}

.chart-title {
    width: 80px;
}

.planDetail-box_container-description {
    display: flex;
    font-size: var(--font-size-14);
    margin-bottom: 30px;
    margin-right: 50px;
    width: 50%;
}

.plan-state {
    margin-top: -25px;
}

.state {
    align-items: center;
    border: 1px solid var(--color-main);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 3px;
    width: 137px;
}

.state p {
    color: var(--color-main);
    font-weight: var(--font-weight-medium);
    margin-bottom: 0;
    margin-right: 10px;
}

/* .bola {
    background-color: var(--color-main);
    border-radius: 40px;
    height: 12px;
    margin-right: 10px;
    width: 12px;
} */

.planDetail-box_container-chart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 50%;
}

.separador-chart {
    background-color: var(--bg-color-grey);
    height: 1px;
    margin-bottom: 40px;
    margin-top: -10px;
}

.planDetail-box_container-chart_title__container {
    color: var(--color-main);
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
    margin-top: 14px;
    width: 50%;
}

/*DATES*/

.planDetail-box_container-dates {
    display: flex;
    flex-direction: row;
    font-weight: 14px;
    justify-content: space-between;
    margin-bottom: 60px;
    width: 400px;
}

.planDetail-box_container-dates_svg {
    display: flex;
    flex-direction: row;
}

.planDetail-box_container-dates_svg svg {
    margin-right: 12px;
    width: 22px;
}

.planDetail-box_container-dates_svg p {
    color: var(--color-text-news);
    font-weight: 14px;
    margin-bottom: 0;
}

.date-literal {
    font-weight: var(--font-weight-medium);
    margin-right: 6px;
}

/*DOWNLOADS AND LINKS*/

.planDetail-box_container-downloads_files {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 23px;
}

.planDetail-box_container-downloads_files a {
    color: var(--color-text-news);
    font-size: var(--font-size-14);
    margin-bottom: 0;
    text-decoration: none;
}

.planDetail_title__container a {
    color: var(--color-text-news);
    font-size: var(--font-size-14);
    margin-bottom: 0;
    text-decoration: none;
}

.planDetail-box_container-downloads_files svg,
.planDetail-box_container-downloads_files svg {
    margin-right: 12px;
    width: 20px;
}

/*MAP*/

.planDetail-box_container-map {
    height: 599px;
    margin-bottom: 70px;
    margin-top: 47px;
}

.action_ubication {
    align-items: center;
    background-color: var(--color-background);
    display: flex;
    height: 100px;
    margin-top: 75px;
}

.action_ubication div h5 {
    color: var(--color-main);
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: 25px;
    margin-left: 102px;
    margin-top: 25px;
    width: 140%;
}

.container-map-title_container {
    background-color: var(--bg-color-light-grey);
}

.planDetail-box_container-map_title {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100px;
    margin-left: 96px;
}

.UbicationIcon {
    height: 30px;
    margin-right: 29px;
    width: 24px;
}

.mapa {
    height: 500px;
}

#Rendicion_accion_desktop {
    stroke: var(--color-main);
}

.combined-shape {
    height: 100px;
    width: 248px;
}

.combined-shape .plan,
.combined-shape .accion,
.combined-shape .opcion .ver-accion {
    color: var(--color-main);
}

.opcion {
    align-items: center;
    background-color: var(--color-background);
    border-radius: 0 0 6.8px 6.8px;
    display: flex;
    height: 51px;
    justify-content: center;
    width: 100%;
}

.ver-accion a:hover {
    color: var(--color-main);
}

.separador {
    background-color: var(--bg-color-grey);
    height: 1px;
    margin-bottom: 40px;
    margin-top: -10px;
}

/*EJES*/

.planDetail-box_container-ejes {
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
    margin-bottom: 70px;
}

.planDetail-box_container-ejes_title {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100px;
    margin-left: 96px;
}

.container-ejes-title_container {
    background-color: var(--bg-color-light-grey);
}

.planDetail-box_container-ejes_title p,
.ejes-acciones-container-title p,
.planDetail-box_container-map_title p {
    color: var(--color-main);
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: 0;

}

.planDetail-box_container-ejes_title svg {
    margin-right: 30px;
    width: 36px;
}

.ejes-title {
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    align-items: center;
    background-color: var(--bg-color-white);
    color: var(--color-text-news);
    display: flex;
    flex-direction: row;
    font-weight: var(--font-weight-medium);
    height: 100px;
    justify-content: space-between;
}

.ejes-title p {
    font-size: var(--font-size-18);
    margin-bottom: 0;
    margin-left: 96px;
}

.separador-ejes {
    background-color: var(--bg-color-grey);
    height: 1px;
    margin-bottom: 0;
}

.arrows-axis {
    cursor: pointer;
    margin-right: 96px;
}

.arrows-axis svg {
    width: 13px;
}

.ejes-description-hidden {
    display: none;
}

.ejes-description-shown {
    background-color: var(--bg-color-lighter-grey);
    display: flex;
    margin-top: -2px;
}

.ejes-description-containter {
    margin: 60px 90px 30px 90px;
    width: 100%;
}

.ejes-description-chart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 25px;
}

.ejes-description {
    display: flex;
    width: 60%;
}

.ejes-description-responsive {
    display: none;
}

.ejes-acciones-container {
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
    margin-top: 54px;
}

.ejes-acciones-container-title {
    align-items: center;
    background-color: var(--bg-color-light-grey);
    display: flex;
    flex-direction: row;
    height: 100px;
}

.ejes-acciones-container-title svg {
    margin-left: 68px;
    margin-right: 30px;
    width: 38px;
}

.ejes-description_download {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.ejes-description_download p {
    margin-bottom: 0;
}

.ejes-description_download svg {
    margin-right: 12px;
    width: 20px;
}

.acciones {
    margin-left: 60px;
    padding-bottom: 40px;
}

.acciones-link {
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-medium);
    margin-top: 40px;
}

.acciones-link a {
    color: var(--color-text-news);
    text-decoration: none;
}

/*ACCIONES*/

.planDetail-box_container-actions {
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.08);
}

.container-actions-title_container {
    background-color: var(--bg-color-light-grey);
}

.planDetail-box_container-actions_title {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100px;
    margin-left: 96px;
}

.planDetail-box_container-actions_title p {
    color: var(--color-main);
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: 0;
}

.planDetail-box_container-actions_title svg {
    margin-right: 30px;
    width: 38px;
}

.actions-content {
    background-color: var(--bg-color-white);
    margin-bottom: 40px;
    padding-bottom: 40px;
}

.actions-content-link {
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-medium);
    margin-left: 96px;
    padding-top: 40px;
}

.actions-content-link a {
    color: var(--color-text-news);
    text-decoration: none;
}

/*SVG*/

#Rendicion_plan_v1_desktop_paper,
#Shape-eje,
#Path-eje,
#Shape-Copy,
#pdf-letras {
    stroke: var(--color-main);
}

#Rendicion_plan_v1_desktop_link,
#Path-7-Copy-14-eje,
#Path-7-Copy-13-eje,
#Group-11-Copy-2,
#pdf-letras,
#pdf-contorno {
    fill: var(--color-main);
}

.map_container {
    height: 750px;
    position: relative;
    width: 1440px;
}

@media screen and (max-width: 960px) {
    .planDetail {
        margin-top: 40px;
    }

    .action_ubication {
        height: 100px;
        width: 100%;
    }

    .planDetail_title__container,
    .planDetail_title {
        margin-right: 40px;
    }

    .planDetail-box_container-description {
        display: none;
    }

    .planDetail-box_container-description-responsive {
        display: flex;
        margin-top: 40px;
    }

    .planDetail-box_container {
        margin-bottom: 0;
    }

    .planDetail-box_container-descrip-chart {
        flex-direction: column;
    }

    .planDetail-box_container-chart {
        width: 100%;
    }

    .planDetail-box_container-dates {
        margin-bottom: 50px;
        margin-top: 25px;
    }

    .ejes-description-containter {
        margin: 60px 40px 30px 40px;
    }

    .planDetail-box_container-ejes_title,
    .planDetail-box_container-actions_title,
    .actions-content-link,
    .planDetail-box_container-map_title,
    .ejes-title p {
        margin-left: 46px;
    }

    .planDetail-box_container-ejes_title,
    .planDetail-box_container-actions_title,
    .actions-content-link,
    .planDetail-box_container-map_title {
        height: 80px;
    }

    .planDetail-box_container-ejes_title p,
    .ejes-acciones-container-title p,
    .planDetail-box_container-map_title p {
        font-weight: var(--font-weight-medium);
    }

    .arrows-axis {
        margin-right: 46px;
    }

    .combined-shape {
        height: auto;
        width: 320px;
    }
}

@media screen and (max-width: 568px) {
    .planDetail {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        width: 100%;
    }

    .planDetail_title__container,
    .planDetail_title {
        font-size: var(--font-size-14);
        height: 80px;
        margin-left: 20px;
    }

    .planDetail_title__container svg {
        width: 45px;
    }

    .planDetail-box_container-ejes_title p,
    .ejes-acciones-container-title p,
    .planDetail-box_container-map_title p,
    .actionDetail_plan-title,
    .planDetail-box_container-actions_title p,
    .ejes-title p,
    .actions-content-link,
    .acciones-link {
        font-size: var(--font-size-14);
    }

    .planDetail-box_container-dates {
        width: auto;
    }

    .planDetail-box_container-chart {
        align-items: center;
        flex-direction: column;
    }

    .chart {
        margin-top: 38px;
    }

    .action_ubication {
        height: 60px;
        width: 100%;
    }

    .action_ubication_content {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-medium);
        height: 60px;
        width: 203px;
    }

    .combined-shape {
        height: auto;
        width: 320px;
    }

    .opcion {
        height: 51px;
        margin-bottom: 0;
    }

    .UbicationIcon {
        height: 30px;
        margin-right: 14px;
        width: 24px;
    }

    .planDetail-box_container-ejes_title,
    .planDetail-box_container-actions_title,
    .actions-content-link,
    .planDetail-box_container-map_title {
        height: 60px;
    }

    .map_container {
        height: 289px;
        width: 500px;
    }

    .planDetail-box_container-ejes_title svg,
    .planDetail-box_container-actions_title svg,
    .ejes-acciones-container-title svg {
        margin-right: 19px;
        width: 27px;
    }

    .planDetail-box_container-ejes {
        margin-bottom: 40px;
    }

    .ejes-description_download {
        margin-bottom: 40px;
    }

    .actions-content,
    .planDetail-box_container-map {
        margin-bottom: 0;
    }

    .plan-state {
        display: flex;
        justify-content: center;
    }

    .planDetail-box_container-description-responsive {
        margin-top: 40px;
    }

    .ejes-description-chart {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 25px;
    }

    .ejes-description {
        display: none;
    }

    .ejes-description-responsive {
        display: flex;
        margin-top: 48px;
    }

    .ejes-description-containter {
        margin: 0px 20px 30px 20px;
        width: 100%;
    }

    .ejes-acciones-container {
        margin-top: 0px;
    }

    .ejes-acciones-container-title,
    .ejes-title {
        height: 60px;
    }

    .actions-content {
        background-color: var(--bg-color-white);
        margin-bottom: 40px;
        padding-bottom: 20px;
    }

    .actions-content-link {
        padding-top: 30px;
    }

    .planDetail-box_container-actions {
        margin-bottom: 40px;
    }

    .acciones {
        padding-bottom: 20px;
    }

    .acciones-link {
        margin-top: 20px;
    }
}

@media screen and (max-width: 568px) {
    .planDetail-box_container-dates {
        flex-direction: column;
    }

    .planDetail-box_container-dates_svg {
        margin-bottom: 10px;
    }
}