.aurretikFacilityInfo {
  background-color: var(--bg-color-white);
  color: var(--content-color-medium-blue);
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  padding: 20px 30px;
}

.aurretikFacilityInfo div:first-child {
  font-family: var(--typography-accent);
  font-size: var(--font-size-15);
  font-weight: var(--font-weight-medium);
  line-height: 20px;
}

.aurretikFacilityInfo_info-icon {
  height: 30px;
  margin-right: 10px;
  width: 30px;
}

.aurretikFacilityInfo_info-icon .info-letter {
  fill: var(--content-color-light-blue);
}

.aurretikFacilityInfo_info-icon .info-circle {
  fill: var(--content-color-medium-blue);
}

.aurretikFacilityInfo__content {
  font-size: var(--font-size-11);
  font-weight: var(--font-weight-regular);
  line-height: 14px;
}

@media (min-width: 1100px) {
  .aurretikFacilityInfo {
    min-width: 300px;
  }  
}