.category-render__container {
    background-color: var(--bg-color-white);
    border: none;
    margin: 0 15px;
    width: 785px;
}

.category-render__container--child {
    border: 1px solid var(--color-main);
}

.category-render__inner-content,
.category-render__inner-content--child,
.category-render__inner-content--third {
    width: 100%;
}

.category-render__inner-content {
    margin-top: 25px;
}

.category-render__inner-content--child {
    padding: 0 25px;
}

.category-render__inner-content--third {
    padding: 0 25px 0 60px;
}

.category-render__first-level-title {
    color: var(--color-main);
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-semi-bold);
}

.category-render__first-level-title svg {
    margin-right: 1rem;
}

.category-render__first-level-title h1 {
    font-size: var(--font-size-22);
}

.category-render__first-level-title h1,
.category-render__first-level-title h2 {
    color: var(--color-main);
    font-weight: var(--font-weight-semi-bold);
}

.category-render__first-level-title h2,
.category-render__inner-content--third h3 {
    font-size: var(--font-size-15);
}

.category-render__first-level-subtitle {
    color: var(--color-main);
    font-size: var(--font-size-15);
    font-weight: var(--font-weight-regular);
    margin-top: 10px;
    word-wrap: normal;
}

.category-render__indicators-wrapper {
    margin: 25px 0 60px 0;
    width: 100%;
}

.category-render__container--child > .category-render__indicators-wrapper {
    padding: 0 25px;
}

.category-render__indicators-wrapper--third {
    margin: 25px 0 60px 0;
    padding: 0 25px 0 60px;
    width: 100%;
}

.category-render__container + .category-render__container {
    margin-top: 67px;
}

@media screen and (max-width: 1440px) and (min-width: 1201px) {
    .category-render__container {
        width: 585px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 769px) {
    .category-render__container {
        width: 785px;
    }
}

@media screen and (max-width: 768px) {
    .category-render__container {
        margin: 0 auto;
        padding: 20px 50px;
        width: 100%;
    }

    .category-render__first-level-title--third {
        margin: 0 auto;
        width: 518px;
    }

    .category-render__indicators-wrapper--third {
        padding: 0;
    }

    .category-render__container.category-render__container--child {
        background-color: rgba(0, 0, 0, 0);
        padding: 0;
        width: 630px;
    }

    .category-render__container > .category-render__inner-content {
        margin-top: 25px;
        width: 100%;
    }

    .category-render__container.category-render__container--child
        > .category-render__first-level-subtitle--responsive {
        margin-bottom: 0;
        padding: 0;
    }
}

@media screen and (max-width: 560px) {
    .category-render__container {
        margin: 0 auto;
        padding: 20px 50px;
        width: 100%;
    }

    .category-render__first-level-title--third {
        margin: 0 auto;
        width: 260px;
    }

    .category-render__indicators-wrapper--third {
        padding: 0;
    }

    .category-render__container--child > .category-render__indicators-wrapper {
        padding: 0;
    }

    .category-render__container.category-render__container--child {
        background-color: rgba(0, 0, 0, 0);
        padding: 0;
        width: 320px;
    }

    .category-render__container > .category-render__inner-content {
        margin-top: 25px;
        width: 100%;
    }

    .category-render__container.category-render__container--child
        > .category-render__first-level-subtitle--responsive {
        margin-bottom: 0;
        padding: 0;
    }
}
