.hartuzCancelationButton__container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.hartuzCancelationButton {
  align-items: center;
  background-color: var(--bg-color-darker-grey);
  color: var(--color-secondary);
  cursor: pointer;
  font-weight: var(--font-weight-bold);
  display: flex;
  justify-content: center;
  gap: 10px;
  min-height: 64px;
  min-width: 286px;
  text-align: center;
  width: 100%;
}

.hartuzCancelationButton i {
  width: 20px;
}

.hartuzCancelationButton:hover {
  color: var(--color-white)
}

@media (min-width: 1024px) {
  .hartuzCancelationButton {
    max-width: 695px;
  }
}