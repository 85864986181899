.swiper-container {
    height: 100%;
}

.swiper__related-news {
    height: 100%;
}

.swiper__container {
    height: 100%;
}
