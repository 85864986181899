.planList {
  background-color: var(--bg-color-white);
  box-shadow: 0px 0px 14px 1px rgba(0,0,0,0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5%;
  margin-top: 50px;
  width: 90%;
}

.planList-box {
  background-color:var(--color-ghost-white);
  display: flex;
  justify-content: center;
}

.planList-box-title__container,
.planList-box-title {
  align-items: center;
  color: var(--color-main);
  display: flex;
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-semi-bold);
  height: 130px;
  justify-content: space-between;
  margin-left: 40px;
  margin-right: 60px;
}

.planList-box-title__container p,
.planList-box-title {
  margin-bottom: 0px;
}

.chart-title{
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 115px;
}
.planList-done-text p{
  color: var(--color-main);
  font-size: var(--font-size-8);
  margin-bottom: 0;
  margin-top: -33px;
}
.planList-box-title_chart-search {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 60%;
}
.label-finder {
  margin: 0;
}
.planList-box-title_search-plan {
  margin-right: 5%;
  width:50%;
}
.search-icon-plan {
  color: var(--color-main);
  left: 1px;
  margin-bottom: 5px;
  padding: 12px;
  position: relative;
  top: -11px;
}
.search-hidden-icon-plan {
  background-color:var(--color-ghost-white);
  border-radius: 50%;
  color: var(--color-main);
  height: 45px;
  left: 1px;
  margin-bottom: 5px;
  padding: 12px;
  position: relative;
  top: -11px;
}

.finder-link {
  background-color:var(--bg-color-lighter-grey);
  border-radius: 50%;
  color: var(--color-main);
  left: -10px;
  margin-bottom: 5px;
  padding: 10px;
  position: relative;
  top: 2px;
}
.finder-link:hover {
  color: var(--color-main);
}

.search-form-plan {
  background-color: var(--bg-color-lighter-grey);
  border: none;
  border-radius: 50px;
  display: flex;
  height: 42px;
  margin-bottom: 3px;
  margin-right: 10px;
  padding: 8px 0 8px 10px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: background-color 1000ms;
  transition: background-color 1000ms;
  width: 100%;
}

.search-form-hidden-plan {
  background-color: transparent;
  border: none;
  border-radius: 2px;
  display: flex;
  height: 42px;
  margin-bottom: 3px;
  margin-right: 10px;
  padding: 8px 0 8px 10px;
  transition: background-color 1s ease;
  width: 100%;
}

.search-input-plan::-webkit-input-placeholder,
.search-input-plan:-moz-placeholder,
.search-input-plan::-moz-placeholder,
.search-input-plan:-ms-input-placeholder {
  color: var(--color-font-secondary);
  opacity: 1;
}

.search-form-plan > a {
  color: var(--color-font-secondary);
  text-decoration: none;
}

.search-input-plan {
  background-color: var(--bg-color-lighter-grey);
  border-width: 0;
  color: var(--color-dim-gray);
  font-size: var(--font-size-15);
  margin-right: 15px;
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: opacity 1000ms;
  transition: opacity 1000ms;
  visibility: visible;
  width: 92%;
}

.search-input-plan::placeholder,
.search-hidden-plan::placeholder {
  color: var(--color-dim-gray);
  font-size: var(--font-size-15);
}

.search-hidden-plan {
  background-color: transparent;
  border-width: 0;
  color: var(--color-font-secondary);
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
  visibility: hidden;
  width: 92%;
}

.hiddenParagraph-plan {
  display: none;
}

#circle,
#palo {
  fill: var(--color-main);
}

.planList-box_container {
  background-color: var(--bg-color-white);
  box-shadow: 0px 0px 14px 1px rgba(0,0,0,0.08);
  margin-bottom: 50px;
  margin-top: 50px;
  width: 87%;
}

.planList-box-subtitle__container  {
  align-items: center;
  background-color: var(--color-background);
  color: var(--color-main);
  display: flex;
  height: 100px;

}

.planList-box-subtitle {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-medium);
  margin-bottom: 0;
  margin-left: 60px;
}

.planList-box_container_title {
  align-items: center;
  background-color: var(--bg-color-light-grey);
  color: var(--color-main);
  display: flex;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-semi-bold);
  height: 100px;
}

.planList-box_container_title p {
  font-family: var(--font-main);
  font-weight: var(--font-weight-medium);
  margin-bottom: 0;
  margin-left: 96px;
}

.planlist_hidden-item {
  display: none;
}

@media screen and (max-width:568px) {
  .planList {
    margin-bottom: -48px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: -20px;
    width: 100%;
  }
  .planList-box {
    background-color: var(--color-background);
    display: flex;
    justify-content: center;
  }

  .planList-box-subtitle__container  {
    height: 60px;
  }

  .planList-box-subtitle {
    font-size: var(--font-size-14);
  }

  .planList-box-title__container {
    align-items: center;
    color: var(--color-main);
    display: flex;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-semi-bold);
    height: 80px;
    justify-content: space-between;
    margin-left: 13%;
    width: 75%;
  }  

  .planList-box-title_chart-search {
    width: 60%;
  }

  .planList-box_container {
    background-color: var(--bg-color-white);
    box-shadow: 0px 0px 14px 1px rgba(0,0,0,0.08);
    margin-bottom: 50px;
    margin-top: 20px;
    width: 75%;
  }

  .planList-box_container_title {
    align-items: center;
    background-color: var(--bg-color-lighter-grey);
    display: flex;
    font-size: var(--font-size-14);
    height: 60px;
    justify-content: center;
    margin-top: -1px;
    width: 100%;
  }

  .planList-box_container_title p {
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .plansNotGrouped_title {
    margin-right: 0;
    width: 90%;
  }

  .planList-box-title p,
  .planList-box-title,
  .planList-box-subtitle {
    margin-left: 0px;
  }

  .plans-percentage {
    display: none;
  }
  .chart-title {
    margin-right: 0;
    width: 67px;
  }
  .planList-done-text p {
    font-size: var(--font-size-8);
    margin-top: -30px;
  }
}

@media screen and (max-width:745px){
  .plansNotGrouped_title {
    margin-right: 0;
    width: 50%;
  }
}


@media screen and (max-width:960px) {
  .planList {
    display: flex;
    justify-content: center;
    margin-left: 30px;
    margin-right: 30px;
    width: 95%;
  }

  .planList-box_container {
    background-color: var(--bg-color-white);
    box-shadow: 0px 0px 14px 1px rgba(0,0,0,0.08);
    margin-bottom: 50px;
    margin-top: 35px;
    width: 90%;
  }

  .planList-box-title__container {
    margin-right: 50px;
  }

  .planList-box-title__container p,
  .planList-box-title {
    margin-left: 0;
  }

  .planList-done-text p {
    margin-left: 0;
  }

  .planList-box_container_title {
    align-items: center;
    background-color: var(--bg-color-light-grey);
    color: var(--color-main);
    display: flex;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-semi-bold);
    height: 80px;
  }

  .chart-title {
    margin-right: 0px;
  }

  .planList-box_container_title p {
    margin-bottom: 0px;
    margin-left: 40px;
  }

}