.CircularProgressbar-path {
  stroke: var(--color-main);
  strokeWidth: 5px;
  strokeLinecap: 'round';
}

.CircularProgressbar-trail {
  stroke: #f1f2f4;
  strokeWidth: 3px;
}
.CircularProgressbar-text {
  display: flex;
  flex-direction: column;
  stroke:0px;
  strokeWidth: 0px;
  fill: var(--color-main);
  font-family: var(--font-main);
  font-weight: var(--font-weight-medium);
   font-size: var(--font-size-20);
  dominant-baseline: middle;
  text-anchor: middle;
}

