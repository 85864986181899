.dropdown-content {
 display: none;
 position: absolute;
 background-color: #fff;
 box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
 z-index: 1;
}

.dropdown-content li {
 color: black;
 padding: 12px 16px;
 text-decoration: none;
 display: block;
}

.dropdown-content li:hover {
  background-color: #fff
}

.dropdown:hover .dropdown-content {
 display: block;
 width: 100%;
}

.dropbtn {
  padding: 0 10px;
  background-color: var(--color-white);
  color: var(--color-main);
  font-weight: var(--font-weight-semi-bold);
  border: 1px solid var(--color-main);
  border-radius: 5px;
  height: 40px;
}

.dropdown:hover .dropbtn, .dropbtn.active {
 background-color: var(--color-main);
 color: var(--color-white);
}

.hartuzCategorySelector ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.hartuzCategorySelector ul li {
  width: 100%;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid var(--border-color-light);
}

