.menu-vertical__container {
    max-width: 290px;
    padding-right: 30px;
}

.menu-vertical__item {
    align-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 72px;
    padding-right: 30px;
    transition: all 0.5 ease-in;
    width: 260px;
}

.menu-vertical__item > span {
    font-size: var(--font-size-15);
    font-weight: var(--font-weight-medium);
    color: var(--color-font-secondary-menu-transparency);
}

.menu-vertical__item.active {
    background-color: var(--color-accent-400);
    color: var(--color-main);
    padding-left: 15px;
    padding-right: 15px;
    width: 290px;
}

.menu-vertical__item.active > span {
    color: var(--color-font-secondary);
}

.menu-vertical__item:hover {
    background-color: var(--bg-color-white);
    color: var(--color-main);
    padding-left: 15px;
    padding-right: 15px;
    width: 290px;
}

.menu-vertical__item:hover > span {
    color: var(--color-font-secondary);
    color: var(--color-font-secondary-menu-transparency);
    color: var(--color-main);
}
