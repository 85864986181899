.news-body-container {
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    font-family: var(--font-main);
    height: 100%;
    justify-content: center;
    max-width: 100%;
    min-height: 390px;
    padding: 68px 68px 0 68px;
    position: relative;
    z-index: var(--z-index-13);
}

.news-body-content {
    margin: 0 auto 20px auto;
    width: 100%;
}

.news-body_text {
    color: var(--color-text-news);
    display: block;
    font-size: var(--font-size-14);
}

.social-icon {
    fill: var(--color-iron);
    width: 29px;
}

@media screen and (max-width: 568px) {
    .news-body-container {
        padding: 0;
    }
}