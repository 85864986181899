.planList_plans {
  color: var(--color-text-news);
  display: flex;
  font-size: var(--font-size-18);
  height: 30px;
  justify-content: space-around;
  margin: 30px 20px 30px 30px;
}

.planList_plans h3 {
  font-size: var(--font-size-18);
}

.plans_status {
  border: 1px solid var(--color-main);
  border-radius: 15px;
  color: var(--color-main);
  display: flex;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-medium);
  justify-content: center;
  padding: 5px 10px 5px 10px;
  width: 112px;
}

.plansNotGrouped_title {
  color: var(--color-text-news);
  font-weight: var(--font-weight-regular);
  margin-bottom: 0;
  margin-right: 40px;
  width: 340px;
}

.plans-percentage{
  align-items: center;
  display: flex;
  flex-direction: column;
}

.percentage {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-medium);
}

.cumplido {
  font-size: var(--font-size-8);
  font-weight: var(--font-weight-medium);
  margin-top: -20px;
}

.percentage p,
.cumplido p{
  margin-bottom: 0;
}

.planList_plans a {
  color: var(--color-text-news);
}

@media screen and (max-width:960px) {
  .plansNotGrouped_start_date,
  .plansNotGrouped_end_date {
    display: none;
  }

  .planList_plans {
    width: auto;
  }
  .planList_plans,
  .planList_plans h3 {
     font-size: var(--font-size-16);
  }
}

@media screen and (max-width:568px) {
  .planList_plans {
    align-items: center;
    border-bottom: 1px solid var(--color-iron);
    display: flex;
    flex-direction: column;
    height: 78px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 60px;
    padding-top: 60px;
    text-align: center;
  }
  .plansNotGrouped_title {
    font-size: var(--font-size-14);
    margin-bottom: 7px;
    margin-right: 0;
    width: auto;
  }
  .plans_status{
    border-radius: 15px;
    font-size: var(--font-size-12);
    padding: 5px 6px 5px 6px;
    width: 100px;
  }
  .plans_status p {
    margin-bottom: 0;
  }

  .planList-box-subtitle__container {
    display: flex;
    height: 60px;
    justify-content: center;
  }

  .planList-box-subtitle {
    font-size: var(--font-size-14);
    margin-left: 0;
  }

}