.occupancy__icon {
  fill: var(--color-iron);
  height: auto;
  width: 40px
}

.occupancy__icon-low #alta {
  fill: var(--color-low);
}

.occupancy__icon-medium #alta {
  fill: var(--color-medium);
}

.occupancy__icon-high #alta {
  fill: var(--color-high);
}