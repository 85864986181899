.timePicker {
  border: 1px solid var(--color-main);
  color: var(--color-text-news);
  border-radius: 5px;
  height: 40px;
  padding: 0 5px 0 15px;
}

.timePicker:focus {
  outline: none;
  border: 2px solid var(--color-main);
}

.timePicker::-webkit-calendar-picker-indicator {
  filter: invert(40%) sepia(60%) saturate(200%) hue-rotate(180deg);
}