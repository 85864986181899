    .ni-container {
        width: 100%;
    }

    .newIncidence {
        align-items: center;
        display: flex;
        flex-direction: column;
        min-height: 1550px;
    }

    .newIncidence input,
    textarea,
    select {
        padding-left: 10px;
    }

    .newIncidence textarea {
        padding-top: 10px;
    }

    .infobox_header-status {
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        justify-content: flex-end;
        width: 50%;
    }

    .new_incidenceindicenciaDetail-infobox {
        background-color: var(--bg-color-lighter-grey);
        height: auto;
        position: relative;
        top: 570px;
        width: 90%;
    }

    .newindicenciaDetail_mapContainer {
        height: 700px;
        position: absolute;
        width: 100%;
    }

    .rightcolumn {
        justify-content: end;
        width: 45%;
    }

    .leftcolumn {
        justify-content: start;
        width: 45%;
    }

    .newinfobox_status-description {
        background-color: var(--bg-color-lighter-grey);
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        height: auto;
        justify-content: space-between;
        margin-top: 40px;
        padding: 0 40px;
    }

    .newinfobox__location_remember {
        align-items: center;
        color: red;
        display: flex;
        gap: 10px;
        height: 70px;
        justify-content: center;
        font-size: x-large;
    }

    .newinfobox__location_remember p {
        margin: 0;
    }

    .infobox_header-status_date {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: flex-end;
        width: 100%;
    }

    .infobox_header-status_date svg {
        height: 21px;
        margin-right: 5px;
        width: 20px;
    }

    .infobox_header-status_date p {
        font-size: var(--font-size-15);
        margin: 0;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--color-iron);
        border-radius: 4px;
    }

    .infobox_status-description ::-webkit-scrollbar {
        background-color: var(--bg-color-white);
        width: 6px;
    }

    .incidencias-reportBoton {
        align-items: center;
        background-color: var(--color-main);
        border-radius: 2px;
        color: var(--color-white);
        cursor: pointer;
        display: flex;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-semi-bold);
        height: 48px;
        justify-content: center;
        text-align: center;
        width: 515px;
    }

    .send_box {
        align-items: center;
        background-color: var(--bg-color-white);
        -webkit-box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.08);
        box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: row;
        height: 170px;
        justify-content: center;
    }

    .send_box>* {
        margin: 138px;
    }

    .fileUploader {
        background: url('../../assets/img/pre_image_icon.png') no-repeat center;
        background-size: 65% 75%;
        width: 100%;
    }

    .fileUploader::before {
        background: rgba(255, 255, 255, 0.808);
    }

    .fileContainer {
        align-items: center;
        border-radius: 10px;
        -webkit-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 20px 0;
        position: relative;
        -webkit-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
        width: 100%;
    }

    .fileContainer .uploadPictureContainer {
        background-color: #fff;
        width: 33%;
    }

    .fileContainer p {
        color: transparent;
        color: #000;
        font-size: var(--font-size-12);
        margin: 8px 0 4px;
    }

    .formularioVisible,
    .formularioNoEnviadoVisible {
        visibility: hidden;
    }

    .chooseFileButton {
        background: #000;
        font-weight: 600 !important;
    }

    @keyframes anim {
        0% {
            background-color: rgba(247, 54, 54, 0);
        }

        25% {
            background-color: rgba(247, 54, 54, 0.20);
        }

        50% {
            background-color: rgba(247, 54, 54, 0.40);
        }

        75% {
            background-color: rgba(247, 54, 54, 0.60);
        }

        100% {
            background-color: rgba(247, 54, 54, 0.74);
        }
    }

    @keyframes anim2 {
        0% {
            background-color: rgba(16, 165, 16, 0);
        }

        25% {
            background-color: rgba(16, 165, 16, 0.20);
        }

        50% {
            background-color: rgba(16, 165, 16, 0.40);
        }

        75% {
            background-color: rgba(16, 165, 16, 0.60);
        }

        100% {
            background-color: rgba(16, 165, 16, 0.74);
        }
    }

    .formulario_enviado,
    .formulario_no_enviado {
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        height: 0px;
        margin: 0px;
        margin-left: 0%;
        text-align: center;
        vertical-align: middle;
        width: 0px;
    }

    .formulario_enviado>*,
    .formulario_no_enviado>* {
        margin: 10px;
    }

    .formulario_enviado>svg {
        color: rgb(0, 97, 0);
        height: 20px;
        width: 20px;
    }

    .formulario_no_enviado>svg {
        color: rgb(165, 0, 0);
        height: 20px;
        width: 20px;
    }

    @media screen and (max-width: 1200px) {
        .ni-container {
            margin-top: -40px;
        }
    }

    @media screen and (max-width: 900px) {
        .rightcolumn {
            width: 100%;
        }

        .leftcolumn {
            width: 100%;
        }

        .fileUploader {
            background: url('../../assets/img/pre_image_icon.png') no-repeat center;
            background-size: 45% 80%;
        }

        .newIncidence {
            min-height: 1945px;
        }

        .tablet_incidenciaDetail-infobox_header {
            background-color: var(--bg-color-white);
            display: flex;
            -ms-flex-direction: row;
            flex-direction: column;
            -ms-flex-pack: justify;
            height: 130px;
            justify-content: space-between;
            padding: 40px
        }

        .newinfobox_status-description {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            height: auto;
            padding-top: 25px;
        }

        .send_box {
            display: flex;
            flex-direction: column;
        }

        .send_box>* {
            margin: 0;
            margin-bottom: 0px;
            margin-top: 10px;
        }

        .incidencias-reportBoton {
            align-items: center;
            background-color: var(--color-main);
            border-radius: 2px;
            color: var(--color-white);
            cursor: pointer;
            display: flex;
            -ms-flex-align: center;
            -ms-flex-pack: center;
            font-size: var(--font-size-18);
            font-weight: var(--font-weight-semi-bold);
            height: 48px;
            justify-content: center;
            width: 308px;
        }
    }

    @media screen and (max-width: 769px) {
        .infobox_header-status_date {
            justify-content: left;
            padding-top: 20px;
        }
    }

    @media screen and (max-width: 576px) {
        .newIncidence {
            min-height: 1750px;
        }

        .fileUploader {
            width: 89%;
        }

        .newIncidence {
            margin-bottom: 0%;
        }

        .newindicenciaDetail_mapContainer {
            height: 700px;
            position: relative;
        }

        .tablet_incidenciaDetail-infobox_header {
            background-color: var(--bg-color-white);
            display: flex;
            -ms-flex-direction: row;
            flex-direction: column;
            -ms-flex-pack: justify;
            height: 130px;
            justify-content: space-between;
            padding: 40px
        }

        .infobox_card_all {
            position: relative;
            top: -75px;
            width: 90%;
        }

        .newinfobox_status-description {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            height: auto;
            margin-top: 0px;
        }

        .infobox_header-status_date {
            justify-content: center;
        }

        .send_box {
            display: flex;
            flex-direction: column;
        }

        .send_box>* {
            margin: 0;
            margin-top: 10px;
        }

        .incidencias-reportBoton {
            align-items: center;
            background-color: var(--color-main);
            border-radius: 2px;
            color: var(--color-white);
            cursor: pointer;
            display: flex;
            -ms-flex-align: center;
            -ms-flex-pack: center;
            font-size: var(--font-size-18);
            font-weight: var(--font-weight-semi-bold);
            height: 45px;
            justify-content: center;
            width: 215px;
        }

        .captcha {
            width: 219px;
        }

        .fileUploader {
            background: url('../../assets/img/pre_image_icon.png') no-repeat center;
            background-size: 75% 75%;
            margin-bottom: 25px;
        }
    }