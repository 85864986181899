.tituloPrincipalResumen,
.tituloEjes,
.tituloAcciones {
  color: var(--color-biscay) !important;
}

/*.planList-box_container_title p,
img {
  margin-left: 30px;
}*/

.actions-content {
  background-color: var(--bg-color-white);
  margin-bottom: 60px;
  padding-bottom: 60px;
}

.contenedorAccionesDeEje {
  display: none;
  margin-left: 60px;
  margin-right: 60px;
}

.contenedorAccionesDeEjeCabecera {
  align-items: center;
  background-color: var(--bg-color-light-grey);
  color: var(--color-main);
  display: -ms-flexbox;
  display: flex;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-semi-bold);
  height: 100px;
}

.planDetail-box_container-ejes_title {
  align-items: center;
  background-color: var(--bg-color-light-grey);
  color: var(--color-main);
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-semi-bold);
  height: 100px;
  margin-bottom: 0;
  margin-left: 96px;
  margin-right: 30px;
  width: 36px;
}

.plansGrouped_plan_Resumen {
  color: var(--color-text-news);
  display: flex;
  font-size: var(--font-size-18);
  height: 80px;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-left: 96px;
  margin-right: 96px;
  margin-top: 50px;
}

.plansGrouped_plan_Resumen_actions {
  color: var(--color-text-news);
  display: flex;
  font-size: var(--font-size-18);
  height: 70px;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 30px;
}

.plansGrouped_Eje_titulo {
  align-items: center;
  display: flex;
  font-family: var(--font-main);
  font-weight: var(--font-weight-medium);
  width: 600px;

}

.plansGrouped_titleAcciones {
  align-items: center;
  display: flex;
  font-family: var(--font-main);
  font-weight: var(--font-weight-medium);
  width: 600px;
}

.ejes_arrows-axis {
  cursor: pointer;
}

.ejes_arrows-axis svg {
  width: 13px;
}

.plansGrouped_start_date {
  margin-right: 40px;
}

.planDetail-box_container-dates_svg p {
   font-size: var(--font-size-14);
}

.separador {
  display: flex;
}

.plans-percentage {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.plans-percentage--hidden {
  visibility: hidden;
}

.percentage {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-medium);
}

.cumplido {
  font-size: var(--font-size-8);
  font-weight: var(--font-weight-medium);
  margin-top: -20px;
}

.percentage p,
.cumplido p {
  margin-bottom: 0;
}

.plansGrouped_plan_Resumen a {
  color: var(--color-text-news);
}

.accionesDeEje {
  margin-bottom: 50px;
  margin-top: 50px;
}

.planList-hidden-content {
  display: none;
}

@media screen and (max-width:960px) {
  .plansGrouped_start_date,
  .plansGrouped_end_date {
    display: none;
  }

  .plansGrouped_plan_Resumen {
    align-items: center;
    color: var(--color-text-news);
    display: flex;
    font-size: var(--font-size-18);
    height: 60px;
    margin-bottom: 40px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }

  .alt_tab_icons {
    margin-bottom: 50px;
    margin-left: 20px;
  }

  .plansGrouped_plan_ResumenAcciones {
    align-items: center;
    display: flex;
    font-size: var(--font-size-16);
  }
/*
  .planList-box_container_title p {
    margin-left: 40px;
  }*/

  .CircularProgressbar {
    height: 80px;
  }
}

@media screen and (max-width:568px) {
  .plansGrouped_plan_Resumen_actions {
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  .plansGrouped_Eje_titulo {
    width: 300px;
  }

  .plansGrouped_start_date,
  .plansGrouped_end_date {
    display: none;
  }

  .plansGrouped_plan_Resumen {
    border-bottom: 1px solid var(--color-iron);
    font-size: var(--font-size-14);
    height: 100px;
    justify-content: center;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-top: 10px;
    width: 100%;
  }

  .plansGrouped_title {
    margin-bottom: 0;
    margin-right: 0px;
    text-align: center;
    width: 80%;
  }

}